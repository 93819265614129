import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import { SliderPicker } from "react-color";
import { useTranslation } from "react-i18next";
import CalendarInput from "../../../components/calendarInput";
import { CommonButton } from "../../../components/commonButton";
import Input from "../../../components/input";
import MultiSelect from "../../../components/multiSelect";
import Popup from "../../../components/popup";
import Spinner from "../../../components/spinner";
import TextArea from "../../../components/textArea";
import { API_SHORT_DATE_FORMAT } from "../../../utils/constants";
import { BATCH_RECORDS, BATCH_RECORDS_ID } from "../../../utils/requests";

const BatchRecordsPopup = ({ isNew, onClose, usedIds, onDelete, onSave, batchRecord, request = async () => {} }) => {
    const { t } = useTranslation();
    const [isSaving, setIsSaving] = useState(false);
    const [editBatchRecord, setEditBatchRecord] = useState({
        id: batchRecord ? batchRecord.id : null,
        tipoPlasma: batchRecord ? batchRecord.tipoPlasma : [],
        inizioValidita: batchRecord ? batchRecord.inizioValidita : null,
        link: batchRecord ? batchRecord.link : null,
        descrizione: batchRecord ? batchRecord.descrizione : null,
        descrizioneBreve: batchRecord ? batchRecord.descrizioneBreve : null,
        versione: batchRecord ? batchRecord.versione : null,
        ordine: batchRecord ? batchRecord.ordine : 0,
        exportColor: batchRecord ? batchRecord.exportColor : null
    });

    return (
        <Popup>
            {isSaving ? (
                <Spinner />
            ) : (
                <>
                    <div className="w-full mb-12 flex flex-col items-center">
                        <div className={"text-red-600 font-bold text-2xl mb-4"}>
                            {(isNew ? t("creation") : t("edit")) + " Batch Record"}
                        </div>
                        <div className="flex items-center justify-center mb-4">
                            <div className="w-52 mr-4">
                                <Input
                                    title={t("identifier")}
                                    placeholder={"--"}
                                    value={editBatchRecord.id}
                                    onChange={(value) => {
                                        setEditBatchRecord((prev) => ({ ...prev, id: value }));
                                    }}
                                    disabled={!isNew || isSaving}
                                />
                            </div>
                            <div className="w-52 ml-4">
                                <MultiSelect
                                    title={t("plasmaType")}
                                    placeholder={t("selectPlaceholder")}
                                    values={editBatchRecord.tipoPlasma}
                                    data={[t("all"), "IT", "EU", "IT REC.", "EU REC.", "US", t("other")]}
                                    onChange={(values) => {
                                        if (values.includes(t("other")) && values[values.length - 1] === t("other"))
                                            setEditBatchRecord((prev) => ({ ...prev, tipoPlasma: [t("other")] }));
                                        else if (values.includes(t("all")) && values[values.length - 1] === t("all"))
                                            setEditBatchRecord((prev) => ({ ...prev, tipoPlasma: [t("all")] }));
                                        else
                                            setEditBatchRecord((prev) => ({
                                                ...prev,
                                                tipoPlasma: values.filter((v) => v !== t("other") && v !== t("all"))
                                            }));
                                    }}
                                    disabled={isSaving}
                                />
                            </div>
                        </div>
                        <div className="flex items-center justify-center mb-4">
                            <div className="w-52 mr-4">
                                <Input
                                    title={t("version")}
                                    placeholder={"--"}
                                    value={editBatchRecord.versione}
                                    type={"number"}
                                    onChange={(value) => {
                                        setEditBatchRecord((prev) => ({ ...prev, versione: parseInt(value) }));
                                    }}
                                    disabled={isSaving}
                                />
                            </div>
                            <div className="w-52 ml-4">
                                <CalendarInput
                                    title={t("validityStart")}
                                    placeholder={t("selectPlaceholder")}
                                    value={editBatchRecord.inizioValidita}
                                    onChange={(value) => {
                                        if (!value) {
                                            return;
                                        }
                                        setEditBatchRecord((prev) => ({ ...prev, inizioValidita: value }));
                                    }}
                                />
                            </div>
                        </div>
                        <div className="w-52">
                            <Input
                                title={t("order")}
                                placeholder={"--"}
                                value={editBatchRecord.ordine?.toString()}
                                type={"number"}
                                onChange={(value) => {
                                    setEditBatchRecord((prev) => ({ ...prev, ordine: parseInt(value) }));
                                }}
                                disabled={isSaving}
                            />
                        </div>
                        <div className="w-full mb-4">
                            <Input
                                title={t("link")}
                                placeholder={"--"}
                                value={editBatchRecord.link}
                                onChange={(value) => {
                                    setEditBatchRecord((prev) => ({ ...prev, link: value }));
                                }}
                                disabled={isSaving}
                            />
                        </div>
                        <div className="min-w-30rem w-2/4">
                            <TextArea
                                minHeight="8rem"
                                title={t("shortDescription")}
                                placeholder={"--"}
                                value={editBatchRecord.descrizioneBreve}
                                onChange={(value) => {
                                    setEditBatchRecord((prev) => ({ ...prev, descrizioneBreve: value }));
                                }}
                            />
                        </div>
                        <div className="min-w-30rem w-2/4">
                            <TextArea
                                minHeight="8rem"
                                title={t("description")}
                                placeholder={"--"}
                                value={editBatchRecord.descrizione}
                                onChange={(value) => {
                                    setEditBatchRecord((prev) => ({ ...prev, descrizione: value }));
                                }}
                            />
                        </div>
                        <div className="min-w-30rem w-2/4">
                            <div className="font-medium text-base mb-1">Colore Export</div>
                            <SliderPicker
                                color={editBatchRecord.exportColor ? editBatchRecord.exportColor : ""}
                                onChange={(color) => {
                                    console.log(color);
                                    setEditBatchRecord((prev) => ({ ...prev, exportColor: color.hex }));
                                }}
                            />
                        </div>
                    </div>
                    <div className="text-red-600 w-full font-bold text-lg mb-8 text-center">
                        {usedIds.includes(editBatchRecord.id) && isNew && t("usedId")}
                    </div>
                    <div className="w-full flex items-center justify-between">
                        <div className="w-28">
                            {!isNew && (
                                <CommonButton
                                    className="text-red-600 border-red-600 p-2"
                                    onClick={async () => {
                                        setIsSaving(true);

                                        const response = await request(BATCH_RECORDS_ID, {
                                            method: "DELETE",
                                            ignoreResponse: true,
                                            params: {
                                                id: batchRecord.id
                                            }
                                        });

                                        if (!response.ok) {
                                            if (response.status !== 401) {
                                                setIsSaving(false);
                                            }
                                            alert(t(response.errorMessage) + " " + response.response);
                                            return;
                                        }

                                        onDelete();

                                        setIsSaving(false);
                                    }}
                                >
                                    {t("delete")}
                                </CommonButton>
                            )}
                        </div>
                        <div className="flex">
                            <div className="w-28 mr-1">
                                <CommonButton className="text-gray-600 p-2" onClick={onClose}>
                                    {t("back")}
                                </CommonButton>
                            </div>
                            <div className="w-28 ml-1">
                                <CommonButton
                                    className="text-green-600 border-green-600 p-2"
                                    onClick={async () => {
                                        setIsSaving(true);
                                        let body = null;

                                        if (!isNew) {
                                            body = Object.keys(editBatchRecord)
                                                .filter((key) => key !== "id")
                                                .reduce((obj, key) => {
                                                    obj[key] = editBatchRecord[key];
                                                    return obj;
                                                }, {});
                                            body.inizioValidita = format(body.inizioValidita, API_SHORT_DATE_FORMAT);
                                        } else {
                                            body = JSON.parse(JSON.stringify(editBatchRecord));
                                            body.inizioValidita = format(
                                                parseISO(body.inizioValidita),
                                                API_SHORT_DATE_FORMAT
                                            );
                                        }

                                        let index = body.tipoPlasma.indexOf(t("other"));

                                        if (index !== -1) body.tipoPlasma[index] = "ALTRO";

                                        index = body.tipoPlasma.indexOf(t("all"));

                                        if (index !== -1) body.tipoPlasma[index] = "TUTTI";

                                        let response = null;

                                        if (isNew) {
                                            response = await request(BATCH_RECORDS, {
                                                method: "POST",
                                                body: JSON.stringify(body)
                                            });
                                        } else {
                                            response = await request(BATCH_RECORDS_ID, {
                                                method: "PUT",
                                                body: JSON.stringify(body),
                                                params: {
                                                    id: batchRecord.id
                                                }
                                            });
                                        }

                                        if (!response.ok) {
                                            if (response.status !== 401) {
                                                setIsSaving(false);
                                            }
                                            alert(t(response.errorMessage));
                                            return;
                                        }

                                        onSave({
                                            ...editBatchRecord,
                                            inizioValidita: parseISO(body.inizioValidita),
                                            tipoPlasma: body.tipoPlasma.includes("ALTRO")
                                                ? [t("other")]
                                                : body.tipoPlasma.includes("TUTTI")
                                                ? [t("all")]
                                                : body.tipoPlasma
                                        });

                                        setIsSaving(false);
                                    }}
                                    enabled={
                                        editBatchRecord.id &&
                                        editBatchRecord.tipoPlasma.length !== 0 &&
                                        editBatchRecord.inizioValidita &&
                                        editBatchRecord.link &&
                                        editBatchRecord.descrizione &&
                                        editBatchRecord.descrizioneBreve &&
                                        editBatchRecord.versione &&
                                        editBatchRecord.ordine >= 0 &&
                                        (!isNew || !usedIds.includes(editBatchRecord.id))
                                    }
                                >
                                    {t("save")}
                                </CommonButton>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Popup>
    );
};

export default BatchRecordsPopup;
