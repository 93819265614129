import { useState } from "react";
import { useTranslation } from "react-i18next";
import FileInput from "../../components/fileInput";
import { Button } from "./components";
import { ReactComponent as AttachmentIcon } from "../../svgIcons/paperclip-solid.svg";
import { CREATE_PLANNING } from "../../utils/requests";
import { getBase64File } from "../../utils/functions";

const FileUpload = ({ onUploadStart = () => {}, onUploadComplete = () => {}, request, disabled = false }) => {
    const { t } = useTranslation();
    const [fileUpload, setFileUpload] = useState(null);

    return (
        <>
            <FileInput
                className={"cursor-pointer"}
                multiple={false}
                onChange={(files) => {
                    setFileUpload(files[0]);
                }}
                value={fileUpload}
            >
                <div
                    className={
                        "py-1 px-2 rounded font-semibold text-lg border-gray-300 border-2 flex items-center hover:bg-gray-100 " +
                        (fileUpload ? "text-green-800" : "text-gray-400")
                    }
                >
                    <AttachmentIcon className="h-5 w-5 mr-4" />
                    {fileUpload ? fileUpload.name : t("selectFile")}
                </div>
            </FileInput>
            {fileUpload && (
                <Button
                    enabled={!!fileUpload || !disabled}
                    className="mt-4"
                    disabledClassName="border-gray-500 text-gray-500"
                    enabledClassName="text-red-600 border-gray-700"
                    onClick={async () => {
                        onUploadStart();

                        const response = await request(CREATE_PLANNING, {
                            method: "POST",
                            body: JSON.stringify({
                                csv: await getBase64File(fileUpload)
                            }),
                            ignoreResponse: false
                        });

                        if (response.ok) {
                            setFileUpload(null);
                        }

                        onUploadComplete(response);
                    }}
                >
                    {t("confirm")}
                </Button>
            )}
        </>
    );
};

export default FileUpload;
