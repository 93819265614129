import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { userRoles } from "../../utils/constants";
import { requestStatuses, statusColors } from "./dataRequest_v2";
import Overlay from "./overlay";

const EditPopup = ({ lot, role, data, dataField, onClose = () => {}, onConfirm = () => {} }) => {
    const { t } = useTranslation();

    const [selectedStatus, setSelectedStatus] = useState(() => {
        if (!data) {
            return requestStatuses.RIVISTO;
        }
        if (dataField) {
            return data[dataField] ? data[dataField] : requestStatuses.RIVISTO;
        }
        if (
            data.statoSupplyChain === requestStatuses.DA_RIVEDERE ||
            data.revisioniGmpDoc.some((r) => r.stato === requestStatuses.DA_RIVEDERE) ||
            data.statoQC === requestStatuses.DA_RIVEDERE ||
            data.statoQA === requestStatuses.DA_RIVEDERE ||
            data.statoPDTS === requestStatuses.DA_RIVEDERE
        ) {
            return requestStatuses.DA_RIVEDERE;
        }
        return requestStatuses.RIVISTO;
    });

    const [gmpStatuses, setGmpStatuses] = useState(() => {
        if (data && data.revisioniGmpDoc && data.revisioniGmpDoc.length > 0) {
            return data.revisioniGmpDoc;
        }
        if (!lot) {
            return [];
        }
        return lot.batchRecords.map((br) => ({
            batchRecordId: br,
            stato: requestStatuses.RIVISTO
        }));
    });

    const enableConfirm = useMemo(() => {
        if (role === userRoles.GMP_DOC) {
            return (
                (selectedStatus === requestStatuses.RIVISTO && gmpStatuses.length === 0) ||
                lot.batchRecords.every((br) => gmpStatuses.some((s) => s.batchRecordId === br))
            );
        }
        return selectedStatus;
    }, [gmpStatuses, lot, role, selectedStatus]);

    return (
        <Overlay>
            <div className="rounded-md border-2 border-gray-500 bg-white py-3 px-5 max-h-70vh overflow-auto">
                <div className="flex flex-col items-center justify-center">
                    <div className="font-bold text-red-600 text-xl mb-1">{t("statusChoice")}</div>
                    <div className="flex w-full items-center justify-evenly mb-5">
                        {role && <div className="font-bold text-xl">{t(role)}</div>}
                        {lot && <div className="font-bold text-xl">{lot.lotto}</div>}
                    </div>
                    {role !== userRoles.GMP_DOC && (
                        <div className="flex w-full items-center justify-evenly">
                            <div
                                className={
                                    "w-24 h-16 border-gray-600 cursor-pointer hover:bg-opacity-90 rounded-sm " +
                                    statusColors.Rivisto +
                                    (selectedStatus === requestStatuses.RIVISTO ? " border-4" : "")
                                }
                                onClick={() => {
                                    setSelectedStatus(requestStatuses.RIVISTO);
                                }}
                            />
                            <div
                                className={
                                    "w-24 h-16 border-gray-600 cursor-pointer hover:bg-opacity-90 rounded-sm " +
                                    statusColors.DaRivedere +
                                    (selectedStatus === requestStatuses.DA_RIVEDERE ? " border-4" : "")
                                }
                                onClick={() => {
                                    setSelectedStatus(requestStatuses.DA_RIVEDERE);
                                }}
                            />
                        </div>
                    )}
                    {role === userRoles.GMP_DOC &&
                        lot.batchRecords.map((br) => {
                            const brData = gmpStatuses.find((s) => s.batchRecordId === br);

                            return (
                                <div key={br} className="flex items-center justify-center mb-2">
                                    <div className="mr-2 border-1 border-gray-600 p-1 rounded-md text-lg underline font-bold text-green-600 whitespace-nowrap">
                                        {br}
                                    </div>
                                    <div className="flex w-full items-center justify-center ml-2">
                                        <div
                                            className={
                                                "w-12 h-8 border-gray-600 cursor-pointer hover:bg-opacity-90 mr-1 rounded-sm " +
                                                statusColors.Rivisto +
                                                (brData?.stato === requestStatuses.RIVISTO ? " border-4" : "")
                                            }
                                            onClick={() => {
                                                setGmpStatuses((old) => {
                                                    let d = old.find((o) => o.batchRecordId === br);
                                                    if (d) {
                                                        d.stato = requestStatuses.RIVISTO;
                                                        return [...old];
                                                    }

                                                    return [
                                                        ...old,
                                                        {
                                                            batchRecordId: br,
                                                            stato: requestStatuses.RIVISTO
                                                        }
                                                    ];
                                                });
                                            }}
                                        />
                                        <div
                                            className={
                                                "w-12 h-8 border-gray-600 cursor-pointer hover:bg-opacity-90 ml-1 rounded-sm " +
                                                statusColors.DaRivedere +
                                                (brData?.stato === requestStatuses.DA_RIVEDERE ? " border-4" : "")
                                            }
                                            onClick={() => {
                                                setGmpStatuses((old) => {
                                                    let d = old.find((o) => o.batchRecordId === br);
                                                    if (d) {
                                                        d.stato = requestStatuses.DA_RIVEDERE;
                                                        return [...old];
                                                    }

                                                    return [
                                                        ...old,
                                                        {
                                                            batchRecordId: br,
                                                            stato: requestStatuses.DA_RIVEDERE
                                                        }
                                                    ];
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    <div className="flex items-center justify-between w-full mt-3">
                        <Button onClick={onClose} className="text-red-600 border-red-600 w-32">
                            {t("back")}
                        </Button>
                        <Button
                            enabled={!!enableConfirm}
                            className="w-32"
                            enabledClassName="text-green-600 border-green-600"
                            disabledClassName="border-gray-500 text-gray-500"
                            onClick={() => {
                                if (!lot) {
                                    onConfirm({
                                        status: selectedStatus
                                    });
                                    return;
                                }
                                if (!role) {
                                    onConfirm({
                                        anno: lot.anno,
                                        idLotto: lot.lotto,
                                        statoSupplyChain: selectedStatus,
                                        statoQC: selectedStatus,
                                        statoQA: selectedStatus,
                                        statoPDTS: selectedStatus,
                                        revisioniGmpDoc:
                                            selectedStatus === requestStatuses.DA_RIVEDERE
                                                ? lot.batchRecords.map((br) => ({
                                                      batchRecordId: br,
                                                      stato: requestStatuses.DA_RIVEDERE
                                                  }))
                                                : []
                                    });
                                    return;
                                }

                                let status = selectedStatus;
                                if (role !== userRoles.GMP_DOC) {
                                    status = selectedStatus;
                                }

                                let result;
                                if (!data) {
                                    result = {
                                        idLotto: lot.lotto,
                                        anno: lot.anno,
                                        [dataField]: status,
                                        revisioniGmpDoc: gmpStatuses
                                    };
                                } else {
                                    result = {
                                        ...data,
                                        [dataField]: status,
                                        revisioniGmpDoc: gmpStatuses
                                    };
                                }
                                onConfirm(result);
                            }}
                        >
                            {t("confirm")}
                        </Button>
                    </div>
                </div>
            </div>
        </Overlay>
    );
};

const Button = ({
    children,
    onClick = () => {},
    enabled = true,
    className = "",
    enabledClassName = "",
    disabledClassName = ""
}) => (
    <div
        className={
            "p-2 font-bold text-xl flex items-center justify-center border-2 shadow rounded-md " +
            className +
            (enabled
                ? " hover:bg-gray-100 cursor-pointer " + enabledClassName
                : " cursor-default bg-gray-100 " + disabledClassName)
        }
        onClick={() => {
            if (!enabled) {
                return;
            }
            onClick();
        }}
    >
        {children}
    </div>
);

export default EditPopup;
