import React, { useState, useMemo } from "react";
import RegistryTab from "../registryTab";
import { registryObjs } from "../anagrafica";
import { ReactComponent as PlusIcon } from "../../../svgIcons/plus-solid.svg";
import { useRequest } from "../../../utils/requestManager";
import { BATCH_RECORDS, FORMS } from "../../../utils/requests";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import Spinner from "../../../components/spinner";
import { ReactComponent as EditIcon } from "../../../svgIcons/edit-regular.svg";
import FormsPopup from "./formsPopup";
import Input from "../../../components/input";

const Forms = ({ handleChange }) => {
    const [showPopup, setShowPopup] = useState({ showing: false, new: false, formEdit: null });
    const [filterString, setFilterString] = useState("");

    const {
        data: forms,
        isLoading: isLoadingForms,
        mutate: mutateForms,
        request
    } = useRequest(FORMS, {
        transformData: (data) => {
            const result = data.map((r) => ({
                ...r,
                inizioValidita: parseISO(r.inizioValidita)
            }));
            return result;
        }
    });

    const { data: batchRecordIds, isLoading: isLoadingBatchRecords } = useRequest(BATCH_RECORDS, {
        transformData: (data) => {
            const result = data.map((r) => r.id);
            return result;
        }
    });

    const filteredData = useMemo(() => {
        if (forms)
            return forms.filter(
                (item) =>
                    item.batchRecordIds.some((elem) => elem.toLowerCase().includes(filterString.toLowerCase())) ||
                    item.id.toLowerCase().includes(filterString.toLowerCase()) ||
                    item.descrizione?.toLowerCase().includes(filterString.toLowerCase())
            );
        return null;
    }, [forms, filterString]);

    const { t } = useTranslation();

    return (
        <>
            <div className="flex justify-center w-full items-center">
                <RegistryTab
                    tabsObjs={registryObjs}
                    onChange={handleChange}
                    currentLink={registryObjs[2].link}
                    disabled={isLoadingBatchRecords || isLoadingForms}
                />
                <div
                    className={
                        "ml-5 mb-4 items-center border-1 shadow bg-green-700 hover:bg-green-600 p-1.5 cursor-pointer rounded-md " +
                        (isLoadingBatchRecords && isLoadingForms
                            ? "cursor-not-allowed bg-gray-200"
                            : "cursor-pointer bg-green-700 hover:bg-green-600")
                    }
                    onClick={() => {
                        if (!isLoadingBatchRecords && !isLoadingForms)
                            setShowPopup({ showing: true, new: true, formEdit: null });
                    }}
                >
                    <PlusIcon className="w-5 h-5 text-white" />
                </div>
            </div>
            <div className="w-11/12 flex mb-12 mt-4 flex-wrap">
                {isLoadingForms || isLoadingBatchRecords ? (
                    <Spinner />
                ) : (
                    <>
                        <div className="w-1/4 mb-5">
                            <Input
                                title={t("search")}
                                placeholder={
                                    t("search") +
                                    " " +
                                    t("by") +
                                    " " +
                                    t("identifier") +
                                    ", " +
                                    t("batchRecord") +
                                    " " +
                                    t("or") +
                                    " " +
                                    t("description")
                                }
                                value={filterString}
                                searchIcon={true}
                                onChange={setFilterString}
                            />
                        </div>
                        <table className="w-full rounded-t-md text-sm text-center">
                            <thead className="text-xl">
                                <tr className="border-b-2">
                                    <th className="p-1 border-2">{t("identifier")}</th>
                                    <th className="p-1 border-2">{t("batchRecords")}</th>
                                    <th className="p-1 border-2">{t("description")}</th>
                                    <th className="p-1 border-2">{t("version")}</th>
                                    <th className="p-1 border-2">{t("link")}</th>
                                    <th className="p-1 border-2">{t("validityStart")}</th>
                                    <th className="p-1 border-2">{t("edit")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((form) => (
                                    <tr key={form.id}>
                                        <td className="p-2 border-1 underline text-green-500 text-base whitespace-nowrap">
                                            {form.id}
                                        </td>
                                        <td className="p-2 border-1">
                                            {form.batchRecordIds.length === 0
                                                ? t("noBatchRecords")
                                                : form.batchRecordIds.join(", ")}
                                        </td>
                                        <td className="p-2 border-1">{form.descrizione}</td>
                                        <td className="p-2 border-1">{form.versione}</td>
                                        <td className="p-2 border-1 underline text-blue-600">
                                            <a href={form.link} target="_blank" rel="noreferrer">
                                                {form.link}
                                            </a>
                                        </td>
                                        <td className="p-2 border-1">
                                            {format(form.inizioValidita, t("shortDateFormat"))}
                                        </td>
                                        <td className="p-2 border-1">
                                            <div className="flex items-center justify-center w-full h-full">
                                                <div
                                                    className="border-1 shadow bg-gray-300 hover:bg-gray-200 p-1.5 cursor-pointer rounded-md"
                                                    onClick={() => {
                                                        setShowPopup({
                                                            showing: true,
                                                            new: false,
                                                            formEdit: form
                                                        });
                                                    }}
                                                >
                                                    <EditIcon className="w-5 h-5 text-gray-700" />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
            {showPopup.showing && (
                <FormsPopup
                    isNew={showPopup.new}
                    form={showPopup.formEdit}
                    usedIds={forms.map((f) => f.id)}
                    request={request}
                    availableBatchRecords={batchRecordIds}
                    onClose={() => setShowPopup({ showing: false, new: null })}
                    onSave={(editedForm) => {
                        if (showPopup.new) {
                            mutateForms((old) =>
                                [...old, editedForm].sort((a, b) =>
                                    a.id.toLowerCase() > b.id.toLowerCase()
                                        ? 1
                                        : b.id.toLowerCase() > a.id.toLowerCase()
                                        ? -1
                                        : 0
                                )
                            );
                        } else {
                            mutateForms((old) => {
                                const index = old.findIndex((r) => r.id === editedForm.id);
                                old[index] = editedForm;
                                return [...old];
                            });
                        }
                        setShowPopup({ showing: false, new: null });
                    }}
                    onDelete={() => {
                        mutateForms((old) => old.filter((r) => r.id !== showPopup.formEdit.id));
                        setShowPopup({ showing: false, new: null });
                    }}
                />
            )}
        </>
    );
};

export default Forms;
