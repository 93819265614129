import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CommonButton } from "../components/commonButton";
import Input from "../components/input";
import PageHeader from "../components/pageHeader";
import { ReactComponent as UserIcon } from "../svgIcons/user-solid.svg";
import { topBarType, USER_TOKEN_KEY } from "../utils/constants";
import { AppContext } from "../utils/contexts";
import { LOGIN } from "../utils/requests";

const Login = ({ setShowLoader }) => {
    const { t } = useTranslation();
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);

    const { setToken, setTopBarOptions } = useContext(AppContext);

    useEffect(() => {
        setTopBarOptions({
            pageTitle: "S-Print",
            barType: topBarType.APP_NAME
        });
    }, [setTopBarOptions, t]);

    const buttonEnabled = useMemo(
        () => username && username.trim().length > 0 && password && password.trim().length > 0,
        [username, password]
    );

    return (
        <div className="flex flex-col w-2/4 items-center justify-center min-w-30rem">
            <PageHeader title={t("adminLogin")} icon={UserIcon} />
            <div className="w-3/5 flex flex-col items-center justify-center mt-7">
                <Input
                    title="Username"
                    placeholder="Username"
                    type="username"
                    value={username}
                    onChange={(value) => {
                        setUsername(value);
                    }}
                />
                <div className="mt-5 w-full">
                    <Input
                        title="Password"
                        placeholder="Password"
                        type="password"
                        value={password}
                        onChange={(value) => {
                            setPassword(value);
                        }}
                    />
                </div>
                <div className="w-full mt-3">
                    <Link className="underline text-red-700 cursor-pointer" to="/password/forgot">
                        {t("forgotPassword")}
                    </Link>
                </div>
                <CommonButton
                    className="mt-7 mb-10 text-red-700"
                    enabled={buttonEnabled}
                    onClick={async () => {
                        if (!buttonEnabled) {
                            return;
                        }
                        setShowLoader(true);

                        const response = await fetch(LOGIN, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                username,
                                password
                            })
                        });

                        if (!response.ok) {
                            if (response.status === 401) {
                                alert(t("loginFailed"));
                            } else {
                                alert(t("fetchError"));
                            }
                            setShowLoader(false);
                            return;
                        }

                        const result = await response.json();

                        setToken(result.token);
                        localStorage.setItem(USER_TOKEN_KEY, result.token);

                        setShowLoader(false);
                    }}
                >
                    {t("login")}
                </CommonButton>
            </div>
        </div>
    );
};

export default Login;
