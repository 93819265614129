import { useRef } from "react";

const TextArea = ({ title, placeholder, value, minHeight, onChange, disabled = false }) => {
    const areaRef = useRef(null);

    return (
        <div className={"relative w-full"}>
            {title && <div className={"font-medium text-base mb-1"}>{title}</div>}
            <textarea
                ref={areaRef}
                placeholder={placeholder}
                style={
                    areaRef.current && value && value.length > 0
                        ? {
                              minHeight,
                              height: `${
                                  areaRef.current.scrollHeight /
                                  parseFloat(getComputedStyle(document.documentElement).fontSize)
                              }rem`
                          }
                        : {
                              minHeight
                          }
                }
                className={
                    "w-full rounded-md px-2 py-1 outline-none overflow-hidden resize-none shadow border-1 border-gray-400"
                }
                onChange={(e) => onChange(e.target.value)}
                value={value || ""}
                readOnly={disabled}
            />
        </div>
    );
};

export default TextArea;
