const Switch = ({ value = false, onChange = () => {} }) => (
    <div
        className={"rounded-3xl p-0.5 cursor-pointer " + (value ? " bg-green-600" : " bg-gray-300")}
        onClick={() => {
            onChange(!value);
        }}
    >
        <div className={"w-12 flex items-center " + (value ? "justify-end" : "justify-start")}>
            <div className={"transition-width ease-linear duration-75 " + (value ? "w-full" : "w-0")} />
            <div>
                <div className="h-6 w-6 bg-gray-100 rounded-full"></div>
            </div>
        </div>
    </div>
);

export default Switch;
