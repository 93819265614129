import React, { useEffect, useState } from "react";
import { topBarType, USER_TOKEN_KEY } from "./utils/constants";
import Layout from "./components/layout";
import { AppContext } from "./utils/contexts";

const App = () => {
    const [token, setToken] = useState(() => {
        const t = localStorage.getItem(USER_TOKEN_KEY);
        if (t && t.trim().length > 0) {
            return t;
        }
        return null;
    });

    const [topBarOptions, setTopBarOptions] = useState({
        pageTitle: "S-Print",
        barType: topBarType.APP_NAME
    });

    useEffect(() => {
        document.title = topBarOptions.pageTitle;
    }, [topBarOptions]);

    return (
        <AppContext.Provider value={{ token, setToken, ...topBarOptions, setTopBarOptions }}>
            <Layout />
        </AppContext.Provider>
    );
};

export default App;
