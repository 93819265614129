import React, { useContext, useEffect } from "react";
import { topBarType } from "../../utils/constants";
import { AppContext } from "../../utils/contexts";
import { useTranslation } from "react-i18next";
import BatchRecords from "./subpages/batchRecords";
import BridgeRecords from "./subpages/bridgeRecords";
import Forms from "./subpages/forms";
import { useRouteMatch, Route, useHistory } from "react-router-dom";
import NavigationMenu from "../../components/navigationMenu";

export const registryObjs = [
    {
        title: "Batch Records",
        link: "batchRecords"
    },
    {
        title: "Bridge Records",
        link: "bridgeRecords"
    },
    {
        title: "Forms",
        link: "forms"
    }
];

const Anagrafica = () => {
    const { setTopBarOptions } = useContext(AppContext);
    const { t } = useTranslation();
    const { path } = useRouteMatch();
    const history = useHistory();

    useEffect(() => {
        setTopBarOptions({
            pageTitle: t("registry"),
            barType: topBarType.TITLE
        });
    }, [setTopBarOptions, t]);

    const changePage = (link) => {
        history.push(`${path}/${link}`);
    };

    return (
        <>
            <div className="w-full mr-20">
                <NavigationMenu />
            </div>
            <Route path={`${path}/batchRecords`}>
                <BatchRecords handleChange={changePage} />
            </Route>
            <Route path={`${path}/bridgeRecords`}>
                <BridgeRecords handleChange={changePage} />
            </Route>
            <Route path={`${path}/forms`}>
                <Forms handleChange={changePage} />
            </Route>
        </>
    );
};

export default Anagrafica;
