import { format, parseISO } from "date-fns";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../../components/input";
import Spinner from "../../../components/spinner";
import { ReactComponent as EditIcon } from "../../../svgIcons/edit-regular.svg";
import { ReactComponent as PlusIcon } from "../../../svgIcons/plus-solid.svg";
import { useRequest } from "../../../utils/requestManager";
import { BATCH_RECORDS } from "../../../utils/requests";
import { registryObjs } from "../anagrafica";
import RegistryTab from "../registryTab";
import BatchRecordsPopup from "./batchRecordsPopup";

const BatchRecords = ({ handleChange }) => {
    const [showPopup, setShowPopup] = useState({ showing: false, new: false, batchRecordEdit: null });
    const [filterString, setFilterString] = useState("");

    const {
        data: batchRecords,
        isLoading: isLoadingBR,
        mutate: mutateBatchRecords,
        request
    } = useRequest(BATCH_RECORDS, {
        transformData: (data) => {
            const result = data.map((r) => ({
                ...r,
                inizioValidita: parseISO(r.inizioValidita),
                tipoPlasma: r.tipoPlasma.includes("ALTRO")
                    ? [t("other")]
                    : r.tipoPlasma.includes("TUTTI")
                    ? [t("all")]
                    : r.tipoPlasma
            }));
            return result;
        }
    });

    const filteredData = useMemo(() => {
        if (filterString.trim() === "") return batchRecords;
        if (batchRecords)
            return batchRecords.filter(
                (item) =>
                    item.id.toLowerCase().includes(filterString.toLowerCase()) ||
                    item.descrizione?.toLowerCase().includes(filterString.toLowerCase()) ||
                    item.descrizioneBreve?.toLowerCase().includes(filterString.toLowerCase())
            );
        return null;
    }, [batchRecords, filterString]);

    const { t } = useTranslation();

    return (
        <>
            <div className="flex justify-center w-full items-center">
                <RegistryTab
                    tabsObjs={registryObjs}
                    onChange={handleChange}
                    currentLink={registryObjs[0].link}
                    disabled={isLoadingBR}
                />
                <div
                    className={
                        "ml-5 mb-4 items-center border-1 shadow p-1.5 rounded-md " +
                        (isLoadingBR
                            ? "cursor-not-allowed bg-gray-200"
                            : "cursor-pointer bg-green-700 hover:bg-green-600")
                    }
                    onClick={() => {
                        if (!isLoadingBR) setShowPopup({ showing: true, new: true, batchRecordEdit: null });
                    }}
                >
                    <PlusIcon className="w-5 h-5 text-white" />
                </div>
            </div>
            <div className="w-11/12 flex mb-12 mt-4 flex-wrap">
                {isLoadingBR ? (
                    <Spinner />
                ) : (
                    <>
                        <div className="w-1/4 mb-5">
                            <Input
                                title={t("search")}
                                placeholder={
                                    t("search") +
                                    " " +
                                    t("by") +
                                    " " +
                                    t("identifier") +
                                    " " +
                                    t("or") +
                                    " " +
                                    t("description")
                                }
                                value={filterString}
                                searchIcon={true}
                                onChange={setFilterString}
                            />
                        </div>
                        <table className="w-full rounded-t-md text-sm text-center">
                            <thead className="text-xl">
                                <tr className="border-b-2">
                                    <th className="p-1 border-2">{t("identifier")}</th>
                                    <th className="p-1 border-2">{t("description")}</th>
                                    <th className="p-1 border-2">{t("shortDescription")}</th>
                                    <th className="p-1 border-2">{t("plasmaType")}</th>
                                    <th className="p-1 border-2">{t("version")}</th>
                                    <th className="p-1 border-2">{t("link")}</th>
                                    <th className="p-1 border-2">{t("validityStart")}</th>
                                    <th className="p-1 border-2">{t("exportColor")}</th>
                                    <th className="p-1 border-2">{t("order")}</th>
                                    <th className="p-1 border-2">{t("edit")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((batchRecord) => (
                                    <tr key={batchRecord.id}>
                                        <td className="p-2 border-1 underline text-green-500 text-base whitespace-nowrap">
                                            {batchRecord.id}
                                        </td>
                                        <td className="p-2 border-1">{batchRecord.descrizione}</td>
                                        <td className="p-2 border-1">{batchRecord.descrizioneBreve}</td>
                                        <td className="p-2 border-1">{batchRecord.tipoPlasma.join(", ")}</td>
                                        <td className="p-2 border-1">{batchRecord.versione}</td>
                                        <td className="p-2 border-1 underline text-blue-600">
                                            <a href={batchRecord.link} target="_blank" rel="noreferrer">
                                                {batchRecord.link}
                                            </a>
                                        </td>
                                        <td className="p-2 border-1">
                                            {format(batchRecord.inizioValidita, t("shortDateFormat"))}
                                        </td>
                                        <td className="p-1 border-1">
                                            <div className="flex items-center justify-center w-full h-full">
                                                <div
                                                    style={{
                                                        backgroundColor: batchRecord.exportColor
                                                            ? batchRecord.exportColor
                                                            : "",
                                                        height: "24px",
                                                        width: "40px"
                                                    }}
                                                ></div>
                                            </div>
                                        </td>
                                        <td className="p-2 border-1">{batchRecord.ordine}</td>
                                        <td className="p-2 border-1">
                                            <div className="flex items-center justify-center w-full h-full">
                                                <div
                                                    className="border-1 shadow bg-gray-300 hover:bg-gray-200 p-1.5 cursor-pointer rounded-md"
                                                    onClick={() => {
                                                        setShowPopup({
                                                            showing: true,
                                                            new: false,
                                                            batchRecordEdit: batchRecord
                                                        });
                                                    }}
                                                >
                                                    <EditIcon className="w-5 h-5 text-gray-700" />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
            {showPopup.showing && (
                <BatchRecordsPopup
                    isNew={showPopup.new}
                    usedIds={batchRecords.map((br) => br.id)}
                    batchRecord={showPopup.batchRecordEdit}
                    request={request}
                    onClose={() => setShowPopup({ showing: false, new: null })}
                    onSave={(editedBatchRecord) => {
                        if (showPopup.new) {
                            mutateBatchRecords((old) =>
                                [...old, editedBatchRecord].sort((a, b) => (a.id > b.id ? 1 : -1))
                            );
                        } else {
                            mutateBatchRecords((old) => {
                                const index = old.findIndex((r) => r.id === editedBatchRecord.id);
                                old[index] = editedBatchRecord;
                                return [...old];
                            });
                        }
                        setShowPopup({ showing: false, new: null });
                    }}
                    onDelete={() => {
                        mutateBatchRecords((old) => old.filter((r) => r.id !== showPopup.batchRecordEdit.id));
                        setShowPopup({ showing: false, new: null });
                    }}
                />
            )}
        </>
    );
};

export default BatchRecords;
