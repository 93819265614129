import { format, isAfter, isBefore, parseISO } from "date-fns";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import NavigationMenu from "../../components/navigationMenu";
import Spinner from "../../components/spinner";
import Switch from "../../components/switch";
import { API_SHORT_DATE_FORMAT } from "../../utils/constants";
import { useRequest } from "../../utils/requestManager";
import {
    ADD_CALENDAR_EXCEPTION,
    CALENDAR_EXCEPTIONS,
    DELETE_CALENDAR_EXCEPTION,
    SETTINGS,
    USERS
} from "../../utils/requests";
import CalendarExceptions from "./calendarExceptions";
import Users from "./users";

const Settings = () => {
    const { t } = useTranslation();

    const { data: settings, isLoading: isLoadingSettings, mutate: mutateSettings, request } = useRequest(SETTINGS);
    const {
        data: calendarExceptions,
        isLoading: isLoadingCalendarExceptions,
        mutate: mutateExceptions
    } = useRequest(CALENDAR_EXCEPTIONS, {
        transformData: (data) =>
            data.map((e) => ({
                ...e,
                eccezione: parseISO(e.eccezione)
            }))
    });

    const { data: users, isLoading: isLoadingUsers, mutate: mutateUsers } = useRequest(USERS, { initialState: [] });

    const onSettingsChange = useCallback(
        (setting, value) => {
            const oldValue = settings[setting];
            const newSettings = {
                ...settings,
                [setting]: value
            };

            request(SETTINGS, {
                method: "PUT",
                body: JSON.stringify(newSettings),
                ignoreResponse: true
            }).then((response) => {
                if (response.ok) {
                    return;
                }

                alert(t(response.errorMessage));
                mutateSettings((old) => ({
                    ...old,
                    [setting]: oldValue
                }));
                return;
            });

            mutateSettings(newSettings);
        },
        [mutateSettings, request, settings, t]
    );

    const onExceptionsAdd = useCallback(
        (exception) => {
            const randomId = Math.random().toString(36).substr(2, 9);

            request(ADD_CALENDAR_EXCEPTION, {
                method: "POST",
                params: {
                    eccezione: format(exception, API_SHORT_DATE_FORMAT)
                }
            }).then((response) => {
                if (response.ok) {
                    mutateExceptions((old) =>
                        old.map((e) =>
                            e.id === randomId
                                ? {
                                      id: response.data.id,
                                      eccezione: e.eccezione
                                  }
                                : e
                        )
                    );
                    return;
                }

                if (response.status === 409) {
                    alert(t("existingException"));
                } else {
                    alert(t(response.errorMessage));
                }

                mutateExceptions((old) => [...old.filter((e) => e.id !== randomId)]);
            });

            mutateExceptions((old) => {
                const newValue = [
                    ...old,
                    {
                        id: randomId,
                        eccezione: exception
                    }
                ];
                newValue.sort(({ eccezione: a }, { eccezione: b }) => {
                    if (isAfter(a, b)) {
                        return 1;
                    }
                    if (isBefore(a, b)) {
                        return -1;
                    }
                    return 0;
                });
                return newValue;
            });
        },
        [mutateExceptions, request, t]
    );

    const onExceptionsDelete = useCallback(
        (exception) => {
            request(DELETE_CALENDAR_EXCEPTION, {
                method: "DELETE",
                params: {
                    id: exception.id
                },
                ignoreResponse: true
            }).then((response) => {
                if (response.ok) {
                    return;
                }

                alert(t(response.errorMessage));

                mutateExceptions((old) => {
                    const newValue = [...old, exception];
                    newValue.sort(({ eccezione: a }, { eccezione: b }) => {
                        if (isAfter(a, b)) {
                            return 1;
                        }
                        if (isBefore(a, b)) {
                            return -1;
                        }
                        return 0;
                    });
                    return newValue;
                });
            });
            mutateExceptions((old) => [...old.filter((e) => e.id !== exception.id)]);
        },
        [mutateExceptions, request, t]
    );

    return (
        <div className="w-11/12 flex mb-12">
            {(isLoadingSettings || isLoadingCalendarExceptions || isLoadingUsers) && (
                <div className="h-40 flex items-center justify-center w-full">
                    <Spinner />
                </div>
            )}
            {!isLoadingSettings && !isLoadingCalendarExceptions && !isLoadingUsers && (
                <div className="w-full pt-8">
                    <div className="flex items-center justify-between max-w-sm mt-4">
                        <div className="text-2xl font-semibold mr-4">{t("version")}</div>
                        <div className="text-2xl">{settings.version}</div>
                    </div>
                    <LeadTimeRow
                        title={t("minLeadTime")}
                        value={settings.minLeadTime !== null ? settings.minLeadTime : ""}
                        onChange={(value) => {
                            onSettingsChange("minLeadTime", value);
                        }}
                    />
                    <LeadTimeRow
                        title={t("maxLeadTime")}
                        value={settings.maxLeadTime !== null ? settings.maxLeadTime : ""}
                        onChange={(value) => {
                            onSettingsChange("maxLeadTime", value);
                        }}
                    />
                    <CalendarExceptions
                        exceptions={calendarExceptions}
                        onExceptionsAdd={onExceptionsAdd}
                        onExceptionsDelete={onExceptionsDelete}
                    />
                    <WeekendSwitch
                        title={t("saturdayExceptions")}
                        value={settings.saturdayException}
                        onChange={(value) => {
                            onSettingsChange("saturdayException", value);
                        }}
                    />
                    <WeekendSwitch
                        title={t("sundayExceptions")}
                        value={settings.sundayException}
                        onChange={(value) => {
                            onSettingsChange("sundayException", value);
                        }}
                    />
                    <Users users={users} mutateUsers={mutateUsers} request={request} />
                </div>
            )}
            <NavigationMenu />
        </div>
    );
};

const LeadTimeRow = ({ title, value, onChange }) => (
    <div className="flex items-center justify-between max-w-sm mt-4">
        <div className="text-2xl font-semibold mr-4">{title}</div>
        <input
            type="number"
            className="border-1 border-gray-600 rounded-md text-3xl w-14"
            value={value}
            onChange={(e) => {
                let value = e.target.value;
                if (value.trim().length === 0) {
                    return;
                }
                if (/[^0-9]*/.test(value)) {
                    value = value.replace(/[^0-9]*/g, "");
                }
                onChange(value);
            }}
        />
    </div>
);

const WeekendSwitch = ({ title, value, onChange }) => (
    <div className="flex items-center justify-between max-w-sm mt-4">
        <div className="text-2xl font-semibold mr-4">{title}</div>
        <Switch value={value} onChange={onChange} />
    </div>
);

export default Settings;
