import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import {
    startOfWeek,
    startOfMonth,
    endOfMonth,
    eachDayOfInterval,
    format,
    getMonth,
    getYear,
    subDays,
    addDays,
    subMonths,
    addMonths,
    isSameMonth,
    endOfWeek,
    isSameDay,
    endOfDay,
    add,
    eachWeekOfInterval
} from "date-fns";
import { ReactComponent as CalendarIcon } from "../svgIcons/calendar-alt-solid.svg";
import { ReactComponent as CaretLeftIcon } from "../svgIcons/caret-left-solid.svg";
import { ReactComponent as CaretRightIcon } from "../svgIcons/caret-right-solid.svg";
import { useTranslation } from "react-i18next";
import { CALENDAR_DAYS } from "../utils/constants";

const CalendarWeekInput = ({ value = null, title, onChange = () => {}, placeholder, disabled = false }) => {
    const [open, setOpen] = useState(false);
    const popupRef = useRef(null);

    const { t } = useTranslation();

    const [calendarDate, setCalendarDate] = useState(value || new Date());

    const closePopup = useCallback(() => {
        if (window.innerWidth < 800) {
            if (popupRef.current) {
                const bounding = popupRef.current.getBoundingClientRect();
                if (bounding.top <= 0 || bounding.top >= window.innerHeight) {
                    setOpen(false);
                }
            }
        } else if (open) {
            setOpen(false);
        }
    }, [open]);

    const closePopupOnClick = useCallback(
        (e) => {
            if (popupRef.current && open && !popupRef.current.contains(e.target)) {
                setOpen(false);
            }
        },
        [open]
    );

    useEffect(() => {
        window.addEventListener("scroll", closePopup);
        window.addEventListener("click", closePopupOnClick);

        return () => {
            window.removeEventListener("scroll", closePopup);
            window.removeEventListener("click", closePopupOnClick);
        };
    }, [closePopup, closePopupOnClick]);

    useEffect(() => {
        if (disabled && open) {
            setOpen(false);
        }
    }, [open, disabled]);

    const formattedValue = useMemo(() => {
        if (!value) {
            return null;
        }
        const start = value;
        const end = endOfDay(add(value, { days: CALENDAR_DAYS }));
        return format(start, t("shortDateFormat")) + " - " + format(end, t("shortDateFormat"));
    }, [t, value]);

    return (
        <div className={"relative w-full h-full"}>
            <div className={"font-medium text-base mb-1 ml-7"}>{title}</div>
            <div className="flex items-center">
                <CaretLeftIcon
                    className={
                        "h-6 w-6 rounded-md mr-1 " +
                        (disabled
                            ? "cursor-not-allowed text-gray-400"
                            : "cursor-pointer hover:bg-gray-300 text-gray-700")
                    }
                    onClick={() => {
                        onChange(subDays(value, 1));
                        setCalendarDate(subDays(value, 1));
                    }}
                />
                <div
                    className={
                        "flex items-center p-2 rounded-md shadow border-1 border-gray-400 w-full " +
                        (disabled ? "cursor-not-allowed bg-gray-200" : "cursor-pointer")
                    }
                    onClick={() => {
                        if (disabled) {
                            return;
                        }
                        setOpen((open) => !open);
                    }}
                >
                    <div
                        className={
                            "h-5 w-full flex items-center text-sm overflow-hidden overflow-ellipsis whitespace-nowrap select-none"
                        }
                    >
                        {!value && placeholder}
                        {formattedValue}
                    </div>
                    <CalendarIcon className={"h-4 w-4 ml-1 text-gray-400"} />
                </div>
                <CaretRightIcon
                    className={
                        "h-6 w-6 rounded-md ml-1 " +
                        (disabled
                            ? "cursor-not-allowed text-gray-400"
                            : "cursor-pointer hover:bg-gray-300 text-gray-700")
                    }
                    onClick={() => {
                        onChange(addDays(value, 1));
                        setCalendarDate(addDays(value, 1));
                    }}
                />
            </div>
            {open && (
                <div className={"absolute z-20 mt-1 top-full w-full flex justify-center"}>
                    <div
                        ref={popupRef}
                        className={"rounded-md py-2 px-3 w-auto shadow bg-white border-1 border-gray-400"}
                    >
                        <div className={"font-semibold flex items-center justify-between mb-2 select-none"}>
                            <CaretLeftIcon
                                className={"h-6 w-6 cursor-pointer rounded-md hover:bg-gray-300"}
                                onClick={() => {
                                    setCalendarDate((prevDate) => subMonths(prevDate, 1));
                                }}
                            />
                            {t("months." + getMonth(calendarDate)) + " " + getYear(calendarDate)}
                            <CaretRightIcon
                                className={"h-6 w-6 cursor-pointer rounded-md hover:bg-gray-300"}
                                onClick={() => {
                                    setCalendarDate((prevDate) => addMonths(prevDate, 1));
                                }}
                            />
                        </div>
                        <table className={"text-center text-sm select-none"}>
                            <thead>
                                <tr>
                                    {Object.values(t("calendar", { returnObjects: true })).map((day) => (
                                        <th className={"font-normal"} key={day}>
                                            {day}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {eachWeekOfInterval(
                                    {
                                        start: startOfMonth(calendarDate),
                                        end: endOfMonth(calendarDate)
                                    },
                                    { weekStartsOn: 1 }
                                ).map((weekStart, index) => (
                                    <tr key={index}>
                                        {eachDayOfInterval({
                                            start: startOfWeek(weekStart, { weekStartsOn: 1 }),
                                            end: endOfWeek(weekStart, { weekStartsOn: 1 })
                                        }).map((weekDay, index) => {
                                            const sameMonth = isSameMonth(calendarDate, weekDay);
                                            return (
                                                <td
                                                    key={index}
                                                    className={
                                                        "hover:bg-blue-300 cursor-pointer p-2 " +
                                                        (!sameMonth ? "text-gray-500" : "") +
                                                        (weekDay >= value &&
                                                        weekDay <= endOfDay(add(value, { days: CALENDAR_DAYS }))
                                                            ? " bg-gray-300"
                                                            : " rounded-full") +
                                                        (isSameDay(weekDay, value)
                                                            ? " rounded-l-md text-red-500"
                                                            : "") +
                                                        (isSameDay(weekDay, add(value, { days: CALENDAR_DAYS }))
                                                            ? " rounded-r-md"
                                                            : "")
                                                    }
                                                    onClick={() => {
                                                        if (disabled) {
                                                            return;
                                                        }
                                                        setCalendarDate(weekDay);
                                                        onChange(weekDay);
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {format(weekDay, "d")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CalendarWeekInput;
