import { Link } from "react-router-dom";
import { ReactComponent as ArrowLeftIcon } from "../svgIcons/arrow-left-solid.svg";
import { ReactComponent as HomeIcon } from "../svgIcons/home-solid.svg";
import { ReactComponent as CogIcon } from "../svgIcons/cog-solid.svg";
import { ReactComponent as SignOutIcon } from "../svgIcons/sign-out-alt-solid.svg";
import { useContext } from "react";
import { AppContext, UserContext } from "../utils/contexts";
import { userRoles, USER_TOKEN_KEY } from "../utils/constants";

const NavigationMenu = ({ previousPage = "/", isHomePage = false }) => {
    const { setToken } = useContext(AppContext);
    const { currentUser } = useContext(UserContext);

    return (
        <div className="flex items-center justify-end h-fitContent">
            {!isHomePage && currentUser.role !== userRoles.ADMIN && (
                <>
                    <Link to={previousPage}>
                        <ArrowLeftIcon className="h-8 w-8 cursor-pointer hover:bg-gray-300 rounded-md text-gray-800" />
                    </Link>
                    <Link to="/">
                        <HomeIcon className="h-8 w-8 cursor-pointer hover:bg-gray-300 rounded-md text-gray-800 ml-3" />
                    </Link>
                </>
            )}
            {currentUser.role === userRoles.GMP_DOC && (
                <Link to={"/settings"}>
                    <CogIcon className="h-8 w-8 cursor-pointer hover:bg-gray-300 rounded-md text-gray-800 ml-3" />
                </Link>
            )}
            <Link
                to={"/"}
                onClick={() => {
                    setToken(null);
                    localStorage.removeItem(USER_TOKEN_KEY);
                }}
            >
                <SignOutIcon className="h-8 w-8 cursor-pointer hover:bg-gray-300 rounded-md text-gray-800 ml-3" />
            </Link>
        </div>
    );
};

export default NavigationMenu;
