const PageHeader = ({ title, icon: Icon }) => {
    return (
        <div className="flex justify-between border-b-1 border-gray-600 w-full">
            <div className="text-red-600 text-3xl">{title}</div>
            <Icon className="w-8 h-8 text-gray-700" />
        </div>
    );
};

export default PageHeader;
