import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CommonLink } from "../components/commonButton";
import NavigationMenu from "../components/navigationMenu";
import PageHeader from "../components/pageHeader";
import { ReactComponent as HomeIcon } from "../svgIcons/home-solid.svg";
import { topBarType, userRoles } from "../utils/constants";
import { AppContext, UserContext } from "../utils/contexts";

const Home = () => {
    const { setTopBarOptions } = useContext(AppContext);

    useEffect(() => {
        setTopBarOptions({
            pageTitle: "S-Print",
            barType: topBarType.APP_NAME
        });
    }, [setTopBarOptions]);

    const { t } = useTranslation();
    const { currentUser } = useContext(UserContext);

    return (
        <>
            <div className="absolute top-6 right-navMenuCalc">
                <NavigationMenu isHomePage={true} />
            </div>
            <div className="flex flex-col w-2/4 items-center justify-center min-w-30rem relative">
                <PageHeader title={"Home Page"} icon={HomeIcon} />
                <div className="mt-8 mb-10 w-full">
                    {currentUser.role === userRoles.GMP_DOC && (
                        <CommonLink to="/anagrafica/batchRecords">{t("registry")}</CommonLink>
                    )}
                    <CommonLink to="/dataRequest" className="mt-8">
                        {t("dataRequestConfiguration")}
                    </CommonLink>
                    {currentUser.role === userRoles.GMP_DOC && (
                        <CommonLink to="/gmpDocRevisions" className="mt-8">
                            {t("revisions")}
                        </CommonLink>
                    )}
                    {[userRoles.GMP_DOC, userRoles.LOGISTIC, userRoles.SUPER_LOGISTIC].includes(currentUser.role) && (
                        <CommonLink to="/programmaStampa" className="mt-8">
                            {t("printProgram")}
                        </CommonLink>
                    )}
                    <CommonLink to="/schedulingBoard" className="mt-8">
                        {t("schedulingBoard")}
                    </CommonLink>
                </div>
            </div>
            <footer
                className={
                    "fixed bottom-0 right-0 left-0 flex items-center justify-center text-xs h-5 z-50 shadow border-t-0.08rem border-gray-500 border-opacity-20 bg-white"
                }
            >
                All Icons By
                <a
                    className={"ml-1 mr-1 font-semibold"}
                    href="https://fontawesome.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Font Awesome
                </a>
                /
                <a
                    className={"ml-1 font-semibold"}
                    href="https://creativecommons.org/licenses/by/4.0/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    CC BY
                </a>
            </footer>
        </>
    );
};

export default Home;
