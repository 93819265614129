import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { CommonButton } from "../../../components/commonButton";
import NavigationMenu from "../../../components/navigationMenu";
import Spinner from "../../../components/spinner";
import { SESSION_WEEK_START, topBarType } from "../../../utils/constants";
import { AppContext } from "../../../utils/contexts";
import { useRequest } from "../../../utils/requestManager";
import { CHANGE_RECORD_STATUS, GET_PRINT_VIEW } from "../../../utils/requests";
import Overlay from "../../dataRequest/overlay";
import { printStatuses } from "../chart/schedulingBoard";
import PageTitle from "../common/pageTitle";

const Print = () => {
    const { setTopBarOptions } = useContext(AppContext);
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        setTopBarOptions({
            pageTitle: t("printRecap"),
            barType: topBarType.TITLE
        });
    }, [setTopBarOptions, t]);

    const { recordId } = useParams();
    //const { currentUser } = useContext(UserContext);

    const apiParams = useMemo(() => ({ id: recordId }), [recordId]);

    const { data: printData, isLoading, request } = useRequest(GET_PRINT_VIEW, { params: apiParams });

    const [isSaving, setIsSaving] = useState(false);

    return (
        <div className="w-11/12 flex flex-col items-center">
            <div className="w-full flex justify-between">
                <PageTitle>{t("printRecap")}</PageTitle>
                <NavigationMenu previousPage={`/schedulingBoard/revision/${recordId}`} />
            </div>
            {isLoading && (
                <div className="h-40 w-full justify-center">
                    <Spinner />
                </div>
            )}
            {!isLoading && printData && (
                <>
                    <table className="text-center mb-12 mt-8">
                        <thead className="text-xl">
                            <tr className="border-b-2">
                                <th className="p-1"></th>
                                <th className="p-1 border-2">{t("form")}</th>
                                <th className="p-1 border-2">{t("version")}</th>
                                <th className="p-1 border-2">{t("description")}</th>
                                <th className="p-1 border-2">{t("link")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border-b-2">
                                <td className="p-2 text-left font-bold text-lg">{t("batchRecord")}</td>
                                <td className="p-2 border-1 underline text-green-500 text-lg">{printData.id}</td>
                                <td className="p-2 border-1">{printData.versione}</td>
                                <td className="p-2 border-1">{printData.descrizione}</td>
                                <td className="p-2 border-1 underline text-blue-600">
                                    <a href={printData.link} target="_blank" rel="noreferrer">
                                        {printData.link}
                                    </a>
                                </td>
                            </tr>
                            {printData.forms.map((form, index) => (
                                <tr key={form.id}>
                                    <td className="p-2 text-left font-bold text-lg">
                                        {index === 0 ? t("attachments") : null}
                                    </td>
                                    <td className="p-2 border-1 underline text-green-500 text-lg">{form.id}</td>
                                    <td className="p-2 border-1">{form.versione}</td>
                                    <td className="p-2 border-1">{form.descrizione}</td>
                                    <td className="p-2 border-1 underline text-blue-600">
                                        <a href={form.link} target="_blank" rel="noreferrer">
                                            {form.link}
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="w-full flex justify-end mb-8">
                        <div className="w-min">
                            <CommonButton
                                className="text-green-600 border-2 font-bold border-green-600"
                                onClick={async () => {
                                    setIsSaving(true);

                                    const response = await request(CHANGE_RECORD_STATUS, {
                                        method: "PUT",
                                        ignoreResponse: true,
                                        params: {
                                            id: recordId,
                                            stato: printStatuses.Stampato.key
                                        }
                                    });

                                    if (!response.ok) {
                                        if (response.status !== 401) {
                                            setIsSaving(false);
                                        }
                                        alert(t(response.errorMessage));
                                        return;
                                    }

                                    setIsSaving(false);

                                    history.push(
                                        `/schedulingBoard?weekStart=${sessionStorage.getItem(SESSION_WEEK_START)}`
                                    );
                                }}
                            >
                                {t("print")}
                            </CommonButton>
                        </div>
                    </div>
                </>
            )}
            {isSaving && (
                <Overlay>
                    <Spinner />
                </Overlay>
            )}
        </div>
    );
};

export default Print;
