import Overlay from "../pages/dataRequest/overlay";

const Popup = ({ children }) => (
    <Overlay>
        <div className="rounded-md border-2 border-gray-500 bg-white py-4 px-5 max-h-80vh overflow-auto mx-8">
            <div className="flex flex-col items-center justify-center">{children}</div>
        </div>
    </Overlay>
);

export default Popup;
