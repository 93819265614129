import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router";
import { CommonButton, CommonLink } from "../../../../components/commonButton";
import { campaigns, userRoles } from "../../../../utils/constants";
import { printStatuses } from "../../chart/schedulingBoard";
import RevisionTab from "../../common/revisionTab";
import QcQaPdtsForm from "./qcQaPdtsForm";
import SupplyChainForm from "./supplyChainForm";
import { ReactComponent as NotCompleteIcon } from "../../../../svgIcons/times-circle-regular.svg";
import { ReactComponent as CompleteIcon } from "../../../../svgIcons/check-circle-regular.svg";
import { revisionStatuses } from "../revision";
import GmpDocForm from "./gmpDocForm";
import Popup from "../../../../components/popup";
import RecapRow from "../../common/recapRow";
import TextArea from "../../../../components/textArea";

const LogisticForm = ({ record, onNotification, editNoteLogistica }) => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();

    const [showExceptions, setShowExceptions] = useState(true);

    const tabs = useMemo(() => {
        const description = record.descrizione + " (" + t("version") + " " + record.versione + ")";
        let result = [];
        const completeIcon = <CompleteIcon className="w-6 h-6 ml-4 text-green-600" />;
        const incompleteIcon = <NotCompleteIcon className="w-6 h-6 ml-4 text-red-600" />;
        const getIncompleteRevisionForm = (role) => (
            <div className="w-full flex flex-col items-center mt-4">
                <div className="font-bold text-2xl text-gray-600 mb-5">{t("incompleteRevision_sendNotification")}</div>
                <div className="w-48">
                    <CommonButton
                        className="text-red-600 border-2"
                        onClick={() => {
                            onNotification(role);
                        }}
                    >
                        {t("sendNotification")}
                    </CommonButton>
                </div>
            </div>
        );

        if (record.revisioneSupplyChain) {
            if (record.revisioneSupplyChain.status === revisionStatuses.APPLICATO) {
                result.push({
                    title: t(userRoles.SUPPLY_CHAIN),
                    icon: completeIcon,
                    content: (
                        <SupplyChainForm
                            recordId={record.id}
                            revision={record.revisioneSupplyChain}
                            description={description}
                            editable={false}
                            //title={t(userRoles.SUPPLY_CHAIN)}
                            showTitle={false}
                            isAlbumina={record.campagna === campaigns.ALBUMINA}
                        />
                    )
                });
            } else {
                result.push({
                    title: t(userRoles.SUPPLY_CHAIN),
                    icon: incompleteIcon,
                    content: getIncompleteRevisionForm(userRoles.SUPPLY_CHAIN)
                });
            }
        }

        if (record.revisioneGmpDoc) {
            if (record.revisioneGmpDoc.status === revisionStatuses.APPLICATO) {
                result.push({
                    title: t(userRoles.GMP_DOC),
                    icon: completeIcon,
                    content: (
                        <GmpDocForm
                            recordId={record.id}
                            revision={record.revisioneGmpDoc}
                            description={description}
                            editable={false}
                            //title={t(userRoles.SUPPLY_CHAIN)}
                            showTitle={false}
                        />
                    )
                });
            } else {
                result.push({
                    title: t(userRoles.GMP_DOC),
                    icon: incompleteIcon,
                    content: getIncompleteRevisionForm(userRoles.GMP_DOC)
                });
            }
        }

        if (record.revisioneQc) {
            if (record.revisioneQc.status === revisionStatuses.APPLICATO) {
                result.push({
                    title: t(userRoles.QC),
                    icon: completeIcon,
                    content: (
                        <QcQaPdtsForm
                            recordId={record.id}
                            revision={record.revisioneQc}
                            description={description}
                            editable={false}
                            //title={t(userRoles.QC)}
                            showTitle={false}
                        />
                    )
                });
            } else {
                result.push({
                    title: t(userRoles.QC),
                    icon: incompleteIcon,
                    content: getIncompleteRevisionForm(userRoles.QC)
                });
            }
        }

        if (record.revisioneQa) {
            if (record.revisioneQa.status === revisionStatuses.APPLICATO) {
                result.push({
                    title: t(userRoles.QA),
                    icon: completeIcon,
                    content: (
                        <QcQaPdtsForm
                            recordId={record.id}
                            revision={record.revisioneQa}
                            description={description}
                            editable={false}
                            //title={t(userRoles.QA)}
                            showTitle={false}
                        />
                    )
                });
            } else {
                result.push({
                    title: t(userRoles.QA),
                    icon: incompleteIcon,
                    content: getIncompleteRevisionForm(userRoles.QA)
                });
            }
        }

        if (record.revisionePdts) {
            if (record.revisionePdts.status === revisionStatuses.APPLICATO) {
                result.push({
                    title: t(userRoles.PDTS),
                    icon: completeIcon,
                    content: (
                        <QcQaPdtsForm
                            recordId={record.id}
                            revision={record.revisionePdts}
                            description={description}
                            editable={false}
                            //title={t(userRoles.PDTS)}
                            showTitle={false}
                        />
                    )
                });
            } else {
                result.push({
                    title: t(userRoles.PDTS),
                    icon: incompleteIcon,
                    content: getIncompleteRevisionForm(userRoles.PDTS)
                });
            }
        }

        return result;
    }, [
        onNotification,
        record.campagna,
        record.descrizione,
        record.id,
        record.revisioneGmpDoc,
        record.revisionePdts,
        record.revisioneQa,
        record.revisioneQc,
        record.revisioneSupplyChain,
        record.versione,
        t
    ]);

    const exceptionsData = useMemo(() => {
        let exceptions = [];
        if (record.revisioneSupplyChain?.eccezione) {
            exceptions.push({
                role: userRoles.SUPPLY_CHAIN,
                exception: record.revisioneSupplyChain.eccezione
            });
        }
        if (record.revisioneGmpDoc?.eccezione) {
            exceptions.push({
                role: userRoles.GMP_DOC,
                exception: record.revisioneGmpDoc.eccezione
            });
        }
        if (record.revisioneQc?.eccezione) {
            exceptions.push({
                role: userRoles.QC,
                exception: record.revisioneQc.eccezione
            });
        }
        if (record.revisioneQa?.eccezione) {
            exceptions.push({
                role: userRoles.QA,
                exception: record.revisioneQa.eccezione
            });
        }
        if (record.revisionePdts?.eccezione) {
            exceptions.push({
                role: userRoles.PDTS,
                exception: record.revisionePdts.eccezione
            });
        }
        return exceptions;
    }, [
        record.revisioneGmpDoc,
        record.revisionePdts,
        record.revisioneQa,
        record.revisioneQc,
        record.revisioneSupplyChain
    ]);

    return (
        <>
            <div className="flex flex-col items-center justify-center min-h-40 w-full">
                {tabs.length === 0 && <div className="font-bold text-3xl text-gray-600 mb-5">{t("noRevisions")}</div>}
                {tabs.length > 0 && <RevisionTab pages={tabs} />}
                <div className="min-w-30rem w-2/4 self-start">
                    <TextArea
                        minHeight="8rem"
                        title={t("logisticNotes")}
                        placeholder={t("notesPlaceholder")}
                        value={record.noteLogistica}
                        onChange={editNoteLogistica}
                    />
                </div>
                <div className={"w-full flex mt-4 mb-8 pr-6 " + (tabs.length > 0 ? "justify-end" : "justify-center")}>
                    {[
                        printStatuses.Stampabile.key,
                        printStatuses.NonStampabileInRevisioneForm.key,
                        printStatuses.InLavorazione.key
                    ].includes(record.status) && (
                        <div className="w-min">
                            <CommonLink className="text-red-600 border-2 whitespace-nowrap" to={`${url}/print`}>
                                {t("goToPrintScreen")}
                            </CommonLink>
                        </div>
                    )}
                </div>
            </div>
            {showExceptions && exceptionsData.length > 0 && (
                <Popup>
                    <div className="w-full min-w-30rem">
                        <div className={"text-red-600 font-bold text-2xl mb-4 w-full text-center"}>
                            {t("exceptions")}
                        </div>
                        {exceptionsData.map((e) => (
                            <RecapRow key={e.role} title={t(e.role)} value={e.exception} />
                        ))}
                    </div>
                    <div>
                        <CommonButton
                            className="text-red-600 border-2"
                            onClick={() => {
                                setShowExceptions(false);
                            }}
                        >
                            {t("confirm")}
                        </CommonButton>
                    </div>
                </Popup>
            )}
        </>
    );
};

export default LogisticForm;
