import { addDays, endOfDay, format, startOfDay } from "date-fns";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CalendarWeekInput from "../../components/calendarWeekInput";
import NavigationMenu from "../../components/navigationMenu";
import Spinner from "../../components/spinner";
import { API_DATE_FORMAT, CALENDAR_DAYS, topBarType, userRoles } from "../../utils/constants";
import { AppContext, UserContext } from "../../utils/contexts";
import { useRequest } from "../../utils/requestManager";
import {
    APPLY_PLANNING_DATA,
    DELETE_LOT,
    DELETE_PLANNING,
    DOWNLOAD_LOGS,
    EDIT_PLANNING_DATA,
    LOTS,
    PLANNING_DATA
} from "../../utils/requests";
import { BodyCell, Button, HeaderCell, ImportCompletePopup, LegendRow, SavePopup } from "./components";
import EditPopup from "./editPopup";
import FileUpload from "./fileUpload";
import Overlay from "./overlay";
import { ReactComponent as TrashIcon } from "../../svgIcons/trash-alt-solid.svg";

export const requestStatuses = Object.freeze({
    DA_RIVEDERE: "DaRivedere",
    RIVISTO: "Rivisto",
    DA_IMPOSTARE: "DaImpostare"
});
export const statusColors = Object.freeze({
    DaRivedere: "bg-red-600",
    Rivisto: "bg-green-600",
    DaImpostare: "bg-gray-300"
});
const planningStatuses = Object.freeze({ APPLICATO: "Applicato", NON_APPLICATO: "NonApplicato" });
const confirmPopupTypes = Object.freeze({ APPLY: "APPLY", DELETE: "DELETE", DELETE_LOT: "DELETE_LOT" });

const DataRequest = () => {
    const { setTopBarOptions } = useContext(AppContext);
    const { t } = useTranslation();

    useEffect(() => {
        setTopBarOptions({
            pageTitle: t("dataRequestConfiguration"),
            barType: topBarType.TITLE
        });
    }, [setTopBarOptions, t]);

    const { currentUser } = useContext(UserContext);

    const calculateDateRange = useCallback((date) => {
        const startDate = startOfDay(date);
        return {
            date: startDate,
            startDate: format(startDate, API_DATE_FORMAT),
            endDate: format(endOfDay(addDays(startDate, CALENDAR_DAYS)), API_DATE_FORMAT)
        };
    }, []);

    const [dateRange, setDateRange] = useState(calculateDateRange(new Date()));

    // LOT DATA
    const {
        data: lotData,
        isLoading: isLoadingLot,
        request,
        mutate: mutateLotData
    } = useRequest(LOTS, {
        params: dateRange
    });

    // PLANNING DATA
    const {
        data,
        isLoading: isLoadingData,
        mutate: mutatePlanningData
    } = useRequest(PLANNING_DATA, { params: dateRange });

    const [popup, setPopup] = useState({
        show: false,
        lotIndex: null,
        dataIndex: null,
        role: null,
        dataField: null
    });

    const [editedSomething, setEditedSomething] = useState(false);

    const isLogistic = useMemo(
        () => [userRoles.LOGISTIC, userRoles.SUPER_LOGISTIC].includes(currentUser.role),
        [currentUser.role]
    );

    const userCanEdit = useMemo(
        () => !isLogistic && lotData && lotData.length > 0 && data /*&&
            data.some((d) => d.stato === planningStatuses.NON_APPLICATO)*/,
        [data, isLogistic, lotData]
    );

    const enableSave = useMemo(() => {
        if (!data || !editedSomething) {
            return false;
        }
        const editableData = data.filter((d) => d.stato === planningStatuses.NON_APPLICATO);
        if (editableData.length === 0) {
            return false;
        }
        return editableData.every(
            (r) => r.statoSupplyChain && r.statoQC && r.statoQA && r.statoPDTS && r.revisioniGmpDoc
        );
    }, [data, editedSomething]);

    const enableApply = useMemo(() => {
        if (!lotData || !lotData.length === 0 || !data || editedSomething) {
            return false;
        }

        return data.some((d) => d.stato === planningStatuses.NON_APPLICATO);
    }, [data, editedSomething, lotData]);

    const [isSaving, setIsSaving] = useState(false);

    const [showConfirmPopup, setShowConfirmPopup] = useState({
        show: false
    });

    const [discardedLots, setDiscardedLots] = useState(null);
    const [lotsWithErrors, setLotsWithErrors] = useState(null);

    return (
        <>
            <div className="flex justify-center w-11/12">
                <div className="w-full flex flex-col items-center">
                    {!isLoadingLot && !isLoadingData && (!lotData || lotData.length === 0) && (
                        <div className="flex flex-col items-center justify-center min-h-40 pt-10">
                            <div className="font-bold text-3xl text-gray-600 mb-5">{t("noData")}</div>
                        </div>
                    )}
                    {(isLoadingLot || isLoadingData) && (
                        <div className="h-40">
                            <Spinner />
                        </div>
                    )}
                    {!isLoadingLot && !isLoadingData && lotData && lotData.length > 0 && data && data.length > 0 && (
                        <>
                            <table className="w-11/12 text-center mb-32">
                                <thead className="text-xl">
                                    <tr className="border-b-2">
                                        <HeaderCell className="text-left">{t("lot")}</HeaderCell>
                                        <HeaderCell className="border-2">{t(userRoles.SUPPLY_CHAIN)}</HeaderCell>
                                        <HeaderCell className="border-2">{t(userRoles.GMP_DOC)}</HeaderCell>
                                        <HeaderCell className="border-2">{t(userRoles.QC)}</HeaderCell>
                                        <HeaderCell className="border-2">{t(userRoles.QA)}</HeaderCell>
                                        <HeaderCell className="border-2">{t(userRoles.PDTS)}</HeaderCell>
                                        {userCanEdit && <HeaderCell className="border-2"></HeaderCell>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {lotData.map((l, index) => {
                                        const dataIndex = data.findIndex((r) => r.idLotto === l.lotto);
                                        const d = dataIndex >= 0 ? data[dataIndex] : null;
                                        return (
                                            <tr key={l.lotto}>
                                                <td className="p-1 text-left font-bold text-lg">
                                                    {l.lotto}
                                                    {isLogistic && d?.stato === planningStatuses.NON_APPLICATO && "*"}
                                                </td>
                                                <BodyCell
                                                    status={d?.statoSupplyChain}
                                                    editable={
                                                        d?.stato === planningStatuses.NON_APPLICATO && userCanEdit
                                                    }
                                                    onClick={() => {
                                                        setPopup({
                                                            show: true,
                                                            lotIndex: index,
                                                            dataIndex: dataIndex,
                                                            role: userRoles.SUPPLY_CHAIN,
                                                            dataField: "statoSupplyChain"
                                                        });
                                                    }}
                                                />
                                                <BodyCell
                                                    status={
                                                        d?.revisioniGmpDoc.some(
                                                            (r) => r.stato === requestStatuses.DA_RIVEDERE
                                                        )
                                                            ? requestStatuses.DA_RIVEDERE
                                                            : requestStatuses.RIVISTO
                                                    }
                                                    editable={
                                                        d?.stato === planningStatuses.NON_APPLICATO && userCanEdit
                                                    }
                                                    onClick={() => {
                                                        setPopup({
                                                            show: true,
                                                            lotIndex: index,
                                                            dataIndex: dataIndex,
                                                            role: userRoles.GMP_DOC,
                                                            dataField: "statoGmpDoc"
                                                        });
                                                    }}
                                                />
                                                <BodyCell
                                                    status={d?.statoQC}
                                                    editable={
                                                        d?.stato === planningStatuses.NON_APPLICATO && userCanEdit
                                                    }
                                                    onClick={() => {
                                                        setPopup({
                                                            show: true,
                                                            lotIndex: index,
                                                            dataIndex: dataIndex,
                                                            role: userRoles.QC,
                                                            dataField: "statoQC"
                                                        });
                                                    }}
                                                />
                                                <BodyCell
                                                    status={d?.statoQA}
                                                    editable={
                                                        d?.stato === planningStatuses.NON_APPLICATO && userCanEdit
                                                    }
                                                    onClick={() => {
                                                        setPopup({
                                                            show: true,
                                                            lotIndex: index,
                                                            dataIndex: dataIndex,
                                                            role: userRoles.QA,
                                                            dataField: "statoQA"
                                                        });
                                                    }}
                                                />
                                                <BodyCell
                                                    status={d?.statoPDTS}
                                                    editable={
                                                        d?.stato === planningStatuses.NON_APPLICATO && userCanEdit
                                                    }
                                                    onClick={() => {
                                                        setPopup({
                                                            show: true,
                                                            lotIndex: index,
                                                            dataIndex: dataIndex,
                                                            role: userRoles.PDTS,
                                                            dataField: "statoPDTS"
                                                        });
                                                    }}
                                                />
                                                {userCanEdit && (
                                                    <td className="p-2 border-2">
                                                        <Button
                                                            className="whitespace-nowrap"
                                                            disabledClassName="border-gray-500 text-gray-500"
                                                            enabledClassName="text-red-600 border-gray-700"
                                                            onClick={() => {
                                                                setShowConfirmPopup({
                                                                    show: true,
                                                                    type: confirmPopupTypes.DELETE_LOT,
                                                                    lotIndex: index,
                                                                    dataIndex: dataIndex
                                                                });
                                                            }}
                                                        >
                                                            <TrashIcon className="h-5 w-5" />
                                                        </Button>
                                                    </td>
                                                )}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </>
                    )}
                </div>
                <div className="min-w-15rem">
                    <NavigationMenu />
                    <div className="mt-5">
                        <CalendarWeekInput
                            title={t("referencePeriod")}
                            value={dateRange.date}
                            onChange={(date) => setDateRange(calculateDateRange(date))}
                            disabled={isLoadingLot || isLoadingData}
                        />
                    </div>
                    <div className="ml-7 mt-8">
                        <div className={"font-semibold text-lg mb-1"}>{t("colorsLegend")}</div>
                        <LegendRow colorClassName={statusColors.Rivisto}>{t("notRequested")}</LegendRow>
                        <LegendRow colorClassName={statusColors.DaRivedere}>{t("requested")}</LegendRow>
                        <LegendRow colorClassName={statusColors.DaImpostare}>{t("toSet")}</LegendRow>
                        {isLogistic && (
                            <div className="flex items-center">
                                <div className={"h-5 w-8 text-center mr-3 font-bold text-lg"}>*</div>
                                <div>{t("notApplied")}</div>
                            </div>
                        )}
                    </div>
                    {userCanEdit && (
                        <div className="flex items-center justify-center w-full mt-8">
                            <Button
                                className="w-full mr-1"
                                disabledClassName="border-gray-500 text-gray-500"
                                enabledClassName="text-green-600 border-gray-700"
                                enabled={enableSave}
                                onClick={async () => {
                                    setIsSaving(true);
                                    let method = "PUT";
                                    let url = EDIT_PLANNING_DATA;
                                    let ignoreResponse = true;

                                    const response = await request(url, {
                                        method,
                                        body: JSON.stringify(
                                            data.filter((d) => d.stato === planningStatuses.NON_APPLICATO)
                                        ),
                                        ignoreResponse
                                    });

                                    if (!response.ok) {
                                        if (response.status !== 401) {
                                            setIsSaving(false);
                                        }
                                        alert(t(response.errorMessage));
                                        return;
                                    }

                                    setEditedSomething(false);
                                    setIsSaving(false);
                                }}
                            >
                                {t("save")}
                            </Button>
                            <Button
                                enabled={enableApply}
                                className="w-full ml-1"
                                disabledClassName="border-gray-500 text-gray-500"
                                enabledClassName="text-green-600 border-gray-700"
                                onClick={async () => {
                                    setShowConfirmPopup({
                                        show: true,
                                        type: confirmPopupTypes.APPLY
                                    });
                                }}
                            >
                                {t("apply")}
                            </Button>
                        </div>
                    )}
                    {!isLogistic &&
                        !isLoadingLot &&
                        !isLoadingData &&
                        lotData &&
                        lotData.length > 0 &&
                        data &&
                        data.length > 0 && (
                            <Button
                                enabled={true}
                                className="mt-5"
                                disabledClassName="border-gray-500 text-gray-500"
                                enabledClassName="text-red-600 border-gray-700"
                                onClick={async () => {
                                    setShowConfirmPopup({
                                        show: true,
                                        type: confirmPopupTypes.DELETE
                                    });
                                }}
                            >
                                {t("deleteAll")}
                            </Button>
                        )}
                    {!isLogistic && (
                        <div className="mt-6">
                            <div className="font-semibold text-lg mb-1">{t("logDownload")}</div>
                            <Button
                                enabled={true}
                                className="mb-5"
                                disabledClassName="border-gray-500 text-gray-500"
                                enabledClassName="text-red-600 border-gray-700"
                                onClick={async () => {
                                    const response = await request(DOWNLOAD_LOGS, {
                                        ignoreResponse: true
                                    });

                                    const file = await response.response.blob();
                                    const url = URL.createObjectURL(file);
                                    window.open(url, "_blank");
                                    URL.revokeObjectURL(url);
                                }}
                            >
                                {t("download")}
                            </Button>
                            <div className="font-semibold text-lg mb-1">{t("uploadCSV")}</div>
                            <FileUpload
                                setIsSaving={setIsSaving}
                                onUploadStart={() => {
                                    setIsSaving(true);
                                }}
                                onUploadComplete={(response) => {
                                    if (!response.ok) {
                                        if (response.status !== 401) {
                                            setIsSaving(false);
                                        }
                                        alert(t(response.errorMessage));
                                        return;
                                    }

                                    setIsSaving(false);
                                    setDateRange(calculateDateRange(dateRange.date));
                                    setDiscardedLots(response.data.lottiScartati);
                                    setLotsWithErrors(response.data.lottiConErrori);
                                }}
                                request={request}
                            />
                        </div>
                    )}
                </div>
            </div>
            {isSaving && (
                <Overlay>
                    <Spinner />
                </Overlay>
            )}
            {popup.show && (
                <EditPopup
                    lot={lotData[popup.lotIndex]}
                    data={popup.dataIndex >= 0 ? data[popup.dataIndex] : null}
                    role={popup.role}
                    dataField={popup.dataField}
                    onClose={() => {
                        setPopup({
                            show: false
                        });
                    }}
                    onConfirm={(data) => {
                        if (data.idLotto) {
                            mutatePlanningData((old) => {
                                let dIndex = old.findIndex((r) => r.idLotto === data.idLotto && r.anno === data.anno);

                                let newRevisioniLotti;
                                if (dIndex >= 0) {
                                    old[dIndex] = data;
                                    newRevisioniLotti = [...old];
                                } else {
                                    newRevisioniLotti = [...old, data];
                                }
                                return newRevisioniLotti;
                            });
                        } else {
                            mutatePlanningData(
                                lotData.map((lot) => ({
                                    idLotto: lot.lotto,
                                    anno: lot.anno,
                                    statoSupplyChain: data.status,
                                    statoQC: data.status,
                                    statoQA: data.status,
                                    statoPDTS: data.status,
                                    revisioniGmpDoc:
                                        data.status === requestStatuses.DA_RIVEDERE
                                            ? lot.batchRecords.map((br) => ({
                                                  batchRecordId: br,
                                                  stato: requestStatuses.DA_RIVEDERE
                                              }))
                                            : []
                                }))
                            );
                        }
                        setEditedSomething(true);
                        setPopup({
                            show: false
                        });
                    }}
                />
            )}
            {showConfirmPopup.show && (
                <SavePopup
                    onClose={() => {
                        setShowConfirmPopup({
                            show: false
                        });
                    }}
                    onConfirm={async () => {
                        setShowConfirmPopup({
                            show: false
                        });
                        setIsSaving(true);

                        let url;
                        let options;
                        let onComplete;

                        switch (showConfirmPopup.type) {
                            case confirmPopupTypes.APPLY:
                                url = APPLY_PLANNING_DATA;
                                options = {
                                    method: "PUT",
                                    body: JSON.stringify(
                                        data.filter((d) => d.stato === planningStatuses.NON_APPLICATO).map((d) => d.id)
                                    ),
                                    ignoreResponse: true
                                };
                                onComplete = () => {
                                    mutatePlanningData((old) => [
                                        ...old.map((d) => ({
                                            ...d,
                                            stato: planningStatuses.APPLICATO
                                        }))
                                    ]);
                                };
                                break;
                            case confirmPopupTypes.DELETE:
                                url = DELETE_PLANNING;
                                options = {
                                    method: "DELETE",
                                    body: JSON.stringify(data.map((d) => d.id)),
                                    ignoreResponse: true
                                };
                                onComplete = () => {
                                    mutateLotData([]);
                                    mutatePlanningData([]);
                                };
                                break;
                            case confirmPopupTypes.DELETE_LOT:
                                {
                                    url = DELETE_LOT;
                                    const lot = lotData[showConfirmPopup.lotIndex];
                                    options = {
                                        method: "DELETE",
                                        params: {
                                            anno: lot.anno,
                                            lotto: encodeURIComponent(lot.lotto)
                                        },
                                        ignoreResponse: true
                                    };
                                    onComplete = () => {
                                        mutateLotData((old) => [
                                            ...old.filter((l) => l.anno !== lot.anno || l.lotto !== lot.lotto)
                                        ]);
                                        mutatePlanningData((old) => [
                                            ...old.filter((d) => d.anno !== lot.anno || d.idLotto !== lot.lotto)
                                        ]);
                                    };
                                }
                                break;
                            default:
                                setIsSaving(false);
                                return;
                        }

                        console.log(options.params);

                        const response = await request(url, options);

                        if (!response.ok) {
                            if (response.status !== 401) {
                                setIsSaving(false);
                            }
                            alert(t(response.errorMessage));
                            return;
                        }

                        onComplete();

                        setIsSaving(false);
                    }}
                />
            )}
            {discardedLots && lotsWithErrors && (
                <ImportCompletePopup
                    lottiScartati={discardedLots}
                    lottiConErrori={lotsWithErrors}
                    onConfirm={() => {
                        setDiscardedLots(null);
                        setLotsWithErrors(null);
                    }}
                />
            )}
        </>
    );
};

export default DataRequest;
