import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { addDays, addHours, format, getHours, isAfter, isSameDay, setHours, startOfDay } from "date-fns";
import { useTranslation } from "react-i18next";
import { API_DATE_FORMAT, campaigns, SESSION_WEEK_START, TOP_HEIGHT, userRoles } from "../../../utils/constants";
import { printStatuses } from "./schedulingBoard";
import { UserContext } from "../../../utils/contexts";
import { useHistory, useRouteMatch } from "react-router";

am4core.useTheme(am4themes_animated);
const dateStringFormat = "yyyy-MM-dd'T'HH:mm:ss";
const zoomLevels = Object.freeze({ week: "week", day: "day", halfDay: "halfDay", hour: "hour" });
const contextMenuTypes = Object.freeze({ LOT: "LOT", RECORD: "RECORD" });

const Chart = ({
    data = [],
    selectedStatus,
    selectedCampaign,
    minLt,
    maxLt,
    minDate,
    maxDate,
    revisions,
    exceptions,
    logisticNotes,
    onStatusChange = () => {},
    onLotShift = () => {},
    onLotReset = () => {},
    onDeleteRecord = () => {}
}) => {
    const [contextMenu, setContextMenu] = useState({
        show: false,
        type: null,
        x: 0,
        y: 0,
        data: null
    });

    const [hoverDate, setHoverDate] = useState("--");

    const chart = useRef(null);
    const { t } = useTranslation();
    const { currentUser } = useContext(UserContext);
    const history = useHistory();
    const { url } = useRouteMatch();

    const zoomLevel = useRef(zoomLevels.week);

    const contextMenuRef = useRef(null);

    const closePopupOnClick = useCallback(
        (e) => {
            if (contextMenuRef.current && contextMenu.show && !contextMenuRef.current.contains(e.target)) {
                setContextMenu({
                    show: false,
                    type: null,
                    x: 0,
                    y: 0,
                    data: null
                });
            }
        },
        [contextMenu]
    );

    useEffect(() => {
        window.addEventListener("click", closePopupOnClick);

        return () => {
            window.removeEventListener("click", closePopupOnClick);
        };
    }, [closePopupOnClick]);

    useLayoutEffect(() => {
        // CHART INIT
        chart.current = am4core.create("chartdiv", am4charts.XYChart);
        chart.current.dateFormatter.inputDateFormat = dateStringFormat;
        chart.current.paddingRight = 50;

        // CATEGORY AXIS INIT
        let categoryAxis = chart.current.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.minGridDistance = 20;

        if (currentUser.role === userRoles.SUPER_LOGISTIC) {
            categoryAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
            categoryAxis.renderer.labels.template.contextMenuDisabled = true;
            categoryAxis.renderer.labels.template.events.on("rightclick", (ev) => {
                const data = ev.target.dataItem.category;
                const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
                // A causa del relative nel div esterno, bisogna sottrarre
                // alla y i pixel della barra superiore (9rem) e del padding top (1.5 rem)
                const pixelY = TOP_HEIGHT * fontSize;
                setContextMenu({
                    show: true,
                    type: contextMenuTypes.LOT,
                    x: ev.event.pageX,
                    y: ev.event.pageY - pixelY + "px",
                    data
                });
            });
        }

        // DATE AXIS INIT
        let dateAxis = chart.current.xAxes.push(new am4charts.DateAxis());
        dateAxis.dateFormatter.dateFormat = t("chartDateFormat");
        dateAxis.renderer.minGridDistance = 70;
        dateAxis.baseInterval = { timeUnit: "minute" };
        dateAxis.periodChangeDateFormats.setKey("hour", "[bold]eee dd/MM[/]");

        dateAxis.strictMinMax = true;
        dateAxis.renderer.tooltipLocation = 0;
        dateAxis.renderer.opposite = true;
        dateAxis.cursorOverStyle = am4core.MouseCursorStyle.pointer;

        dateAxis.renderer.axisFills.template.disabled = false;
        dateAxis.renderer.axisFills.template.fill = am4core.color("#A9A9A9");
        dateAxis.renderer.axisFills.template.fillOpacity = 0.2;

        // DATE TOOLTIP
        categoryAxis.cursorTooltipEnabled = false;
        dateAxis.cursorTooltipEnabled = false;

        chart.current.cursor = new am4charts.XYCursor();
        chart.current.cursor.behavior = "none";
        chart.current.plotContainer.tooltipPosition = "pointer";
        //chart.current.plotContainer.showTooltipOn = "hit";
        chart.current.cursor.events.on("cursorpositionchanged", function (ev) {
            const xAxis = ev.target.chart.xAxes.getIndex(0);
            const date = xAxis.positionToDate(xAxis.toAxisPosition(ev.target.xPosition));
            //chart.current.plotContainer.tooltipText = format(date, t("chartDateFormat"));
            setHoverDate(format(date, t("chartDateFormat")));
        });

        // ZOOM MANAGEMENT
        dateAxis.keepSelection = true;

        dateAxis.renderer.labels.template.events.on("hit", (ev) => {
            let clickDate = ev.target.dataItem.date;

            if (isAfter(clickDate, dateAxis.max)) {
                return;
            }

            let startDate = startOfDay(clickDate);
            let endDate;

            switch (zoomLevel.current) {
                case zoomLevels.week:
                    endDate = addDays(startDate, 1);
                    zoomLevel.current = zoomLevels.day;
                    break;
                case zoomLevels.day: {
                    const hours = getHours(clickDate);
                    if (hours < 12) {
                        endDate = setHours(startDate, 12);
                        startDate = startOfDay(startDate);
                    } else {
                        endDate = addDays(startDate, 1);
                        startDate = setHours(startDate, 12);
                    }
                    zoomLevel.current = zoomLevels.halfDay;
                    break;
                }
                case zoomLevels.halfDay:
                    startDate = setHours(startDate, getHours(clickDate));
                    endDate = addHours(clickDate, 1);
                    zoomLevel.current = zoomLevels.hour;
                    break;
                case zoomLevels.hour:
                default:
                    return;
            }

            dateAxis.zoomToDates(startDate, endDate);
        });

        chart.current.zoomOutButton.background.fill = am4core.color("#FF4500");
        chart.current.zoomOutButton.background.states.getKey("hover").properties.fill = am4core.color("#ff8f66");
        chart.current.zoomOutButton.events.off("hit");
        chart.current.zoomOutButton.events.on("hit", () => {
            let startDate = new Date(dateAxis.minZoomed);
            let endDate = new Date(dateAxis.maxZoomed);

            switch (zoomLevel.current) {
                case zoomLevels.hour: {
                    const hours = getHours(startDate);
                    if (hours < 12) {
                        startDate = startOfDay(startDate);
                        endDate = setHours(endDate, 12);
                    } else {
                        startDate = setHours(startDate, 12);
                        endDate = setHours(addDays(endDate, 1), 0);
                    }
                    zoomLevel.current = zoomLevels.halfDay;
                    break;
                }
                case zoomLevels.halfDay:
                    startDate = startOfDay(startDate);
                    endDate = setHours(addDays(startDate, 1), 0);
                    zoomLevel.current = zoomLevels.day;
                    break;
                case zoomLevels.day:
                    startDate = dateAxis.min;
                    endDate = dateAxis.max;
                    zoomLevel.current = zoomLevels.week;
                    break;
                case zoomLevels.week:
                default:
                    return;
            }

            dateAxis.zoomToDates(startDate, endDate);
        });

        // RANGE LINES
        let range = dateAxis.axisRanges.create();
        range.grid.stroke = am4core.color("red");
        range.grid.strokeWidth = 2;
        range.grid.strokeOpacity = 1;

        let range2 = dateAxis.axisRanges.create();
        range2.grid.stroke = am4core.color("red");
        range2.grid.strokeWidth = 2;
        range2.grid.strokeOpacity = 1;

        let cellSize = 1.7;
        chart.current.events.on("datavalidated", (ev) => {
            let chart = ev.target;
            let categoryAxis = chart.yAxes.getIndex(0);

            let fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

            let categoryAxisRemHeight = categoryAxis.pixelHeight / fontSize;

            // Calculate how we need to adjust chart height
            let adjustHeight =
                (chart.data.length > 0 ? chart.data.length * chart.series.length : 3) * cellSize -
                categoryAxisRemHeight;

            // get current chart height
            let targetHeight = chart.pixelHeight / fontSize + adjustHeight;

            // Set it on chart's container
            chart.svgContainer.htmlElement.style.height = targetHeight + "rem";
        });

        return () => {
            chart.current.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    useLayoutEffect(() => {
        if (!chart.current) {
            return;
        }
        let dateAxis = chart.current.xAxes.getIndex(0);
        dateAxis.fillRule = (dataItem) => {
            let date = new Date(dataItem.value);
            if (exceptions.some((e) => isSameDay(e, date))) {
                dataItem.axisFill.visible = true;
                dataItem.axisFill.fill = am4core.color("red");
            } else if (date.getDate() % 2 > 0) {
                dataItem.axisFill.visible = true;
                dataItem.axisFill.fill = am4core.color("#A9A9A9");
            } else {
                dataItem.axisFill.visible = false;
            }
        };
    }, [exceptions]);

    useLayoutEffect(() => {
        if (!chart.current) {
            return;
        }
        let dateAxis = chart.current.xAxes.getIndex(0);
        dateAxis.axisRanges.getIndex(0).date = minLt;
        dateAxis.axisRanges.getIndex(1).date = maxLt;
    }, [minLt, maxLt]);

    useLayoutEffect(() => {
        if (!chart.current) {
            return;
        }
        let dateAxis = chart.current.xAxes.getIndex(0);

        while (dateAxis.axisRanges.length !== 2) {
            dateAxis.axisRanges.removeIndex(dateAxis.axisRanges.length - 1);
        }

        revisions.forEach((details) => {
            let range = dateAxis.axisRanges.create();
            range.grid.stroke = am4core.color("rgb(245, 158, 11)");
            range.grid.strokeWidth = details.length > 1 ? 2 * details.length : 3;
            range.grid.strokeOpacity = 1;
            range.date = details[0].inizioValidita;

            range.grid.tooltip = new am4core.Tooltip();
            range.grid.tooltip.pointerOrientation = "horizontal";
            range.grid.tooltipPosition = "pointer";
            range.grid.tooltipText = details
                .map(
                    (d) =>
                        `${t("code")}: [bold]${d.idOggettoRevisione}[/]\n${t("from")}: ${format(
                            d.inizioValidita,
                            t("shortDateFormat")
                        )}`
                )
                .join("\n---\n");
        });

        logisticNotes.forEach((note) => {
            let range = dateAxis.axisRanges.create();
            range.grid.stroke = am4core.color("blue");
            range.grid.strokeWidth = 3;
            range.grid.strokeOpacity = 1;
            range.date = note.data;
            range.grid.tooltip = new am4core.Tooltip();
            range.grid.tooltip.pointerOrientation = "horizontal";
            range.grid.tooltipPosition = "pointer";
            range.grid.tooltipText = note.text;
        });
    }, [revisions, logisticNotes, t]);

    useLayoutEffect(() => {
        if (!chart.current) {
            return;
        }
        let dateAxis = chart.current.xAxes.getIndex(0);
        dateAxis.min = minDate.getTime();
        dateAxis.max = maxDate.getTime();
        zoomLevel.current = zoomLevels.week;
        dateAxis.zoomToDates(dateAxis.min, dateAxis.max);
    }, [minDate, maxDate]);

    useLayoutEffect(() => {
        if (!chart.current) {
            return;
        }
        chart.current.series.clear();
        chart.current.data = [];

        const onClick = (ev) => {
            let data = ev.target.dataItem.dataContext;
            sessionStorage.setItem(SESSION_WEEK_START, format(minDate, API_DATE_FORMAT));
            history.push(`${url}/revision/${data.id}`);
        };

        const onRightClick = (ev) => {
            const data = ev.target.dataItem.dataContext;
            if (
                data.status === printStatuses.NonStampabileInRevisione.key ||
                data.status === printStatuses.NonStampabileInRevisioneForm.key
            ) {
                return;
            }
            const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
            // A causa del relative nel div esterno, bisogna sottrarre
            // alla y i pixel della barra superiore (9rem) e del padding top (1.5 rem)
            const pixelY = TOP_HEIGHT * fontSize;
            if (
                data.status !== printStatuses.Stampabile.key &&
                ![userRoles.LOGISTIC, userRoles.SUPER_LOGISTIC].includes(currentUser.role)
            )
                return;
            setContextMenu({
                show: true,
                type: contextMenuTypes.RECORD,
                x: ev.event.pageX,
                y: ev.event.pageY - pixelY + "px",
                data
            });
        };

        let categoryData = [];

        data.forEach((d) => {
            //Commentare qui e decommentare più in basso per filtrare anche i lotti
            /*categoryData.push({
                category: d.category
            });*/

            d.data.forEach((catData, index) => {
                let series = chart.current.series.getIndex(index);
                if (!series) {
                    series = chart.current.series.push(new am4charts.ColumnSeries());
                    series.showOnInit = false;
                    series.contextMenuDisabled = true;
                    //series.columns.template.width = am4core.percent(80);
                    series.tooltip.getFillFromObject = false;
                    series.tooltip.background.fill = am4core.color("rgb(55, 65, 81)");
                    series.tooltip.pointerOrientation = "vertical";
                    series.columns.template.tooltipText = `${t("code")}: [bold]{code}[/]\n${t(
                        "from"
                    )}: [bold]{openDateX}[/]\n${t("to")}: [bold]{dateX}[/]\n${t(
                        "statusChangeDate"
                    )}: [bold]{statusDateChange.formatDate('yyyy-MM-dd HH:mm:ss')}[/]\n\n${t(
                        "shortDescription"
                    )}:\n[bold]{shortDescription}[/]\n\n${t("description")}:\n[bold]{description}[/]\n\n${t(
                        "logisticNotes"
                    )}:\n[bold]{logisticNotes}[/]`;
                    series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

                    series.columns.template.adapter.add("fill", function (fill, target) {
                        if (!target.dataItem.dataContext.status) {
                            return am4core.color("#ddd");
                        }
                        return am4core.color(printStatuses[target.dataItem.dataContext.status].color);
                    });

                    series.columns.template.column.cornerRadiusTopLeft = 2;
                    series.columns.template.column.cornerRadiusBottomLeft = 2;
                    series.columns.template.column.cornerRadiusTopRight = 2;
                    series.columns.template.column.cornerRadiusBottomRight = 2;

                    series.dataFields.openDateX = "fromDate";
                    series.dataFields.dateX = "toDate";
                    series.dataFields.categoryY = "name";
                    //series.columns.template.propertyFields.fill = "color"; // get color from data
                    //series.columns.template.propertyFields.stroke = "color";
                    series.columns.template.stroke = am4core.color("black");
                    series.columns.template.strokeOpacity = 1;
                    series.columns.template.events.on("hit", onClick);

                    let labelBullet = series.bullets.push(new am4charts.LabelBullet());
                    labelBullet.contextMenuDisabled = true;
                    labelBullet.label.text = "{shortDescription}";
                    labelBullet.label.fontSize = 11;
                    labelBullet.locationY = 0.5;
                    labelBullet.label.hideOversized = true;
                    labelBullet.label.cursorOverStyle = am4core.MouseCursorStyle.pointer;

                    labelBullet.label.adapter.add("text", (text, label) => {
                        if (
                            label.dataItem.dataContext.logisticNotes &&
                            label.dataItem.dataContext.logisticNotes.trim().length > 0
                        ) {
                            return text + "  [bold mediumblue][[NL]][/]";
                        }
                        return text;
                    });

                    labelBullet.label.tooltipText = `${t("code")}: [bold]{code}[/]\n${t(
                        "from"
                    )}: [bold]{openDateX}[/]\n${t("to")}: [bold]{dateX}[/]\n${t(
                        "statusChangeDate"
                    )}: [bold]{statusDateChange.formatDate('yyyy-MM-dd HH:mm:ss')}[/]\n\n${t(
                        "shortDescription"
                    )}:\n[bold]{shortDescription}[/]\n\n${t("description")}:\n[bold]{description}[/]\n\n${t(
                        "logisticNotes"
                    )}:\n[bold]{logisticNotes}[/]`;

                    labelBullet.label.events.on("hit", onClick);

                    // LOGISTIC / SUPER_LOGISTIC RIGHT CLICK

                    series.columns.template.events.on("rightclick", onRightClick);
                    labelBullet.label.events.on("rightclick", onRightClick);

                    series.data = [];
                }
                const filteredData = catData.filter((da) => {
                    if (selectedStatus && da.status !== selectedStatus) {
                        return false;
                    }
                    if (
                        selectedCampaign &&
                        (selectedCampaign === campaigns.ALBUMINA
                            ? !da.campagne.includes(campaigns.ALBUMINA)
                            : !da.campagne.some((c) => c !== campaigns.ALBUMINA))
                    ) {
                        return false;
                    }
                    return true;
                });

                //Commentare più in alto e decommentare qui per filtrare anche i lotti
                if (filteredData.length > 0 && !categoryData.some((c) => c.category === d.category)) {
                    categoryData.push({
                        category: d.category
                    });
                }
                series.data.push(...filteredData);
            });
        });

        chart.current.data = categoryData;
    }, [currentUser.role, data, history, selectedStatus, selectedCampaign, t, url, minDate]);

    return (
        <>
            <div id="chartdiv" className="w-full mb-6" />
            {contextMenu.show && (
                <div
                    className={"z-20 absolute p-0.5 border-1 border-black rounded bg-gray-200 shadow"}
                    style={{ top: contextMenu.y, left: contextMenu.x }}
                    ref={contextMenuRef}
                    onContextMenu={(e) => {
                        e.preventDefault();
                    }}
                >
                    {contextMenu.type === contextMenuTypes.RECORD &&
                        contextMenu.data.status === printStatuses.NonStampabile.key &&
                        [userRoles.SUPER_LOGISTIC, userRoles.LOGISTIC].includes(currentUser.role) && (
                            <div
                                className={
                                    "border-black flex items-center text-xs rounded cursor-pointer hover:bg-gray-400 py-0.5 px-1"
                                }
                                onClick={() => {
                                    setContextMenu({
                                        show: false,
                                        data: null,
                                        type: null,
                                        x: 0,
                                        y: 0
                                    });
                                    onStatusChange(contextMenu.data, printStatuses.Stampabile.key);
                                }}
                            >
                                <div className="mr-0.5">{t("editStatusIn")}</div>
                                <div className={printStatuses.Stampabile.className + " ml-0.5"}>
                                    {t(printStatuses.Stampabile.key)}
                                </div>
                            </div>
                        )}
                    {contextMenu.type === contextMenuTypes.RECORD &&
                        contextMenu.data.status === printStatuses.Stampabile.key && (
                            <div
                                className={
                                    "border-black flex items-center text-xs rounded cursor-pointer hover:bg-gray-400 py-0.5 px-1"
                                }
                                onClick={() => {
                                    setContextMenu({
                                        show: false,
                                        data: null,
                                        type: contextMenuTypes.RECORD,
                                        x: 0,
                                        y: 0
                                    });
                                    onStatusChange(contextMenu.data, printStatuses.NonStampabile.key);
                                }}
                            >
                                <div className="mr-0.5">{t("editStatusIn")}</div>
                                <div className={printStatuses.NonStampabile.className + " ml-0.5"}>
                                    {t(printStatuses.NonStampabile.key)}
                                </div>
                            </div>
                        )}
                    {contextMenu.type === contextMenuTypes.RECORD &&
                        contextMenu.data.status === printStatuses.Stampato.key &&
                        [userRoles.SUPER_LOGISTIC, userRoles.LOGISTIC].includes(currentUser.role) && (
                            <>
                                <div
                                    className={
                                        "border-black flex items-center text-xs rounded cursor-pointer hover:bg-gray-400 py-0.5 px-1"
                                    }
                                    onClick={() => {
                                        setContextMenu({
                                            show: false,
                                            data: null,
                                            type: contextMenuTypes.RECORD,
                                            x: 0,
                                            y: 0
                                        });
                                        onStatusChange(contextMenu.data, printStatuses.Stampabile.key);
                                    }}
                                >
                                    <div className="mr-0.5">{t("editStatusIn")}</div>
                                    <div className={printStatuses.Stampabile.className + " ml-0.5"}>
                                        {t(printStatuses.Stampabile.key)}
                                    </div>
                                </div>
                                <div
                                    className={
                                        "border-black flex items-center text-xs rounded cursor-pointer hover:bg-gray-400 py-0.5 px-1"
                                    }
                                    onClick={() => {
                                        setContextMenu({
                                            show: false,
                                            data: null,
                                            type: contextMenuTypes.RECORD,
                                            x: 0,
                                            y: 0
                                        });
                                        onStatusChange(contextMenu.data, printStatuses.NonStampabile.key);
                                    }}
                                >
                                    <div className="mr-0.5">{t("editStatusIn")}</div>
                                    <div className={printStatuses.NonStampabile.className + " ml-0.5"}>
                                        {t(printStatuses.NonStampabile.key)}
                                    </div>
                                </div>
                            </>
                        )}
                    {contextMenu.type === contextMenuTypes.RECORD &&
                        (contextMenu.data.status === printStatuses.Stampabile.key ||
                            contextMenu.data.status === printStatuses.NonStampabile.key ||
                            contextMenu.data.status === printStatuses.InLavorazione.key) &&
                        [userRoles.SUPER_LOGISTIC, userRoles.LOGISTIC].includes(currentUser.role) && (
                            <div
                                className={
                                    "border-black flex items-center text-xs rounded cursor-pointer hover:bg-gray-400 py-0.5 px-1"
                                }
                                onClick={() => {
                                    setContextMenu({
                                        show: false,
                                        data: null,
                                        type: null,
                                        x: 0,
                                        y: 0
                                    });
                                    onStatusChange(contextMenu.data, printStatuses.Stampato.key);
                                }}
                            >
                                <div className="mr-0.5">{t("editStatusIn")}</div>
                                <div className={printStatuses.Stampato.className + " ml-0.5"}>
                                    {t(printStatuses.Stampato.key)}
                                </div>
                            </div>
                        )}
                    {contextMenu.type === contextMenuTypes.RECORD && currentUser.role === userRoles.SUPER_LOGISTIC && (
                        <div
                            className={
                                "border-black flex items-center text-xs rounded cursor-pointer hover:bg-gray-400 py-0.5 px-1 text-red-600"
                            }
                            onClick={() => {
                                onDeleteRecord(contextMenu.data);
                                setContextMenu({
                                    show: false,
                                    data: null,
                                    type: contextMenuTypes.RECORD,
                                    x: 0,
                                    y: 0
                                });
                            }}
                        >
                            {t("deleteRecord")}
                        </div>
                    )}
                    {contextMenu.type === contextMenuTypes.LOT && currentUser.role === userRoles.SUPER_LOGISTIC && (
                        <>
                            <div
                                className={
                                    "border-black flex items-center text-xs rounded cursor-pointer hover:bg-gray-400 py-0.5 px-1"
                                }
                                onClick={() => {
                                    setContextMenu({
                                        show: false,
                                        data: null,
                                        type: contextMenuTypes.LOT,
                                        x: 0,
                                        y: 0
                                    });
                                    onLotShift(contextMenu.data);
                                }}
                            >
                                {t("lotShift")}
                            </div>
                            <div
                                className={
                                    "border-black flex items-center text-xs rounded cursor-pointer hover:bg-gray-400 py-0.5 px-1"
                                }
                                onClick={() => {
                                    setContextMenu({
                                        show: false,
                                        data: null,
                                        type: contextMenuTypes.LOT,
                                        x: 0,
                                        y: 0
                                    });
                                    onLotReset(contextMenu.data);
                                }}
                            >
                                {t("resetLot")}
                            </div>
                        </>
                    )}
                </div>
            )}
            <div className="fixed bottom-2 right-2 rounded-md bg-blue-300 bg-opacity-30 border-gray-500 border-1 p-0.5 text-sm font-bold">
                {hoverDate}
            </div>
        </>
    );
};

export default Chart;
