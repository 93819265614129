import { useMemo } from "react";

const RegistryTab = ({ tabsObjs, currentLink, disabled, onChange = () => {} }) => {
    const tabs = useMemo(
        () =>
            tabsObjs.map((tabObj, index) => (
                <div
                    key={index}
                    className={
                        "relative h-full flex items-center justify-center p-3 rounded-t-md shadow " +
                        ((currentLink === tabObj.link
                            ? "text-red-600"
                            : "text-gray-500 hover:bg-gray-300 hover:bg-opacity-60") +
                            (disabled ? " cursor-not-allowed bg-gray-200" : " cursor-pointer"))
                    }
                    onClick={() => {
                        if (!disabled) onChange(tabObj.link);
                    }}
                >
                    <div className={"font-bold text-2xl"}>{tabObj.title}</div>
                    <div
                        className={
                            "absolute bottom-0 rignt-0 left-0 w-full rounded-t-md " +
                            (currentLink === tabObj.link ? "h-0.200rem bg-red-600" : "h-0.5 bg-gray-500")
                        }
                    />
                </div>
            )),
        [tabsObjs, currentLink, disabled, onChange]
    );

    return (
        <>
            <div className={"flex flex-row flex-nowrap items-end justify-center mb-4 shadow rounded-t-md"}>{tabs}</div>
        </>
    );
};

export default RegistryTab;
