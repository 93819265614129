import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as EditIcon } from "../../svgIcons/edit-regular.svg";
import { ReactComponent as CheckIcon } from "../../svgIcons/check-solid.svg";
import { ReactComponent as TimesIcon } from "../../svgIcons/times-solid.svg";
import { ReactComponent as DeleteIcon } from "../../svgIcons/trash-alt-solid.svg";
import { ReactComponent as PlusIcon } from "../../svgIcons/plus-solid.svg";
import Input from "../../components/input";
import Select from "../../components/select";
import { userRoles } from "../../utils/constants";
import Popup from "../../components/popup";
import { CommonButton } from "../../components/commonButton";
import { DELETE_USER, USERS } from "../../utils/requests";

const rolesArray = Object.freeze(Object.values(userRoles));

const Users = ({ users, mutateUsers, request }) => {
    const { t } = useTranslation();

    const [attentionPopup, setAttentioPopup] = useState({
        show: false,
        userId: null
    });

    return (
        <>
            <div className="mt-8 w-full">
                <div className="flex items-center mb-2">
                    <div className="text-2xl font-semibold mr-2">{t("users")}</div>
                    <div
                        className="border-1 shadow bg-green-700 hover:bg-green-600 p-1.5 cursor-pointer rounded-md"
                        onClick={() => {
                            mutateUsers((old) => [
                                {
                                    id: Math.random().toString(36).substr(2, 9),
                                    firstName: null,
                                    lastName: null,
                                    userName: null,
                                    email: null,
                                    role: null,
                                    isNew: true
                                },
                                ...old
                            ]);
                        }}
                    >
                        <PlusIcon className="w-4 h-4 text-white" />
                    </div>
                </div>
                <table className="w-full rounded-t-md">
                    <thead className="text-xl text-center">
                        <tr className="border-b-2">
                            <th className="p-1 border-2">{t("firstName")}</th>
                            <th className="p-1 border-2">{t("lastName")}</th>
                            <th className="p-1 border-2">{t("username")}</th>
                            <th className="p-1 border-2">{t("email")}</th>
                            <th className="p-1 border-2">{t("role")}</th>
                            <th className="p-1 border-2">{t("edit")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <Row
                                key={user.id}
                                user={user}
                                onSave={async (userEdit) => {
                                    const response = await request(USERS, {
                                        method: userEdit.isNew ? "POST" : "PUT",
                                        body: JSON.stringify(userEdit),
                                        ignoreResponse: !userEdit.isNew
                                    });

                                    if (response.ok) {
                                        mutateUsers((old) => {
                                            let index = old.findIndex((u) => u.id === userEdit.id);
                                            old[index] = {
                                                ...userEdit,
                                                id: userEdit.isNew ? response.data.id : userEdit.id,
                                                isNew: false
                                            };
                                            old.sort((userA, userB) => {
                                                const a = userA.firstName + " " + userA.lastName;
                                                const b = userB.firstName + " " + userB.lastName;

                                                if (userB.isNew || a > b) {
                                                    return 1;
                                                }
                                                if (userA.isNew || a < b) {
                                                    return -1;
                                                }
                                                return 0;
                                            });
                                            return [...old];
                                        });
                                        return true;
                                    }

                                    if (response.status === 409) {
                                        alert(t("existingUsernameOrEmail"));
                                    } else {
                                        alert(t(response.errorMessage));
                                    }

                                    return false;
                                }}
                                onDelete={async () => {
                                    if (!user.isNew) {
                                        setAttentioPopup({
                                            show: true,
                                            userId: user.id
                                        });
                                        return;
                                    }
                                    mutateUsers((old) => [...old.filter((u) => u.id !== user.id)]);
                                }}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            {attentionPopup.show && (
                <Popup>
                    <div className="font-bold text-red-600 text-xl mb-1">{t("irreversibleAction")}</div>
                    <div className="flex items-center justify-between w-full mt-3">
                        <div className="w-32">
                            <CommonButton
                                onClick={() => {
                                    setAttentioPopup({
                                        show: false,
                                        userId: null
                                    });
                                }}
                                className="text-red-600 border-red-600"
                            >
                                {t("back")}
                            </CommonButton>
                        </div>
                        <div className="w-32">
                            <CommonButton
                                className="text-green-600 border-green-600"
                                onClick={async () => {
                                    const user = users.find((u) => u.id === attentionPopup.userId);
                                    setAttentioPopup({
                                        show: false,
                                        userId: null
                                    });

                                    request(DELETE_USER, {
                                        method: "DELETE",
                                        params: {
                                            id: user.id
                                        },
                                        ignoreResponse: true
                                    }).then((response) => {
                                        if (response.ok) {
                                            return;
                                        }

                                        alert(t(response.errorMessage));

                                        mutateUsers((old) => {
                                            const newValue = [...old, user];
                                            newValue.sort((userA, userB) => {
                                                const a = userA.firstName + " " + userA.lastName;
                                                const b = userB.firstName + " " + userB.lastName;

                                                if (userB.isNew || a > b) {
                                                    return 1;
                                                }
                                                if (userA.isNew || a < b) {
                                                    return -1;
                                                }
                                                return 0;
                                            });
                                            return newValue;
                                        });
                                    });

                                    mutateUsers((old) => [...old.filter((u) => u.id !== user.id)]);
                                }}
                            >
                                {t("confirm")}
                            </CommonButton>
                        </div>
                    </div>
                </Popup>
            )}
        </>
    );
};

const Row = ({ user, onSave = async () => {}, onDelete = () => {} }) => {
    const { t } = useTranslation();
    const [userEdit, setUserEdit] = useState(user.isNew ? user : null);

    const canSave = useMemo(() => {
        if (!userEdit) {
            return false;
        }
        return (
            userEdit.firstName &&
            userEdit.firstName.trim().length > 0 &&
            userEdit.lastName &&
            userEdit.lastName.trim().length > 0 &&
            userEdit.userName &&
            userEdit.userName.trim().length > 0 &&
            userEdit.email &&
            userEdit.email.trim().length > 0 &&
            userEdit.role &&
            userEdit.role.trim().length > 0
        );
    }, [userEdit]);

    if (!userEdit) {
        return (
            <tr>
                <td className="p-2 border-1">{user.firstName}</td>
                <td className="p-2 border-1">{user.lastName}</td>
                <td className="p-2 border-1">{user.userName}</td>
                <td className="p-2 border-1">{user.email}</td>
                <td className="p-2 border-1">{t(user.role)}</td>
                <td className="p-2 border-1">
                    <div className="flex items-center justify-center w-full h-full">
                        <div
                            className="border-1 shadow bg-gray-300 hover:bg-gray-200 p-1.5 mx-0.5 cursor-pointer rounded-md"
                            onClick={() => {
                                setUserEdit(user);
                            }}
                        >
                            <EditIcon className="w-5 h-5 text-gray-700" />
                        </div>
                        <div
                            className="border-1 shadow bg-red-800 hover:bg-red-700 p-1.5 mx-0.5 cursor-pointer rounded-md"
                            onClick={() => {
                                onDelete();
                            }}
                        >
                            <DeleteIcon className="w-5 h-5 text-white" />
                        </div>
                    </div>
                </td>
            </tr>
        );
    }

    return (
        <tr>
            <td className="p-2 border-1">
                <Input
                    placeholder={t("firstName")}
                    value={userEdit.firstName}
                    onChange={(value) => {
                        setUserEdit((old) => ({
                            ...old,
                            firstName: value
                        }));
                    }}
                />
            </td>
            <td className="p-2 border-1">
                <Input
                    placeholder={t("lastName")}
                    value={userEdit.lastName}
                    onChange={(value) => {
                        setUserEdit((old) => ({
                            ...old,
                            lastName: value
                        }));
                    }}
                />
            </td>
            <td className="p-2 border-1">
                <Input
                    placeholder={t("username")}
                    value={userEdit.userName}
                    onChange={(value) => {
                        setUserEdit((old) => ({
                            ...old,
                            userName: value
                        }));
                    }}
                />
            </td>
            <td className="p-2 border-1">
                <Input
                    placeholder={t("email")}
                    value={userEdit.email}
                    onChange={(value) => {
                        setUserEdit((old) => ({
                            ...old,
                            email: value
                        }));
                    }}
                />
            </td>
            <td className="p-2 border-1">
                <Select
                    data={rolesArray}
                    placeholder={t("selectPlaceholder")}
                    value={userEdit.role}
                    render={(role) => t(role)}
                    onChange={(value) => {
                        setUserEdit((old) => ({
                            ...old,
                            role: value
                        }));
                    }}
                />
            </td>
            <td className="p-2 border-1">
                <div className="flex items-center justify-center w-full h-full">
                    <div
                        className={
                            "border-1 p-1.5 mx-0.5 rounded-md " +
                            (canSave ? "bg-green-600 hover:bg-green-500 shadow cursor-pointer" : "bg-gray-300")
                        }
                        onClick={async () => {
                            if (!canSave) {
                                return;
                            }
                            const ok = await onSave(userEdit);
                            if (ok) {
                                setUserEdit(null);
                            }
                        }}
                    >
                        <CheckIcon className="w-5 h-5 text-white" />
                    </div>
                    <div
                        className="border-1 shadow bg-red-600 hover:bg-red-500 p-1.5 mx-0.5 cursor-pointer rounded-md"
                        onClick={() => {
                            if (user.isNew) {
                                onDelete();
                            } else {
                                setUserEdit(null);
                            }
                        }}
                    >
                        <TimesIcon className="w-5 h-5 text-white" />
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default Users;
