import { useTranslation } from "react-i18next";
import {
    APPLY_PROGRAMMA_STAMPA,
    EXPORT_PROGRAMMA_STAMPA_TODAY,
    PROGRAMMA_STAMPA_LAVORAZIONE
} from "../../utils/requests";
import { format, isSameDay, parseISO } from "date-fns";
import { useRequest } from "../../utils/requestManager";
import Spinner from "../../components/spinner";
import NavigationMenu from "../../components/navigationMenu";
import { Fragment, useContext, useEffect, useState } from "react";
import Popup from "../../components/popup";
import { CommonButton } from "../../components/commonButton";
import { AppContext } from "../../utils/contexts";
import { topBarType } from "../../utils/constants";
import { printStatuses } from "../schedulingBoard/chart/schedulingBoard";
import { ReactComponent as DownloadIcon } from "../../svgIcons/file-download-solid.svg";

const ProgrammaStampaLogistic = () => {
    const { setTopBarOptions } = useContext(AppContext);
    const { t } = useTranslation();

    useEffect(() => {
        setTopBarOptions({
            pageTitle: t("printProgram"),
            barType: topBarType.TITLE
        });
    }, [setTopBarOptions, t]);

    const { data, isLoading, request, reload } = useRequest(PROGRAMMA_STAMPA_LAVORAZIONE, {
        transformData: (data) =>
            data.map((r) => ({
                ...r,
                dataLavorazione: parseISO(r.dataLavorazione)
            }))
    });

    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    return (
        <>
            <div className="w-11/12 flex mb-12">
                {isLoading && (
                    <div className="h-40 flex items-center justify-center w-full">
                        <Spinner />
                    </div>
                )}
                {!isLoading && (
                    <div className="w-full">
                        <div className="flex items-center mb-2">
                            <div className="text-2xl font-semibold mr-2">{t("printProgram")}</div>
                            <div
                                className="border-1 shadow bg-gray-300 hover:bg-gray-200 p-1.5 cursor-pointer rounded-md mr-2"
                                onClick={async () => {
                                    const response = await request(EXPORT_PROGRAMMA_STAMPA_TODAY, {
                                        ignoreResponse: true
                                    });

                                    const file = await response.response.blob();
                                    const url = URL.createObjectURL(file);
                                    window.open(url, "_blank");
                                    URL.revokeObjectURL(url);
                                }}
                            >
                                <DownloadIcon className="w-5 h-5 text-gray-700" />
                            </div>
                            <div
                                className="border-1 shadow bg-blue-700 hover:bg-blue-600 p-1.5 cursor-pointer rounded-md text-white font-bold"
                                onClick={() => {
                                    setShowConfirmPopup(true);
                                }}
                            >
                                {t("recalculate")}
                            </div>
                        </div>
                        <table className="w-full rounded-t-md text-sm text-center">
                            <thead className="text-xl">
                                <tr className="border-b-2">
                                    <th className="p-1 border-2">{t("processingDate")}</th>
                                    <th className="p-1 border-2">{t("batchRecord")}</th>
                                    <th className="p-1 border-2">{t("shortDescription")}</th>
                                    <th className="p-1 border-2">{t("link")}</th>
                                    <th className="p-1 border-2">{t("lot")}</th>
                                    <th className="p-1 border-2">{t("item")}</th>
                                    <th className="p-1 border-2">{t("printStatus")}</th>
                                    <th className="p-1 border-2">{t("revisionObject")}</th>
                                    <th className="p-1 border-2">{t("notes")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.flatMap((d, di, dArr) => {
                                    const recordsCount = d.batchRecords.flatMap((br) => br.records).length;
                                    return d.batchRecords.flatMap((br, bri, brArr) =>
                                        br.records.map((r, i, rArr) => (
                                            <tr
                                                key={r.id}
                                                className={
                                                    bri === brArr.length - 1 &&
                                                    i === rArr.length - 1 &&
                                                    di !== dArr.length - 1
                                                        ? "border-b-2 border-blue-600"
                                                        : ""
                                                }
                                            >
                                                {bri === 0 && i === 0 && (
                                                    <td
                                                        className={
                                                            "p-2 border-1 text-lg font-bold" +
                                                            (isSameDay(d.dataLavorazione, new Date())
                                                                ? " bg-blue-600 text-white"
                                                                : "")
                                                        }
                                                        rowSpan={recordsCount}
                                                    >
                                                        {format(d.dataLavorazione, t("shortDateFormat"))}
                                                    </td>
                                                )}
                                                {i === 0 && (
                                                    <>
                                                        <td
                                                            rowSpan={br.records.length}
                                                            className="p-2 border-1 underline text-green-500 text-lg whitespace-nowrap"
                                                        >
                                                            {br.batchRecord}
                                                        </td>
                                                        <td rowSpan={br.records.length} className="p-2 border-1">
                                                            {br.descrizioneBreve}
                                                        </td>
                                                        <td
                                                            rowSpan={br.records.length}
                                                            className="p-2 border-1 underline text-blue-600"
                                                        >
                                                            <a href={br.link} target="_blank" rel="noreferrer">
                                                                {br.link}
                                                            </a>
                                                        </td>
                                                    </>
                                                )}
                                                <td className="p-2 border-1">{r.lotto + " (" + r.anno + ")"}</td>
                                                <td className="p-2 border-1">
                                                    {r.item?.trim().length > 0 ? r.item : "--"}
                                                </td>
                                                <td className={"p-2 border-1 " + printStatuses[r.stato].className}>
                                                    {t(r.stato)}
                                                </td>
                                                <td className="p-2 border-1">
                                                    {r.oggettoRevisione?.trim().length > 0 ? r.oggettoRevisione : "--"}
                                                </td>
                                                <td className="p-2 border-1">
                                                    {r.noteLogistica?.trim().length > 0 ? r.noteLogistica : "--"}
                                                </td>
                                            </tr>
                                        ))
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
                <NavigationMenu />
            </div>
            {showConfirmPopup && (
                <Popup>
                    {isSaving && <Spinner />}
                    {!isSaving && (
                        <div className="w-full">
                            <div className="font-bold text-red-600 text-xl mb-1">{t("irreversibleAction")}</div>
                            <div className="w-full flex items-center justify-between mt-5">
                                <div className="w-28 mr-1">
                                    <CommonButton
                                        className="text-gray-600 p-2"
                                        onClick={() => {
                                            setShowConfirmPopup(false);
                                        }}
                                    >
                                        {t("back")}
                                    </CommonButton>
                                </div>
                                <div className="w-28 ml-1">
                                    <CommonButton
                                        className="text-green-600 border-green-600 p-2"
                                        onClick={async () => {
                                            setIsSaving(true);

                                            const response = await request(APPLY_PROGRAMMA_STAMPA, {
                                                method: "GET",
                                                ignoreResponse: true
                                            });

                                            if (!response.ok) {
                                                if (response.status !== 401) {
                                                    setIsSaving(false);
                                                }
                                                alert(t(response.errorMessage));
                                                return;
                                            }

                                            reload();

                                            setIsSaving(false);
                                            setShowConfirmPopup(false);
                                        }}
                                    >
                                        {t("save")}
                                    </CommonButton>
                                </div>
                            </div>
                        </div>
                    )}
                </Popup>
            )}
        </>
    );
};

export default ProgrammaStampaLogistic;
