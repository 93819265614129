import Login from "../pages/login";
import { useContext, useEffect, useState } from "react";
import { CURRENT_USER } from "../utils/requests";
import { useRequest } from "../utils/requestManager";
import { AppContext, UserContext } from "../utils/contexts";
import TopBar from "./topBar";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "../pages/home";
import DataRequest from "../pages/dataRequest/dataRequest_v2";
import Anagrafica from "../pages/anagrafica/anagrafica";
import Spinner from "./spinner";
import SchedulingBoardNavigator from "../pages/schedulingBoard/schedulingBoardNavigator";
import PasswordChange from "../pages/password/passwordChange";
import PasswordForgot from "../pages/password/passwordForgot";
import Settings from "../pages/settings/settings";
import { userRoles } from "../utils/constants";
import GmpDocRevisions from "../pages/gmpDocRevisions/gmpDocRevisions";
import ProgrammaStampaGmpDoc from "../pages/programmaStampa/programmaStampaGmpDoc";
import ProgrammaStampaLogistic from "../pages/programmaStampa/programmaStampaLogistic";

const Layout = () => {
    const { token } = useContext(AppContext);
    const { data: currentUser, error, mutate: mutateCurrentUser, isLoading } = useRequest(token ? CURRENT_USER : null);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (!token && currentUser) {
            mutateCurrentUser(null);
        }
    }, [currentUser, mutateCurrentUser, token]);

    return (
        <UserContext.Provider value={{ currentUser }}>
            <TopBar />
            <div className="flex flex-col justify-center w-full items-center pt-6 relative">
                {(showLoader || isLoading) && <Spinner />}
                {!showLoader && !isLoading && (!token || (error && error.status === 401)) && (
                    <BrowserRouter>
                        <Switch>
                            <Route exact path="/password/forgot">
                                <PasswordForgot setShowLoader={setShowLoader} />
                            </Route>
                            <Route path="/password/:guid">
                                <PasswordChange setShowLoader={setShowLoader} />
                            </Route>
                            <Route path="/">
                                <Login setShowLoader={setShowLoader} />
                            </Route>
                        </Switch>
                    </BrowserRouter>
                )}
                {!showLoader && token && currentUser && currentUser.role !== userRoles.ADMIN && (
                    <BrowserRouter>
                        <Switch>
                            {currentUser.role === userRoles.GMP_DOC && (
                                <Route path="/anagrafica">
                                    <Anagrafica />
                                </Route>
                            )}
                            <Route path="/schedulingBoard">
                                <SchedulingBoardNavigator />
                            </Route>
                            <Route path="/dataRequest">
                                <DataRequest />
                            </Route>
                            {currentUser.role === userRoles.GMP_DOC && (
                                <Route path="/gmpDocRevisions">
                                    <GmpDocRevisions />
                                </Route>
                            )}
                            {currentUser.role === userRoles.GMP_DOC && (
                                <Route path="/programmaStampa">
                                    <ProgrammaStampaGmpDoc />
                                </Route>
                            )}
                            {currentUser.role === userRoles.GMP_DOC && (
                                <Route path="/settings">
                                    <Settings />
                                </Route>
                            )}
                            {[userRoles.LOGISTIC, userRoles.SUPER_LOGISTIC].includes(currentUser.role) && (
                                <Route path="/programmaStampa">
                                    <ProgrammaStampaLogistic />
                                </Route>
                            )}
                            <Route path="/">
                                <Home />
                            </Route>
                        </Switch>
                    </BrowserRouter>
                )}
                {!showLoader && token && currentUser && currentUser.role === userRoles.ADMIN && (
                    <BrowserRouter>
                        <Settings />
                    </BrowserRouter>
                )}
            </div>
        </UserContext.Provider>
    );
};

export default Layout;
