import { useContext, useEffect, useState } from "react";
import { ReactComponent as Logo } from "../svgIcons/takeda_logo.svg";
import { topBarType } from "../utils/constants";
import { AppContext, UserContext } from "../utils/contexts";
import { ReactComponent as UserIcon } from "../svgIcons/user-solid.svg";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

const TopBar = () => {
    const { barType, pageTitle } = useContext(AppContext);
    const { currentUser } = useContext(UserContext);
    const { t } = useTranslation();

    const [nowDate, setNowDate] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setNowDate(new Date());
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="flex justify-center mb-4">
            <div className="w-11/12 max-h-36">
                <div className="flex items-center justify-start">
                    <div className="flex items-center">
                        <Logo className="w-36 h-36" />
                        <div className="font-bold whitespace-nowrap">Better Health, Brighter Future</div>
                    </div>
                    {barType === topBarType.TITLE && (
                        <div className="w-full ml-6 mr-6 flex items-center">
                            <VerticalLine />
                            <div className="w-full text-center font-medium text-5xl text-red-600">{pageTitle}</div>
                            <VerticalLine />
                        </div>
                    )}
                    {barType === topBarType.APP_NAME && (
                        <div className="w-full ml-6 mr-6 flex items-center">
                            <div className="text-center whitespace-nowrap font-extrabold text-9xl italic text-red-600 w-full">
                                S-Print
                            </div>
                            {currentUser && <VerticalLine />}
                        </div>
                    )}
                    {!currentUser && <div className="w-2/5"></div>}
                    {currentUser && (
                        <div className="w-2/6 h-36 flex flex-col pt-5 pb-5 whitespace-nowrap">
                            <div className="text-red-600 underline text-2xl mb-2">Welcome</div>
                            <div className="flex items-center mb-2">
                                <UserIcon className="w-10 h-10 mr-3 text-gray-700" />
                                <div>
                                    <div className="font-medium text-lg">
                                        {currentUser.firstName + " " + currentUser.lastName}
                                    </div>
                                    <div className="text-gray-700">{t(currentUser.role)}</div>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="w-10 mr-3" />
                                <div className="font-light text-sm text-gray-600">
                                    {format(nowDate, "dd MMM yyyy - HH:mm")}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="w-full h-2.5 bg-red-600 shadow-md"></div>
            </div>
        </div>
    );
};

const VerticalLine = () => {
    return <div className="h-32 w-2.5 shadow-md bg-red-600"></div>;
};

export default TopBar;
