import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import Spinner from "../../../components/spinner";
import { SESSION_WEEK_START, campaigns, topBarType, userRoles } from "../../../utils/constants";
import { AppContext, UserContext } from "../../../utils/contexts";
import { useRequest } from "../../../utils/requestManager";
import {
    BATCH_RECORDS,
    INCOMPLETE_FORM_NOTIFICATION,
    RECORD_PIANIFICATO,
    SAVE_LOGISTIC_NOTES
} from "../../../utils/requests";
import Overlay from "../../dataRequest/overlay";
import { printStatuses } from "../chart/schedulingBoard";
import RecordInfo from "../common/recordInfo";
import GmpDocForm from "./forms/gmpDocForm";
import LogisticForm from "./forms/logisticForm";
import QcQaPdtsForm from "./forms/qcQaPdtsForm";
import SupplyChainForm from "./forms/supplyChainForm";

export const revisionStatuses = Object.freeze({ APPLICATO: "Applicato", NON_APPLICATO: "NonApplicato" });

export const revisionFields = Object.freeze({
    [userRoles.QA]: "revisioneQa",
    [userRoles.QC]: "revisioneQc",
    [userRoles.PDTS]: "revisionePdts",
    [userRoles.SUPPLY_CHAIN]: "revisioneSupplyChain",
    [userRoles.GMP_DOC]: "revisioneGmpDoc"
});

const Revision = () => {
    const { setTopBarOptions } = useContext(AppContext);
    const { t } = useTranslation();

    useEffect(() => {
        setTopBarOptions({
            pageTitle: t("lotInputBoard"),
            barType: topBarType.TITLE
        });
    }, [setTopBarOptions, t]);

    const { recordId } = useParams();
    const { currentUser } = useContext(UserContext);
    const history = useHistory();

    const apiParams = useMemo(() => ({ id: recordId }), [recordId]);

    const { data: record, isLoading, mutate, request } = useRequest(RECORD_PIANIFICATO, { params: apiParams });

    const { data: batchRecordIds, isLoading: isLoadingBatchRecords } = useRequest(BATCH_RECORDS, {
        transformData: (data) => {
            const result = data.map((r) => r.id);
            return result;
        }
    });

    const [isSaving, setIsSaving] = useState(false);

    const description = useMemo(
        () => (record ? record.descrizione + " (" + t("version") + " " + record.versione + ")" : null),
        [record, t]
    );

    const onSave = useCallback(
        async (url, data) => {
            setIsSaving(true);
            const response = await request(url, {
                method: "PUT",
                body: JSON.stringify(
                    Object.entries(data).reduce(
                        (obj, [key, value]) => ({
                            ...obj,
                            [key]: value !== "" ? value : null
                        }),
                        {}
                    )
                ),
                ignoreResponse: true
            });

            if (!response.ok) {
                if (response.status !== 401) {
                    setIsSaving(false);
                }
                alert(t(response.errorMessage));
                return;
            }

            setIsSaving(false);
            history.push(`/schedulingBoard?weekStart=${sessionStorage.getItem(SESSION_WEEK_START)}`);
        },
        [history, request, t]
    );

    const logisticNotesTimeout = useRef(null);

    return (
        <>
            <div className="flex justify-center w-11/12">
                {isLoading && isLoadingBatchRecords && (
                    <div className="h-40">
                        <Spinner />
                    </div>
                )}
                {!isLoading && !isLoadingBatchRecords && record && (
                    <>
                        <div className="w-full">
                            {[userRoles.QA, userRoles.QC, userRoles.PDTS].includes(currentUser.role) && (
                                <QcQaPdtsForm
                                    recordId={record.id}
                                    revision={
                                        record[revisionFields[currentUser.role]] || {
                                            formConsegato: null,
                                            eccezioneId: null,
                                            nota: null,
                                            eccezione: null
                                        }
                                    }
                                    description={description}
                                    noteLogistica={record.noteLogistica}
                                    mutateRevision={(mutateFunction) => {
                                        mutate((old) => ({
                                            ...old,
                                            [revisionFields[currentUser.role]]: mutateFunction(
                                                old[revisionFields[currentUser.role]]
                                            )
                                        }));
                                    }}
                                    onSave={onSave}
                                    editable={record.status !== printStatuses.Stampato.key}
                                />
                            )}
                            {currentUser.role === userRoles.GMP_DOC && (
                                <GmpDocForm
                                    recordId={record.id}
                                    batchRecord={record.batchRecord}
                                    mutateRecord={mutate}
                                    revision={
                                        record[revisionFields[currentUser.role]] || {
                                            eccezioneId: null,
                                            nota: null,
                                            eccezione: null
                                        }
                                    }
                                    description={description}
                                    noteLogistica={record.noteLogistica}
                                    mutateRevision={(mutateFunction) => {
                                        mutate((old) => ({
                                            ...old,
                                            [revisionFields[currentUser.role]]: mutateFunction(
                                                old[revisionFields[currentUser.role]]
                                            )
                                        }));
                                    }}
                                    availableBatchRecords={batchRecordIds}
                                    onSave={onSave}
                                    editable={record.status !== printStatuses.Stampato.key}
                                />
                            )}
                            {currentUser.role === userRoles.SUPPLY_CHAIN && (
                                <SupplyChainForm
                                    recordId={record.id}
                                    isAlbumina={record.campagna === campaigns.ALBUMINA}
                                    lot={record.lotto}
                                    revision={
                                        record[revisionFields[currentUser.role]] || {
                                            workOrder: null,
                                            tipoCampagna: null,
                                            status: null,
                                            plasmaProvenienza: null,
                                            nota: null,
                                            lotSerial: null,
                                            item: null,
                                            eccezione: null,
                                            eccezioneId: null
                                        }
                                    }
                                    description={description}
                                    noteLogistica={record.noteLogistica}
                                    mutateRevision={(mutateFunction) => {
                                        mutate((old) => ({
                                            ...old,
                                            [revisionFields[currentUser.role]]: mutateFunction(
                                                old[revisionFields[currentUser.role]]
                                            )
                                        }));
                                    }}
                                    onSave={onSave}
                                    editable={record.status !== printStatuses.Stampato.key}
                                />
                            )}
                            {[userRoles.LOGISTIC, userRoles.SUPER_LOGISTIC].includes(currentUser.role) && (
                                <LogisticForm
                                    record={record}
                                    editNoteLogistica={(note) => {
                                        if (logisticNotesTimeout.current) {
                                            clearTimeout(logisticNotesTimeout.current);
                                        }
                                        mutate((old) => ({
                                            ...old,
                                            noteLogistica: note
                                        }));
                                        logisticNotesTimeout.current = setTimeout(async () => {
                                            const response = await request(SAVE_LOGISTIC_NOTES, {
                                                method: "PUT",
                                                ignoreResponse: true,
                                                body: JSON.stringify(note),
                                                params: {
                                                    id: recordId
                                                }
                                            });

                                            if (!response.ok) {
                                                alert(t(response.errorMessage));
                                            }
                                        }, 500);
                                    }}
                                    onNotification={async (role) => {
                                        setIsSaving(true);

                                        const response = await request(INCOMPLETE_FORM_NOTIFICATION, {
                                            method: "PUT",
                                            ignoreResponse: true,
                                            params: {
                                                id: recordId,
                                                ruolo: role
                                            }
                                        });

                                        if (!response.ok) {
                                            if (response.status !== 401) {
                                                setIsSaving(false);
                                            }
                                            alert(t(response.errorMessage));
                                            return;
                                        }

                                        alert(t("sentNotification"));
                                        setIsSaving(false);
                                    }}
                                />
                            )}
                        </div>
                        <RecordInfo
                            record={record}
                            previousPage={`/schedulingBoard?weekStart=${sessionStorage.getItem(SESSION_WEEK_START)}`}
                        />
                    </>
                )}
            </div>
            {isSaving && (
                <Overlay>
                    <Spinner />
                </Overlay>
            )}
        </>
    );
};

export default Revision;
