import { useState, useMemo, useCallback, useRef, useEffect } from "react";
import { ReactComponent as ChevronDown } from "../svgIcons/chevron-down-solid.svg";
import { ReactComponent as CheckedIcon } from "../svgIcons/check-circle-solid.svg";
import { ReactComponent as DeleteIcon } from "../svgIcons/times-solid.svg";
import { ReactComponent as SpinnerIcon } from "../svgIcons/spinner-solid.svg";
import { ReactComponent as PlusIcon } from "../svgIcons/plus-solid.svg";
import { useTranslation } from "react-i18next";

const Select = ({
    value = null,
    onChange = () => {},
    title,
    placeholder,
    data = [],
    keyField,
    render = (obj) => obj,
    filter = null,
    isLoading = false,
    disabled = false,
    allowAdditions = false,
    canAddNew = (value) => !data.includes(value),
    onAdd = () => {},
    allowDeletion = false,
    onDelete = () => {}
}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const popupRef = useRef(null);
    const [search, setSearch] = useState("");

    const closePopup = useCallback(() => {
        if (window.innerWidth < 800) {
            if (popupRef.current) {
                const bounding = popupRef.current.getBoundingClientRect();
                if (bounding.top <= 0 || bounding.top >= window.innerHeight) {
                    setOpen(false);
                    setSearch("");
                }
            }
        } else if (open) {
            setOpen(false);
            setSearch("");
        }
    }, [open]);

    const closePopupOnClick = useCallback(
        (e) => {
            if (popupRef.current && open && !popupRef.current.contains(e.target)) {
                setOpen(false);
                setSearch("");
            }
        },
        [open]
    );

    useEffect(() => {
        window.addEventListener("scroll", closePopup);
        window.addEventListener("click", closePopupOnClick);

        return () => {
            window.removeEventListener("scroll", closePopup);
            window.removeEventListener("click", closePopupOnClick);
        };
    }, [closePopup, closePopupOnClick]);

    useEffect(() => {
        if (disabled && open) {
            setOpen(false);
        }
    }, [open, disabled]);

    const renderedData = useMemo(() => {
        let result = data;
        if (filter) {
            result = result.filter((d) => filter(d, search));
        }
        return result.map((obj) => {
            const key = keyField ? obj[keyField] : obj;
            return (
                <div
                    className={
                        "px-2 py-2 text-sm cursor-pointer rounded-md flex justify-between items-center overflow-x-hidden overflow-ellipsis select-none hover:bg-gray-300 text-gray-800"
                    }
                    key={key}
                    onClick={() => {
                        setOpen(false);
                        if (key !== value) {
                            onChange(key);
                        } else {
                            onChange(null);
                        }
                    }}
                >
                    {render(obj)}
                    {allowDeletion && value !== key && (
                        <div>
                            <DeleteIcon
                                className={"h-4 w-4 cursor-pointer"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDelete(key);
                                }}
                            />
                        </div>
                    )}
                    <div className={value !== key ? "hidden" : ""}>
                        <CheckedIcon className={"h-4 w-4"} />
                    </div>
                </div>
            );
        });
    }, [data, filter, search, keyField, render, allowDeletion, value, onChange, onDelete]);

    const inputRef = useRef(null);

    useEffect(() => {
        if (open && inputRef.current) {
            inputRef.current.focus();
        }
    }, [open]);

    const renderedValue = useMemo(() => {
        const obj = data.find((obj) => (keyField ? obj[keyField] : obj) === value);
        if (!obj) {
            return null;
        }
        return render(obj);
    }, [data, render, keyField, value]);

    const selectRef = useRef(null);

    const popupPositionClassNames = useMemo(() => {
        if (open) {
            const bounding = selectRef.current.getBoundingClientRect();
            const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

            const remWindowHeight = window.innerHeight / fontSize;
            const remTop = bounding.top / fontSize;

            if (remWindowHeight - remTop < 16) {
                return "mb-1 bottom-full";
            }
        }

        return "mt-1 top-full";
    }, [open]);

    return (
        <div className={"relative w-full h-full"}>
            {title && <div className={"font-medium text-base mb-1"}>{title}</div>}
            <div
                ref={selectRef}
                className={
                    "flex items-center p-2 rounded-md shadow border-1 border-gray-400 " +
                    (disabled ? "bg-gray-200 cursor-not-allowed" : "cursor-pointer")
                }
                onClick={() => {
                    if (disabled) {
                        return;
                    }
                    if (open) {
                        setSearch("");
                    }
                    setOpen((open) => !open);
                }}
            >
                <div
                    className={
                        "h-5 w-full text-sm overflow-hidden overflow-ellipsis select-none " +
                        (!renderedValue
                            ? !disabled
                                ? "text-gray-500"
                                : "text-gray-400"
                            : !disabled
                            ? "text-black"
                            : "text-gray-500")
                    }
                >
                    {!renderedValue && placeholder}
                    {renderedValue}
                </div>
                <div className={isLoading ? "hidden " : ""}>
                    <ChevronDown
                        className={
                            "h-4 w-4 transform transition-transform ml-1 " +
                            (open ? "rotate-180 " : "") +
                            (!disabled ? "" : "text-gray-400")
                        }
                    />
                </div>
                <div className={!isLoading ? "hidden " : ""}>
                    <SpinnerIcon className={"h-4 w-4 animate-spin " + (!disabled ? "" : "text-gray-400")} />
                </div>
            </div>
            {open && (
                <div
                    ref={popupRef}
                    className={
                        "absolute z-20 right-0 left-0 max-h-60 rounded-md p-2 overflow-y-auto scrollbar-bg-transparent shadow border-1 border-gray-400 bg-white " +
                        popupPositionClassNames /*+
                        (!disabled ? theme.formBg : theme.formDisabledBg)*/
                    }
                >
                    {filter && (
                        <div className={"flex items-center justify-center mb-1"}>
                            <div
                                className={
                                    "flex items-center p-2 rounded-md shadow w-full " /*+
                                    (!disabled
                                        ? theme.formSelectSearch
                                        : theme.formDisabledSelectSearch + " cursor-not-allowed")*/
                                }
                            >
                                <input
                                    ref={inputRef}
                                    className={
                                        "border-none w-full bg-transparent outline-none h-5 text-sm " +
                                        /*(!disabled
                                            ? theme.formSelectSearchPlaceholder
                                            : theme.formDisabledSelectSearchPlaceholder) +*/
                                        " " +
                                        //(!disabled ? theme.formText : theme.formDisabledText) +
                                        " " +
                                        (disabled ? " cursor-not-allowed" : "")
                                    }
                                    readOnly={disabled}
                                    value={search}
                                    onChange={(e) => {
                                        setSearch(e.target.value);
                                    }}
                                    placeholder={allowAdditions ? t("searchOrAdd") : t("search")}
                                />
                                <DeleteIcon
                                    className={
                                        "h-4 w-4 ml-2 " +
                                        (search.length === 0 ? "hidden " : " ") +
                                        (!disabled ? "cursor-pointer" : "cursor-not-allowed")
                                    }
                                    onClick={() => {
                                        setSearch("");
                                    }}
                                />
                            </div>
                            <div
                                onClick={() => {
                                    onAdd(search);
                                    setSearch("");
                                }}
                                className={
                                    "bg-green-700 text-lg rounded-md ml-1 p-2 cursor-pointer hover:bg-opacity-90 font-bold flex items-center " +
                                    (allowAdditions && search.length > 0 && canAddNew(search) ? "" : "hidden")
                                }
                            >
                                <PlusIcon className={"w-3 h-3 text-white"} />
                            </div>
                        </div>
                    )}
                    {renderedData.length === 0 && <div className={"text-sm"}>{t("noResults")}</div>}
                    {renderedData}
                </div>
            )}
        </div>
    );
};

export default Select;
