import { useState, useRef, useEffect, useCallback } from "react";
import {
    startOfWeek,
    startOfMonth,
    endOfMonth,
    eachWeekOfInterval,
    eachDayOfInterval,
    endOfWeek,
    isSameMonth,
    format,
    getMonth,
    getYear,
    subMonths,
    addMonths,
    isSameDay,
    isWeekend
} from "date-fns";
import { ReactComponent as CalendarIcon } from "../svgIcons/calendar-alt-solid.svg";
import { ReactComponent as CaretLeftIcon } from "../svgIcons/caret-left-solid.svg";
import { ReactComponent as CaretRightIcon } from "../svgIcons/caret-right-solid.svg";
import { useTranslation } from "react-i18next";

const CalendarInput = ({ value = null, title, onChange = () => {}, placeholder, disabled = false }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const popupRef = useRef(null);

    const [calendarDate, setCalendarDate] = useState(new Date());

    const closePopup = useCallback(() => {
        if (window.innerWidth < 800) {
            if (popupRef.current) {
                const bounding = popupRef.current.getBoundingClientRect();
                if (bounding.top <= 0 || bounding.top >= window.innerHeight) {
                    setOpen(false);
                }
            }
        } else if (open) {
            setOpen(false);
        }
    }, [open]);

    const closePopupOnClick = useCallback(
        (e) => {
            if (popupRef.current && open && !popupRef.current.contains(e.target)) {
                setOpen(false);
            }
        },
        [open]
    );

    useEffect(() => {
        window.addEventListener("scroll", closePopup);
        window.addEventListener("click", closePopupOnClick);

        return () => {
            window.removeEventListener("scroll", closePopup);
            window.removeEventListener("click", closePopupOnClick);
        };
    }, [closePopup, closePopupOnClick]);

    useEffect(() => {
        if (disabled && open) {
            setOpen(false);
        }
    }, [open, disabled]);

    return (
        <div className={"relative w-full h-full"}>
            <div className={"font-medium text-base mb-1"}>{title}</div>
            <div
                className={
                    "flex items-center p-2 rounded-md shadow border-1 border-gray-400 w-full " +
                    (disabled ? "cursor-not-allowed bg-gray-200" : "cursor-pointer")
                }
                onClick={() => {
                    if (disabled) {
                        return;
                    }
                    setOpen((open) => !open);
                }}
            >
                <div
                    className={
                        "h-5 w-full flex items-center text-sm overflow-hidden overflow-ellipsis whitespace-nowrap select-none"
                    }
                >
                    {!value && placeholder}
                    {value && format(value, t("shortDateFormat"))}
                </div>
                <CalendarIcon className={"h-4 w-4 ml-1 text-gray-400"} />
            </div>
            {open && (
                <div className={"absolute z-20 mt-1 top-full w-full flex justify-center"}>
                    <div
                        ref={popupRef}
                        className={"rounded-md py-2 px-3 w-auto shadow bg-white border-1 border-gray-400"}
                    >
                        <div className={"font-semibold flex items-center justify-between mb-2 select-none"}>
                            <CaretLeftIcon
                                className={"h-6 w-6 cursor-pointer rounded-md hover:bg-gray-300"}
                                onClick={() => {
                                    setCalendarDate((prevDate) => subMonths(prevDate, 1));
                                }}
                            />
                            {t("months." + getMonth(calendarDate)) + " " + getYear(calendarDate)}
                            <CaretRightIcon
                                className={"h-6 w-6 cursor-pointer rounded-md hover:bg-gray-300"}
                                onClick={() => {
                                    setCalendarDate((prevDate) => addMonths(prevDate, 1));
                                }}
                            />
                        </div>
                        <table className={"text-center text-sm select-none"}>
                            <thead>
                                <tr>
                                    {Object.values(t("calendar", { returnObjects: true })).map((day) => (
                                        <th className={"font-normal"} key={day}>
                                            {day}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {eachWeekOfInterval(
                                    {
                                        start: startOfMonth(calendarDate),
                                        end: endOfMonth(calendarDate)
                                    },
                                    { weekStartsOn: 1 }
                                ).map((weekStart, index) => (
                                    <tr key={index}>
                                        {eachDayOfInterval({
                                            start: startOfWeek(weekStart, { weekStartsOn: 1 }),
                                            end: endOfWeek(weekStart, { weekStartsOn: 1 })
                                        }).map((weekDay, index) => {
                                            const sameDay = isSameDay(weekDay, value);
                                            const sameMonth = isSameMonth(calendarDate, weekDay);
                                            return (
                                                <td
                                                    key={index}
                                                    className={
                                                        "p-2 rounded-full " +
                                                        (!sameMonth ? " text-gray-400" : " cursor-pointer") +
                                                        (sameDay
                                                            ? " bg-gray-300 hover:bg-gray-300"
                                                            : " hover:bg-gray-200") +
                                                        (sameMonth && isWeekend(weekDay) ? " text-red-600" : "")
                                                    }
                                                    onClick={() => {
                                                        if (disabled || !sameMonth) {
                                                            return;
                                                        }
                                                        if (sameDay) {
                                                            onChange(null);
                                                        } else {
                                                            onChange(weekDay);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {sameMonth ? format(weekDay, "d") : ""}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CalendarInput;
