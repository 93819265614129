import { useTranslation } from "react-i18next";
import NavigationMenu from "../../../components/navigationMenu";
import { format, parseISO } from "date-fns";

const RecordInfo = ({ previousPage, record }) => {
    const { t } = useTranslation();
    return (
        <div className="h-full">
            <NavigationMenu previousPage={previousPage} />
            {record && (
                <div className="border-l-1 border-gray-600 h-full mt-4 pt-4 pb-4 pl-2">
                    <InfoRow title={t("lot")} value={record.lotto} />
                    <InfoRow title={t("campaign")} value={record.campagna} />
                    <InfoRow title={t("plasma")} value={record.plasma} />
                    <InfoRow title={t("batchRecord")} value={record.batchRecord} />
                    <InfoRow
                        title={t("statusChangeDate")}
                        value={format(parseISO(record.dataModificaStatus), t("chartDateFormat"))}
                    />
                </div>
            )}
        </div>
    );
};

const InfoRow = ({ title, value }) => (
    <div className="mb-8 mt-8 first:mt-0 last:mb-0">
        <div className="font-semibold text-2xl whitespace-nowrap mb-1">{title}</div>
        <div className="underline font-bold text-3xl text-green-500 whitespace-nowrap">{value}</div>
    </div>
);

export default RecordInfo;
