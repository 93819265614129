import { add, endOfDay, format, isValid, parseISO, startOfDay } from "date-fns";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import CalendarWeekInput from "../../../components/calendarWeekInput";
import { CommonButton } from "../../../components/commonButton";
import NavigationMenu from "../../../components/navigationMenu";
import Popup from "../../../components/popup";
import Select from "../../../components/select";
import Spinner from "../../../components/spinner";
import { API_DATE_FORMAT, CALENDAR_DAYS, campaigns, topBarType, userRoles } from "../../../utils/constants";
import { AppContext, UserContext } from "../../../utils/contexts";
import { useQuery } from "../../../utils/functions";
import { useRequest } from "../../../utils/requestManager";
import {
    CHANGE_RECORD_STATUS,
    DELETE_PLANNING_RECORD,
    EXPORT_SCHEDULED_DATA,
    GMP_REVISIONS_CHART,
    LEAD_TIME,
    LOT_RESET,
    LOT_SHIFT,
    PLANNING_CHART_DATA,
    PLANNING_EXCEPTIONS,
    PLANNING_NOTE_LOGISTICA
} from "../../../utils/requests";
import Overlay from "../../dataRequest/overlay";
import Chart from "./chart";
import { LogisticNotesPopup } from "../logisticNotesPopup";

export const printStatuses = Object.freeze({
    Stampabile: {
        key: "Stampabile",
        className: "text-green-600",
        color: "rgb(5, 150, 105)"
    },
    InLavorazione: {
        key: "InLavorazione",
        className: "text-blue-600",
        color: "rgb(37, 99, 235)"
    },
    NonStampabileInRevisione: {
        key: "NonStampabileInRevisione",
        className: "text-yellow-500",
        color: "rgb(245, 158, 11)"
    },
    NonStampabileInRevisioneForm: {
        key: "NonStampabileInRevisioneForm",
        className: "text-green-yellow",
        color: "rgb(183, 209, 50)"
    },
    NonStampabile: {
        key: "NonStampabile",
        className: "text-red-600",
        color: "rgb(220, 38, 38)"
    },
    Stampato: {
        key: "Stampato",
        className: "text-gray-300",
        color: "rgb(209, 213, 219)"
    }
});

const printStatusesArray = Object.freeze(Object.values(printStatuses));

const campaignsArray = Object.freeze([campaigns.PROCESS, campaigns.ALBUMINA]);

const SchedulingBoard = () => {
    const { setTopBarOptions } = useContext(AppContext);
    const { t } = useTranslation();
    const { currentUser } = useContext(UserContext);

    useEffect(() => {
        setTopBarOptions({
            pageTitle: t("schedulingBoard"),
            barType: topBarType.TITLE
        });
    }, [setTopBarOptions, t]);

    const history = useHistory();
    const query = useQuery();
    const { url } = useRouteMatch();

    const range = useMemo(() => {
        const queryDateString = query.get("weekStart");
        let queryDate = new Date(queryDateString);

        if (!queryDateString || !queryDate || !isValid(queryDate)) {
            queryDate = new Date();
        }

        const weekStart = startOfDay(queryDate);
        const weekEnd = endOfDay(add(queryDate, { days: CALENDAR_DAYS }));

        return {
            weekStart,
            weekEnd,
            startDate: format(weekStart, API_DATE_FORMAT),
            endDate: format(weekEnd, API_DATE_FORMAT)
        };
    }, [query]);

    const {
        data: chartData,
        isLoading: isLoadingData,
        mutate: mutateData,
        request
    } = useRequest(PLANNING_CHART_DATA, { params: range });

    const { data: leadTime, isLoading: isLoadingLeadTime } = useRequest(LEAD_TIME, {
        transformData: (data) => ({
            min: parseISO(data.min),
            max: parseISO(data.max)
        }),
        refreshInterval: 120000
    });

    const canGetRevisions = useMemo(
        () => [userRoles.GMP_DOC, userRoles.LOGISTIC, userRoles.SUPER_LOGISTIC].includes(currentUser.role),
        [currentUser.role]
    );

    const { data: revisions, isLoading: isLoadingRevisions } = useRequest(
        canGetRevisions ? GMP_REVISIONS_CHART : null,
        {
            initialState: [],
            params: range,
            transformData: (data) => {
                const newData = {};

                data.forEach((r) => {
                    if (!newData[r.inizioValidita]) {
                        newData[r.inizioValidita] = [];
                    }

                    newData[r.inizioValidita].push({
                        ...r,
                        inizioValidita: parseISO(r.inizioValidita)
                    });
                });

                return Object.values(newData);
            }
        }
    );

    const { data: exceptions, isLoading: isLoadingExceptions } = useRequest(PLANNING_EXCEPTIONS, {
        params: range,
        transformData: (exceptions) => exceptions.map((e) => parseISO(e))
    });

    const {
        data: logisticNotes,
        isLoading: isLoadingLogisticNotes,
        mutate: mutateLogisticNotes
    } = useRequest(PLANNING_NOTE_LOGISTICA, {
        initialState: [],
        params: range,
        transformData: (notes) =>
            notes.map((e) => ({
                ...e,
                data: parseISO(e.data)
            }))
    });

    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedCampaign, setSelectedCampaign] = useState(null);

    const hasLoadedFirstTime = useRef(false);

    const isLoading = useMemo(() => {
        if (hasLoadedFirstTime.current) {
            return false;
        }
        if (isLoadingData || isLoadingLeadTime || isLoadingRevisions || isLoadingExceptions || isLoadingLogisticNotes) {
            return true;
        }
        hasLoadedFirstTime.current = true;
        return false;
    }, [isLoadingData, isLoadingExceptions, isLoadingLeadTime, isLoadingRevisions, isLoadingLogisticNotes]);

    const [isSaving, setIsSaving] = useState(false);

    const [shiftPopup, setShiftPopup] = useState({
        show: false,
        reset: false,
        lot: null
    });

    const [deletePopup, setDeletePopup] = useState({
        show: false,
        record: null
    });

    const [showLogisticNotesPopup, setShowLogisticNotesPopup] = useState(false);

    return (
        <div className="w-full min-w-50rem flex flex-col items-center justify-center">
            <div className="w-11/12 flex">
                <div className="w-full flex items-center">
                    <div className="w-60">
                        <CalendarWeekInput
                            title={t("referencePeriod")}
                            value={range.weekStart}
                            onChange={(weekStart) => {
                                history.push({
                                    pathname: url,
                                    search: `?weekStart=${format(weekStart, API_DATE_FORMAT)}`
                                });
                            }}
                            disabled={isLoading}
                        />
                    </div>
                    <div className="w-56 ml-5">
                        <Select
                            title={t("printStatus")}
                            placeholder={t("all")}
                            data={printStatusesArray}
                            value={selectedStatus}
                            onChange={(value) => {
                                setSelectedStatus(value);
                            }}
                            keyField="key"
                            render={(obj) => <span className={obj.className + " font-bold"}>{t(obj.key)}</span>}
                            disabled={isLoading}
                        />
                    </div>
                    <div className="w-48 ml-5">
                        <Select
                            title={t("campaign")}
                            placeholder={t("all")}
                            data={campaignsArray}
                            value={selectedCampaign}
                            onChange={(value) => {
                                setSelectedCampaign(value);
                            }}
                            render={(obj) => t(obj)}
                            disabled={isLoading}
                        />
                    </div>
                </div>
                <div className="flex flex-col">
                    <NavigationMenu />
                    <div className="flex w-full justify-end items-center">
                        {[userRoles.SUPER_LOGISTIC, userRoles.LOGISTIC].includes(currentUser.role) && (
                            <button
                                className="rounded border-1 p-2 flex items-center justify-center shadow hover:bg-gray-200 cursor-pointer border-gray-400 text-red-600 mt-8 mr-2 whitespace-nowrap"
                                onClick={async () => {
                                    setShowLogisticNotesPopup(true);
                                }}
                            >
                                {t("logisticNotes")}
                            </button>
                        )}
                        <button
                            className="rounded border-1 p-2 flex items-center justify-center shadow hover:bg-gray-200 cursor-pointer border-gray-400 text-red-600 mt-8"
                            onClick={async () => {
                                const response = await request(EXPORT_SCHEDULED_DATA, {
                                    ignoreResponse: true,
                                    params: {
                                        startDate: range.startDate
                                    }
                                });

                                const file = await response.response.blob();
                                const url = URL.createObjectURL(file);
                                window.open(url, "_blank");
                                URL.revokeObjectURL(url);
                            }}
                        >
                            Export
                        </button>
                    </div>
                </div>
            </div>
            {isLoading && (
                <div className="h-40">
                    <Spinner />
                </div>
            )}
            {!isLoading && (!chartData || chartData.length === 0) && (
                <div className="flex items-center justify-center font-bold text-3xl text-gray-600 h-40">No Data</div>
            )}
            {!isLoading && chartData && chartData.length > 0 && (
                <Chart
                    data={chartData}
                    selectedStatus={selectedStatus}
                    selectedCampaign={selectedCampaign}
                    revisions={revisions}
                    exceptions={exceptions}
                    logisticNotes={logisticNotes}
                    minLt={leadTime.min}
                    maxLt={leadTime.max}
                    minDate={range.weekStart}
                    maxDate={range.weekEnd}
                    onStatusChange={async (record, status) => {
                        setIsSaving(true);

                        const response = await request(CHANGE_RECORD_STATUS, {
                            method: "PUT",
                            ignoreResponse: true,
                            params: {
                                id: record.id,
                                stato: status
                            }
                        });

                        if (!response.ok) {
                            if (response.status !== 401) {
                                setIsSaving(false);
                            }
                            alert(t(response.errorMessage));
                            return;
                        }

                        mutateData((old) => {
                            let data = old
                                .find((c) => c.category === record.name)
                                .data.find((d) => d.some((r) => r.id === record.id))
                                .find((r) => r.id === record.id);
                            data.status = status;
                            data.statusDateChange = new Date();
                            return [...old];
                        });

                        setIsSaving(false);
                    }}
                    onLotShift={(lot) => {
                        setShiftPopup({
                            show: true,
                            reset: false,
                            lot
                        });
                    }}
                    onLotReset={(lot) => {
                        setShiftPopup({
                            show: true,
                            reset: true,
                            lot
                        });
                    }}
                    onDeleteRecord={(record) => {
                        setDeletePopup({
                            show: true,
                            record
                        });
                    }}
                />
            )}
            {isSaving && (
                <Overlay>
                    <Spinner />
                </Overlay>
            )}
            {shiftPopup.show && (
                <ShiftPopup
                    lot={shiftPopup.lot}
                    lotReset={shiftPopup.reset}
                    onClose={() => {
                        setShiftPopup({
                            show: false,
                            reset: false,
                            lot: null
                        });
                    }}
                    onConfirm={() => {
                        hasLoadedFirstTime.current = false;
                        history.push({
                            pathname: url,
                            search: `?weekStart=${range.startDate}`
                        });
                        setShiftPopup({
                            show: false,
                            reset: false,
                            lot: null
                        });
                    }}
                    request={request}
                    range={range}
                />
            )}
            {deletePopup.show && (
                <Popup>
                    <div className="font-bold text-red-600 text-xl mb-1">{t("irreversibleAction")}</div>
                    <div className="flex items-center justify-between w-full mt-3">
                        <div className="w-32">
                            <CommonButton
                                onClick={() => {
                                    setDeletePopup({
                                        show: false,
                                        record: null
                                    });
                                }}
                                className="text-red-600 border-red-600"
                            >
                                {t("back")}
                            </CommonButton>
                        </div>
                        <div className="w-32">
                            <CommonButton
                                className="text-green-600 border-green-600"
                                onClick={async () => {
                                    const record = deletePopup.record;
                                    setDeletePopup({
                                        show: false,
                                        record: null
                                    });
                                    setIsSaving(true);

                                    const response = await request(DELETE_PLANNING_RECORD, {
                                        method: "DELETE",
                                        ignoreResponse: true,
                                        params: {
                                            id: record.id
                                        }
                                    });

                                    if (!response.ok) {
                                        if (response.status !== 401) {
                                            setIsSaving(false);
                                        }
                                        alert(t(response.errorMessage));
                                        return;
                                    }

                                    mutateData((old) =>
                                        old.map((c) => {
                                            if (c.category !== record.name) {
                                                return c;
                                            }
                                            return {
                                                ...c,
                                                data: c.data.map((d) => d.filter((r) => r.id !== record.id))
                                            };
                                        })
                                    );

                                    setIsSaving(false);
                                }}
                            >
                                {t("confirm")}
                            </CommonButton>
                        </div>
                    </div>
                </Popup>
            )}
            {showLogisticNotesPopup && (
                <LogisticNotesPopup
                    notes={logisticNotes}
                    mutate={mutateLogisticNotes}
                    onClose={() => {
                        setShowLogisticNotesPopup(false);
                    }}
                    request={request}
                    minDate={new Date(range.startDate)}
                />
            )}
        </div>
    );
};

const ShiftPopup = ({ lot, onClose, onConfirm, request, lotReset }) => {
    const { t } = useTranslation();
    const [days, setDays] = useState(0);

    const [isSaving, setIsSaving] = useState(false);

    if (lotReset) {
        return (
            <Popup>
                {isSaving && <Spinner />}
                {!isSaving && (
                    <>
                        <div className="font-bold text-red-600 text-xl mb-1">{t("irreversibleAction")}</div>
                        <div className="font-bold text-2xl mb-3">{lot}</div>
                        <div className="flex items-center justify-between w-full">
                            <div className="w-32">
                                <CommonButton onClick={onClose} className="text-red-600 border-red-600">
                                    {t("back")}
                                </CommonButton>
                            </div>
                            <div className="w-32">
                                <CommonButton
                                    className="text-green-600 border-green-600"
                                    onClick={async () => {
                                        setIsSaving(true);

                                        const yearIndex = lot.indexOf("(");
                                        const response = await request(LOT_RESET, {
                                            method: "PUT",
                                            ignoreResponse: true,
                                            body: JSON.stringify({
                                                lotto: lot.substring(0, yearIndex - 1),
                                                anno: lot.substring(yearIndex + 1, lot.length - 1)
                                            })
                                        });

                                        if (!response.ok) {
                                            alert(t(response.errorMessage));
                                            setIsSaving(false);
                                            return;
                                        }

                                        onConfirm();
                                    }}
                                >
                                    {t("confirm")}
                                </CommonButton>
                            </div>
                        </div>
                    </>
                )}
            </Popup>
        );
    }

    return (
        <Popup>
            {isSaving && <Spinner />}
            {!isSaving && (
                <>
                    <div className="font-bold text-red-600 text-xl mb-1">{t("shiftPopupDays")}</div>
                    <div className="font-bold text-2xl mb-3">{lot}</div>
                    <input
                        type="number"
                        className="border-1 border-gray-600 rounded-md text-4xl w-20 mb-4"
                        value={days}
                        onChange={(e) => {
                            let value = e.target.value;
                            if (/[^-0-9]*/.test(value)) {
                                value = value.replace(/[^-0-9]*/g, "");
                            }
                            setDays(value);
                        }}
                    />
                    <div className="flex items-center justify-between w-full">
                        <div className="w-32">
                            <CommonButton onClick={onClose} className="text-red-600 border-red-600">
                                {t("back")}
                            </CommonButton>
                        </div>
                        <div className="w-32">
                            <CommonButton
                                className="text-green-600 border-green-600"
                                onClick={async () => {
                                    setIsSaving(true);

                                    const yearIndex = lot.indexOf("(");
                                    const response = await request(LOT_SHIFT, {
                                        method: "PUT",
                                        ignoreResponse: true,
                                        body: JSON.stringify({
                                            lotto: lot.substring(0, yearIndex - 1),
                                            anno: lot.substring(yearIndex + 1, lot.length - 1),
                                            giorni: days
                                        })
                                    });

                                    if (!response.ok) {
                                        if (response.status !== 409) {
                                            alert(t("shiftConflict"));
                                        } else {
                                            alert(t(response.errorMessage));
                                        }
                                        setIsSaving(false);
                                        return;
                                    }

                                    onConfirm();
                                }}
                            >
                                {t("confirm")}
                            </CommonButton>
                        </div>
                    </div>
                </>
            )}
        </Popup>
    );
};

export default SchedulingBoard;
