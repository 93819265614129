import { format } from "date-fns";
import Popup from "../../components/popup";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ReactComponent as PlusIcon } from "../../svgIcons/plus-solid.svg";
import { ReactComponent as CheckIcon } from "../../svgIcons/check-solid.svg";
import { ReactComponent as TimesIcon } from "../../svgIcons/times-solid.svg";
import { ReactComponent as TrashIcon } from "../../svgIcons/trash-alt-solid.svg";
import { ReactComponent as EditIcon } from "../../svgIcons/edit-regular.svg";
import TextArea from "../../components/textArea";
import { v4 } from "uuid";
import { EDIT_PLANNING_NOTE_LOGISTICA, INSERT_PLANNING_NOTE_LOGISTICA } from "../../utils/requests";
import { API_DATE_FORMAT } from "../../utils/constants";

export const LogisticNotesPopup = ({ notes, minDate, mutate, onClose, request }) => {
    const { t } = useTranslation();

    const [editingNotes, setEditingNotes] = useState([]);

    return (
        <Popup>
            <table className="w-full rounded-t-md text-sm min-w-30rem">
                <thead className="text-xl">
                    <tr className="border-b-2">
                        <th className="border-2 p-1">{t("date")}</th>
                        <th className="border-2 p-1">{t("text")}</th>
                        <th className="border-2 p-1">
                            <button
                                className={
                                    "border-1 shadow p-1.5 rounded-md cursor-pointer bg-green-700 hover:bg-green-600"
                                }
                                onClick={() => {
                                    setEditingNotes((ed) => [
                                        {
                                            id: v4(),
                                            data: new Date(),
                                            text: null,
                                            isSaving: false,
                                            isNew: true
                                        },
                                        ...ed
                                    ]);
                                }}
                            >
                                <PlusIcon className="w-4 h-4 text-white" />
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {editingNotes
                        .filter((n) => n.isNew)
                        .map((n) => (
                            <TableRow
                                minDate={minDate}
                                mutate={mutate}
                                note={n}
                                request={request}
                                setEditingNotes={setEditingNotes}
                                editing={true}
                                key={n.id}
                            />
                        ))}
                    {notes.map((n) => {
                        const editingNode = editingNotes.find((e) => e.id === n.id);

                        let editing = false;
                        let note = n;

                        if (editingNode) {
                            editing = true;
                            note = editingNode;
                        }

                        return (
                            <TableRow
                                minDate={minDate}
                                mutate={mutate}
                                note={note}
                                request={request}
                                setEditingNotes={setEditingNotes}
                                editing={editing}
                                key={n.id}
                            />
                        );
                    })}
                    {notes.length === 0 && editingNotes.length === 0 && (
                        <tr>
                            <td colSpan={3} className="p-2 border-1">
                                {t("noData")}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            <div className="w-full flex items-center justify-end mt-4">
                <button
                    className="float-right rounded border-1 p-2 flex items-center justify-center shadow hover:bg-gray-200 cursor-pointer border-gray-400 text-red-600 whitespace-nowrap"
                    onClick={onClose}
                >
                    {t("back")}
                </button>
            </div>
        </Popup>
    );
};

const TableRow = ({ note, minDate, setEditingNotes, editing = false, request, mutate }) => {
    const { t } = useTranslation();

    return (
        <tr>
            <td className="p-1 border-1">
                {editing ? (
                    <input
                        type="datetime-local"
                        className="rounded-md border-1 shadow border-gray-400"
                        value={format(note.data, "yyyy-MM-dd'T'HH:mm")}
                        min={format(minDate, "yyyy-MM-dd'T'HH:mm")}
                        onChange={(e) => {
                            setEditingNotes((old) => {
                                const index = old.findIndex((r) => r.id === note.id);
                                old[index] = {
                                    ...old[index],
                                    data: new Date(e.target.value)
                                };
                                return [...old];
                            });
                        }}
                    />
                ) : (
                    format(note.data, t("dateNoSecondsFormat"))
                )}
            </td>
            <td className="p-1 border-1">
                {editing ? (
                    <TextArea
                        placeholder={t("text")}
                        value={note.text}
                        minHeight="3.1rem"
                        onChange={(value) => {
                            setEditingNotes((old) => {
                                const index = old.findIndex((r) => r.id === note.id);
                                old[index] = {
                                    ...old[index],
                                    text: value
                                };
                                return [...old];
                            });
                        }}
                    />
                ) : (
                    note.text
                )}
            </td>
            <td className="p-1 border-1">
                {editing ? (
                    <EditButtons
                        showDelete={!note.isNew}
                        onConfirm={async () => {
                            let url;
                            let method;
                            let params;
                            let ignoreResponse;

                            if (note.isNew) {
                                url = INSERT_PLANNING_NOTE_LOGISTICA;
                                method = "POST";
                                params = null;
                                ignoreResponse = false;
                            } else {
                                url = EDIT_PLANNING_NOTE_LOGISTICA;
                                method = "PUT";
                                params = {
                                    id: note.id
                                };
                                ignoreResponse = true;
                            }

                            const response = await request(url, {
                                method,
                                params,
                                ignoreResponse,
                                body: JSON.stringify({
                                    data: format(note.data, API_DATE_FORMAT),
                                    text: note.text
                                })
                            });

                            if (!response.ok) {
                                alert(t(response.errorMessage));
                                return;
                            }

                            if (note.isNew) {
                                mutate((old) =>
                                    [
                                        ...old,
                                        {
                                            id: note.isNew ? response.data.id : note.id,
                                            data: note.data,
                                            text: note.text
                                        }
                                    ].sort((a, b) => (a.data > b.data ? 1 : -1))
                                );
                            } else {
                                mutate((old) => {
                                    const index = old.findIndex((r) => r.id === note.id);
                                    old[index] = {
                                        id: note.id,
                                        data: note.data,
                                        text: note.text
                                    };
                                    return [...old].sort((a, b) => (a.data > b.data ? 1 : -1));
                                });
                            }

                            setEditingNotes((old) => old.filter((o) => o.id !== note.id));
                        }}
                        onCancel={() => {
                            setEditingNotes((old) => old.filter((o) => o.id !== note.id));
                        }}
                        onDelete={async () => {
                            const response = await request(EDIT_PLANNING_NOTE_LOGISTICA, {
                                method: "DELETE",
                                ignoreResponse: true,
                                params: {
                                    id: note.id
                                }
                            });

                            if (!response.ok) {
                                alert(t(response.errorMessage));
                                return;
                            }

                            setEditingNotes((old) => old.filter((o) => o.id !== note.id));
                            mutate((old) => old.filter((o) => o.id !== note.id));
                        }}
                    />
                ) : (
                    <button
                        className={"border-1 shadow p-1.5 rounded-md cursor-pointer bg-gray-300 hover:bg-gray-200"}
                        onClick={() => {
                            setEditingNotes((old) => [...old, note]);
                        }}
                    >
                        <EditIcon className="w-4 h-4 text-gray-700" />
                    </button>
                )}
            </td>
        </tr>
    );
};

const EditButtons = ({ onConfirm, onCancel, showDelete, onDelete }) => (
    <div className="flex items-center justify-center">
        <button
            className={"border-1 shadow p-1.5 rounded-md cursor-pointer bg-green-600 hover:bg-green-500"}
            onClick={onConfirm}
        >
            <CheckIcon className="w-4 h-4 text-white" />
        </button>
        <button
            className={"border-1 shadow p-1.5 rounded-md cursor-pointer bg-yellow-600 hover:bg-yellow-500 ml-1"}
            onClick={onCancel}
        >
            <TimesIcon className="w-4 h-4 text-white" />
        </button>
        {showDelete && (
            <>
                <span className="ml-2 mr-2">--</span>
                <button
                    className={"border-1 shadow p-1.5 rounded-md cursor-pointer bg-red-700 hover:bg-red-600"}
                    onClick={onDelete}
                >
                    <TrashIcon className="w-4 h-4 text-white" />
                </button>
            </>
        )}
    </div>
);
