import {
    addMonths,
    eachDayOfInterval,
    eachWeekOfInterval,
    endOfMonth,
    endOfWeek,
    format,
    getDay,
    getMonth,
    getYear,
    isSameDay,
    isSameMonth,
    isWeekend,
    startOfMonth,
    startOfWeek,
    subMonths
} from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CaretLeftIcon } from "../../svgIcons/caret-left-solid.svg";
import { ReactComponent as CaretRightIcon } from "../../svgIcons/caret-right-solid.svg";
import { ReactComponent as DeletIcon } from "../../svgIcons/times-solid.svg";

const views = Object.freeze({ CALENDAR: "CALENDAR", LIST: "LIST" });

const CalendarExceptions = ({ exceptions, onExceptionsAdd, onExceptionsDelete }) => {
    const { t } = useTranslation();

    const [view, setView] = useState(views.CALENDAR);

    return (
        <div className="flex justify-between max-w-xl mt-8">
            <div className="text-2xl font-semibold mr-4">{t("calendarExceptions")}</div>
            <div className={"flex justify-center"}>
                <div className={"rounded-md w-auto shadow bg-white border-1 border-gray-400"}>
                    <div className={"flex flex-row flex-nowrap items-end justify-center shadow rounded-t-md w-full"}>
                        <CalendarTab
                            title={t("calendarView")}
                            selected={view === views.CALENDAR}
                            onClick={() => {
                                setView(views.CALENDAR);
                            }}
                        />
                        <CalendarTab
                            title={t("list")}
                            selected={view === views.LIST}
                            onClick={() => {
                                setView(views.LIST);
                            }}
                        />
                    </div>
                    <div className="py-2 px-3 overflow-auto">
                        {view === views.CALENDAR && (
                            <Calendar
                                exceptions={exceptions}
                                onExceptionsAdd={onExceptionsAdd}
                                onExceptionsDelete={onExceptionsDelete}
                            />
                        )}
                        {view === views.LIST && (
                            <List exceptions={exceptions} onExceptionsDelete={onExceptionsDelete} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const CalendarTab = ({ selected = false, title, onClick }) => (
    <div
        className={
            "relative h-full flex items-center justify-center p-1 cursor-pointer rounded-t-md shadow w-full " +
            (selected ? "text-red-600" : "text-gray-500 hover:bg-gray-300 hover:bg-opacity-60")
        }
        onClick={onClick}
    >
        <div className={"font-bold text-sm"}>{title}</div>
        <div
            className={
                "absolute bottom-0 rignt-0 left-0 w-full " + (selected ? "h-0.200rem bg-red-600" : "h-0.5 bg-gray-500")
            }
        />
    </div>
);

const Calendar = ({ exceptions, onExceptionsAdd, onExceptionsDelete }) => {
    const { t } = useTranslation();
    const [calendarDate, setCalendarDate] = useState(new Date());

    return (
        <>
            <div className={"font-semibold flex items-center justify-between mb-2 select-none"}>
                <CaretLeftIcon
                    className={"h-6 w-6 cursor-pointer rounded-md hover:bg-gray-300"}
                    onClick={() => {
                        setCalendarDate((prevDate) => subMonths(prevDate, 1));
                    }}
                />
                {t("months." + getMonth(calendarDate)) + " " + getYear(calendarDate)}
                <CaretRightIcon
                    className={"h-6 w-6 cursor-pointer rounded-md hover:bg-gray-300"}
                    onClick={() => {
                        setCalendarDate((prevDate) => addMonths(prevDate, 1));
                    }}
                />
            </div>
            <table className={"text-center text-sm select-none"}>
                <thead>
                    <tr>
                        {Object.values(t("calendar", { returnObjects: true })).map((day) => (
                            <th className={"font-normal"} key={day}>
                                {day}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {eachWeekOfInterval(
                        {
                            start: startOfMonth(calendarDate),
                            end: endOfMonth(calendarDate)
                        },
                        { weekStartsOn: 1 }
                    ).map((weekStart, index) => (
                        <tr key={index}>
                            {eachDayOfInterval({
                                start: startOfWeek(weekStart, { weekStartsOn: 1 }),
                                end: endOfWeek(weekStart, { weekStartsOn: 1 })
                            }).map((weekDay, index) => {
                                const sameMonth = isSameMonth(calendarDate, weekDay);
                                const exception = exceptions.find((e) => isSameDay(weekDay, e.eccezione));

                                return (
                                    <td
                                        key={index}
                                        className={
                                            "p-2 rounded-full" +
                                            (!sameMonth ? " text-gray-400" : " cursor-pointer") +
                                            (sameMonth && exception
                                                ? " bg-gray-300 hover:bg-gray-300"
                                                : " hover:bg-gray-200") +
                                            (sameMonth && isWeekend(weekDay) ? " text-red-600" : "")
                                        }
                                        onClick={() => {
                                            if (!sameMonth) {
                                                return;
                                            }
                                            if (exception) {
                                                onExceptionsDelete(exception);
                                            } else {
                                                console.log("add");
                                                onExceptionsAdd(weekDay);
                                            }
                                        }}
                                    >
                                        {format(weekDay, "d")}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

const List = ({ exceptions, onExceptionsDelete }) => {
    const { t } = useTranslation();

    return (
        <div className="h-56 w-56 text-lg">
            <table className="w-full">
                <tbody>
                    {exceptions.map((e) => (
                        <tr key={e.id} className="hover:bg-gray-200 rounded-md">
                            <td className="font-semibold p-1 rounded-l-md">{t("calendar." + getDay(e.eccezione))}</td>
                            <td className="p-1">{format(e.eccezione, t("shortDateFormat"))}</td>
                            <td className="p-1 rounded-r-md">
                                <DeletIcon
                                    className="text-red-600 h-4 w-4 float-right cursor-pointer"
                                    onClick={() => {
                                        onExceptionsDelete(e);
                                    }}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CalendarExceptions;
