export const USER_TOKEN_KEY = "user_token";

export const topBarType = Object.freeze({ APP_NAME: "APP_NAME", TITLE: "TITLE" });

export const userRoles = Object.freeze({
    ADMIN: "ADMIN",
    SUPPLY_CHAIN: "SUPPLY_CHAIN",
    GMP_DOC: "GMP_DOC",
    LOGISTIC: "LOGISTIC",
    SUPER_LOGISTIC: "SUPER_LOGISTIC",
    QA: "QA",
    QC: "QC",
    PDTS: "PDTS"
});

export const API_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const API_SHORT_DATE_FORMAT = "yyyy-MM-dd";

export const TOP_HEIGHT = 10.5;

export const campaigns = Object.freeze({
    PROCESS: "PROCESS",
    FORM: "FORM",
    ALBUMINA: "Albumina"
});

export const CALENDAR_DAYS = 7;
export const SESSION_WEEK_START = "SCHEDULING_BOARD_WEEK_START";
