import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CommonButton } from "../../../components/commonButton";
import Input from "../../../components/input";
import MultiSelect from "../../../components/multiSelect";
import Popup from "../../../components/popup";
import Select from "../../../components/select";
import Spinner from "../../../components/spinner";
import TextArea from "../../../components/textArea";
import { BRIDGE_RECORDS, BRIDGE_RECORDS_ID } from "../../../utils/requests";

const BridgeRecordsPopup = ({
    isNew,
    bridgeRecord,
    onClose,
    onDelete,
    onSave,
    availableBatchRecords,
    availableCampaigns,
    request = async () => {}
}) => {
    const { t } = useTranslation();
    const [isSaving, setIsSaving] = useState(false);
    const [campaigns, setCampaigns] = useState(availableCampaigns.sort());
    const [editBridgeRecord, setEditBridgeRecord] = useState({
        batchRecordId: bridgeRecord ? bridgeRecord.batchRecordId : null,
        campagna: bridgeRecord ? bridgeRecord.campagna : null,
        tipoPlasma: bridgeRecord ? bridgeRecord.tipoPlasma : [],
        descrizione: bridgeRecord ? bridgeRecord.descrizione : null,
        stepInizio: bridgeRecord ? bridgeRecord.stepInizio : null,
        stepFine: bridgeRecord ? bridgeRecord.stepFine : null,
        item: bridgeRecord ? bridgeRecord.item : null
    });

    return (
        <Popup>
            {isSaving ? (
                <Spinner />
            ) : (
                <>
                    <div className="w-full mb-12 flex flex-col items-center">
                        <div className={"text-red-600 font-bold text-2xl mb-4"}>
                            {(isNew ? t("creation") : t("edit")) + " Bridge Record"}
                        </div>

                        <div className="w-full mb-4">
                            <Select
                                title={t("batchRecord")}
                                data={availableBatchRecords}
                                filter={(obj, search) => obj.toLowerCase().includes(search.toLowerCase())}
                                placeholder={t("selectPlaceholder")}
                                value={editBridgeRecord.batchRecordId}
                                onChange={(value) => {
                                    setEditBridgeRecord((prev) => ({ ...prev, batchRecordId: value }));
                                }}
                                disabled={isSaving}
                            />
                        </div>
                        <div className="flex items-center justify-center mb-4">
                            <div className="w-52 mr-4">
                                <MultiSelect
                                    title={t("plasmaType")}
                                    placeholder={t("selectPlaceholder")}
                                    values={editBridgeRecord.tipoPlasma}
                                    data={[t("all"), "IT", "EU", "IT REC.", "EU REC.", "US", t("other")]}
                                    onChange={(values) => {
                                        if (values.includes(t("other")) && values[values.length - 1] === t("other"))
                                            setEditBridgeRecord((prev) => ({ ...prev, tipoPlasma: [t("other")] }));
                                        else if (values.includes(t("all")) && values[values.length - 1] === t("all"))
                                            setEditBridgeRecord((prev) => ({ ...prev, tipoPlasma: [t("all")] }));
                                        else
                                            setEditBridgeRecord((prev) => ({
                                                ...prev,
                                                tipoPlasma: values.filter((v) => v !== t("other"))
                                            }));
                                    }}
                                    disabled={isSaving}
                                />
                            </div>
                            <div className="w-52 ml-4">
                                <Select
                                    title={t("campaign")}
                                    data={campaigns}
                                    placeholder={t("selectPlaceholder")}
                                    value={editBridgeRecord.campagna}
                                    filter={(obj, search) => obj.toLowerCase().includes(search.toLowerCase())}
                                    allowAdditions={true}
                                    onAdd={(newCampaign) => {
                                        setCampaigns((prev) => [newCampaign, ...prev].sort());
                                        setEditBridgeRecord((prev) => ({ ...prev, campagna: newCampaign }));
                                    }}
                                    onChange={(value) => {
                                        setEditBridgeRecord((prev) => ({ ...prev, campagna: value }));
                                    }}
                                    disabled={isSaving}
                                />
                            </div>
                        </div>
                        <div className="flex items-center justify-center mb-4">
                            <div className="w-52 mr-4">
                                <Input
                                    title={t("initialStep")}
                                    placeholder={"--"}
                                    value={editBridgeRecord.stepInizio}
                                    onChange={(value) => {
                                        setEditBridgeRecord((prev) => ({ ...prev, stepInizio: value }));
                                    }}
                                    disabled={isSaving}
                                />
                            </div>
                            <div className="w-52 ml-4">
                                <Input
                                    title={t("finalStep")}
                                    placeholder={"--"}
                                    value={editBridgeRecord.stepFine}
                                    onChange={(value) => {
                                        setEditBridgeRecord((prev) => ({ ...prev, stepFine: value }));
                                    }}
                                    disabled={isSaving}
                                />
                            </div>
                        </div>
                        <div className="flex items-center mb-4">
                            <Input
                                title={t("item")}
                                placeholder={"--"}
                                value={editBridgeRecord.item}
                                onChange={(value) => {
                                    setEditBridgeRecord((prev) => ({ ...prev, item: value }));
                                }}
                                disabled={isSaving}
                            />
                        </div>
                        <div className="min-w-30rem w-2/4">
                            <TextArea
                                minHeight="8rem"
                                title={t("description")}
                                placeholder={"--"}
                                value={editBridgeRecord.descrizione}
                                onChange={(value) => {
                                    setEditBridgeRecord((prev) => ({ ...prev, descrizione: value }));
                                }}
                            />
                        </div>
                    </div>
                    <div className="w-full flex items-center justify-between">
                        <div className="w-28">
                            {!isNew && (
                                <CommonButton
                                    className="text-red-600 border-red-600 p-2"
                                    onClick={async () => {
                                        setIsSaving(true);

                                        const response = await request(BRIDGE_RECORDS_ID, {
                                            method: "DELETE",
                                            ignoreResponse: true,
                                            params: {
                                                id: bridgeRecord.id
                                            }
                                        });

                                        if (!response.ok) {
                                            if (response.status !== 401) {
                                                setIsSaving(false);
                                            }
                                            alert(t(response.errorMessage) + " " + response.response);
                                            return;
                                        }

                                        onDelete();

                                        setIsSaving(false);
                                    }}
                                >
                                    {t("delete")}
                                </CommonButton>
                            )}
                        </div>
                        <div className="flex">
                            <div className="w-28 mr-1">
                                <CommonButton className="text-gray-600 p-2" onClick={onClose}>
                                    {t("back")}
                                </CommonButton>
                            </div>
                            <div className="w-28 ml-1">
                                <CommonButton
                                    className="text-green-600 border-green-600 p-2"
                                    onClick={async () => {
                                        setIsSaving(true);

                                        let response = null;

                                        let index = editBridgeRecord.tipoPlasma.indexOf(t("other"));

                                        if (index !== -1) editBridgeRecord.tipoPlasma[index] = "ALTRO";

                                        index = editBridgeRecord.tipoPlasma.indexOf(t("all"));

                                        if (index !== -1) editBridgeRecord.tipoPlasma[index] = "TUTTI";

                                        if (isNew) {
                                            response = await request(BRIDGE_RECORDS, {
                                                method: "POST",
                                                body: JSON.stringify(editBridgeRecord)
                                            });
                                        } else {
                                            response = await request(BRIDGE_RECORDS_ID, {
                                                method: "PUT",
                                                body: JSON.stringify(editBridgeRecord),
                                                params: {
                                                    id: bridgeRecord.id
                                                }
                                            });
                                        }

                                        if (!response.ok) {
                                            if (response.status !== 401) {
                                                setIsSaving(false);
                                            }
                                            alert(t(response.errorMessage));
                                            return;
                                        }

                                        if (isNew) {
                                            onSave({
                                                ...editBridgeRecord,
                                                id: response.data.id,
                                                tipoPlasma: editBridgeRecord.tipoPlasma.includes("ALTRO")
                                                    ? [t("other")]
                                                    : editBridgeRecord.tipoPlasma.includes("TUTTI")
                                                    ? [t("all")]
                                                    : editBridgeRecord.tipoPlasma
                                            });
                                        } else {
                                            onSave({
                                                ...editBridgeRecord,
                                                id: bridgeRecord.id,
                                                tipoPlasma: editBridgeRecord.tipoPlasma.includes("ALTRO")
                                                    ? [t("other")]
                                                    : editBridgeRecord.tipoPlasma.includes("TUTTI")
                                                    ? [t("all")]
                                                    : editBridgeRecord.tipoPlasma
                                            });
                                        }

                                        setIsSaving(false);
                                    }}
                                    enabled={
                                        editBridgeRecord.batchRecordId &&
                                        editBridgeRecord.tipoPlasma.length !== 0 &&
                                        editBridgeRecord.campagna &&
                                        editBridgeRecord.descrizione &&
                                        editBridgeRecord.stepInizio &&
                                        editBridgeRecord.stepFine &&
                                        editBridgeRecord.item
                                    }
                                >
                                    {t("save")}
                                </CommonButton>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Popup>
    );
};

export default BridgeRecordsPopup;
