import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CommonButton } from "../../../components/commonButton";
import Popup from "../../../components/popup";
import Select from "../../../components/select";
import { useRequest } from "../../../utils/requestManager";
import { CREATE_REVISION_EXCEPTION, DELETE_REVISION_EXCEPTION, REVISION_EXCEPTIONS } from "../../../utils/requests";

const RevisionExceptions = ({ eccezioneId, mutateRevision }) => {
    const { t } = useTranslation();

    const {
        data: exceptions,
        isLoading: isLoadingExceptions,
        mutate: mutateExceptions,
        request
    } = useRequest(REVISION_EXCEPTIONS, {
        initialState: []
    });

    const [isSavingException, setIsSavingException] = useState(false);
    const [showPopup, setShowPopup] = useState({
        show: false,
        exceptionId: null
    });

    return (
        <>
            <Select
                title={t("exception")}
                placeholder={t("noException")}
                data={exceptions}
                value={eccezioneId}
                onChange={(value) => {
                    mutateRevision((old) => ({
                        ...old,
                        eccezioneId: value
                    }));
                }}
                keyField="id"
                render={(obj) => obj.eccezione}
                isLoading={isLoadingExceptions || isSavingException}
                disabled={isLoadingExceptions || isSavingException}
                filter={(obj, searchValue) => {
                    return obj.eccezione.toLowerCase().includes(searchValue.toLowerCase());
                }}
                allowAdditions={true}
                canAddNew={(value) =>
                    !exceptions.some((t) => t.eccezione.trim().toLowerCase() === value.trim().toLowerCase())
                }
                onAdd={async (value) => {
                    setIsSavingException(true);

                    const response = await request(CREATE_REVISION_EXCEPTION, {
                        method: "POST",
                        params: {
                            eccezione: encodeURIComponent(value)
                        }
                    });

                    if (!response.ok) {
                        if (response.status !== 401) {
                            setIsSavingException(false);
                        }
                        alert(t(response.errorMessage));
                        return;
                    }

                    mutateExceptions((old) => [
                        ...old,
                        {
                            id: response.data.id,
                            eccezione: value
                        }
                    ]);
                    mutateRevision((old) => ({
                        ...old,
                        eccezioneId: response.data.id
                    }));

                    setIsSavingException(false);
                }}
                allowDeletion={true}
                onDelete={async (value) => {
                    setShowPopup({
                        show: true,
                        exceptionId: value
                    });
                }}
            />
            {showPopup.show && (
                <Popup>
                    <div className="font-bold text-red-600 text-xl mb-1">{t("irreversibleAction")}</div>
                    <div className="flex items-center justify-between w-full mt-3">
                        <div className="w-32">
                            <CommonButton
                                onClick={() => {
                                    setShowPopup({
                                        show: false,
                                        exceptionId: null
                                    });
                                }}
                                className="text-red-600 border-red-600"
                            >
                                {t("back")}
                            </CommonButton>
                        </div>
                        <div className="w-32">
                            <CommonButton
                                className="text-green-600 border-green-600"
                                onClick={async () => {
                                    setShowPopup({
                                        show: false,
                                        exceptionId: null
                                    });
                                    setIsSavingException(true);

                                    const response = await request(DELETE_REVISION_EXCEPTION, {
                                        method: "DELETE",
                                        ignoreResponse: true,
                                        params: {
                                            id: showPopup.exceptionId
                                        }
                                    });

                                    if (!response.ok) {
                                        if (response.status !== 401) {
                                            setIsSavingException(false);
                                        }
                                        alert(t(response.errorMessage));
                                        return;
                                    }

                                    mutateExceptions((old) => old.filter((e) => e.id !== showPopup.exceptionId));

                                    setIsSavingException(false);
                                }}
                            >
                                {t("confirm")}
                            </CommonButton>
                        </div>
                    </div>
                </Popup>
            )}
        </>
    );
};

export default RevisionExceptions;
