import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const getBase64File = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result.split(",", 2)[1]);
        };
        reader.onerror = (error) => reject(error);
    });
};

export const useQuery = () => {
    const location = useLocation();

    const query = useMemo(() => new URLSearchParams(location.search), [location]);

    return query;
};
