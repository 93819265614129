import { Link } from "react-router-dom";

const commonClassName = "w-full border-1 p-3 flex items-center justify-center rounded text-2xl shadow ";
const commonEnabledClassName = "hover:bg-gray-200 cursor-pointer border-gray-700";

export const CommonButton = ({ enabled = true, onClick = () => {}, children = null, className = null }) => {
    return (
        <div
            className={
                commonClassName +
                (className ? className + " " : "") +
                (enabled ? commonEnabledClassName : "bg-gray-300 border-gray-400 cursor-not-allowed")
            }
            onClick={() => {
                if (!enabled) {
                    return;
                }
                onClick();
            }}
        >
            {children}
        </div>
    );
};

export const CommonLink = ({ to, children = null, className = null }) => {
    return (
        <Link to={to} className={commonClassName + (className ? className + " " : "") + commonEnabledClassName}>
            {children}
        </Link>
    );
};
