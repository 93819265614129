import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { CommonButton } from "../../components/commonButton";
import Input from "../../components/input";
import PageHeader from "../../components/pageHeader";
import { ReactComponent as UserIcon } from "../../svgIcons/user-solid.svg";
import { PASSWORD_CHANGE } from "../../utils/requests";

const PasswordChange = ({ setShowLoader }) => {
    const { guid } = useParams();
    const { t } = useTranslation();
    const [password, setPassword] = useState(null);
    const [repeatPassword, setRepeatPassword] = useState(null);
    const history = useHistory();

    return (
        <div className="flex flex-col w-2/4 items-center justify-center min-w-30rem">
            <PageHeader title={t("passwordChange")} icon={UserIcon} />
            <div className="w-3/5 flex flex-col items-center justify-center mt-7">
                <div className="mt-5 w-full">
                    <Input
                        title="Password"
                        placeholder="Password"
                        type="password"
                        value={password}
                        onChange={(value) => {
                            setPassword(value);
                        }}
                    />
                </div>
                <div className="mt-5 w-full">
                    <Input
                        title={t("repeatPassword")}
                        placeholder="Password"
                        type="password"
                        value={repeatPassword}
                        onChange={(value) => {
                            setRepeatPassword(value);
                        }}
                    />
                </div>
                <CommonButton
                    className="mt-7 mb-10 text-red-700"
                    enabled={password && password.trim().length > 0 && password === repeatPassword}
                    onClick={async () => {
                        setShowLoader(true);

                        const response = await fetch(PASSWORD_CHANGE, {
                            method: "PUT",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                guid,
                                password
                            })
                        });

                        if (!response.ok) {
                            alert(t("fetchError"));
                            setShowLoader(false);
                            return;
                        }

                        history.push("/");
                        setShowLoader(false);
                    }}
                >
                    {t("passwordChange")}
                </CommonButton>
            </div>
        </div>
    );
};

export default PasswordChange;
