import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CommonButton } from "../../../../components/commonButton";
import Input from "../../../../components/input";
import Popup from "../../../../components/popup";
import Select from "../../../../components/select";
import TextArea from "../../../../components/textArea";
import { userRoles } from "../../../../utils/constants";
import { UserContext } from "../../../../utils/contexts";
import { SAVE_SUPPLY_CHAIN_REVISION } from "../../../../utils/requests";
import PageDescription from "../../common/pageDescription";
import PageTitle from "../../common/pageTitle";
import RecapRow from "../../common/recapRow";
import RevisionExceptions from "../../common/revisionExceptions";

const SupplyChainForm = ({
    recordId,
    isAlbumina,
    lot,
    revision,
    description,
    noteLogistica,
    mutateRevision = () => {},
    onSave = () => {},
    editable = true,
    title = null,
    showTitle = true
}) => {
    const { t } = useTranslation();
    const { currentUser } = useContext(UserContext);

    const isLogistic = useMemo(
        () => [userRoles.LOGISTIC, userRoles.SUPER_LOGISTIC].includes(currentUser.role),
        [currentUser.role]
    );

    const [showConfirmPopup, setShowConfirmPopup] = useState(null);

    return (
        <>
            {showTitle && <PageTitle>{title || t(currentUser.role)}</PageTitle>}
            <PageDescription>{description}</PageDescription>
            <FormInputRow
                title={t("workOrder")}
                field="workOrder"
                maxLength={"7"}
                revision={revision}
                editable={editable}
                mutateRevision={mutateRevision}
            />
            {!isAlbumina && (
                <FormSelectRow
                    title={t("campaign")}
                    field="tipoCampagna"
                    revision={revision}
                    editable={editable}
                    mutateRevision={mutateRevision}
                    data={["NG1", "NG2", "NG3", "NG7", "NG9", "PW1", "CYN"]}
                />
            )}
            <FormSelectRow
                title={t("plasma")}
                field="plasmaProvenienza"
                revision={revision}
                editable={editable}
                mutateRevision={mutateRevision}
                data={[t("all"), "IT", "EU", "IT REC.", "EU REC.", "US", t("other")]}
            />
            {isAlbumina && (
                <>
                    <FormInputRow
                        title={t("fractionV")}
                        field="frazioniV"
                        revision={revision}
                        editable={editable}
                        mutateRevision={mutateRevision}
                    />
                    {/*<FormInputRow
                        title={t("fractionVLims")}
                        field="frazioneVLims"
                        revision={revision}
                        editable={editable}
                        mutateRevision={mutateRevision}
                    />*/}
                    <FormInputRow
                        title={t("itemNumber")}
                        field="itemNumber"
                        revision={revision}
                        editable={editable}
                        mutateRevision={mutateRevision}
                        maxLength={"7"}
                    />
                </>
            )}
            {!isAlbumina && (
                <>
                    <FormInputRow
                        title={t("formItem")}
                        field="item"
                        revision={revision}
                        editable={editable}
                        mutateRevision={mutateRevision}
                        maxLength={"7"}
                    />
                    <FormInputRow
                        title={t("lotSerial")}
                        field="lotSerial"
                        revision={revision}
                        editable={editable}
                        mutateRevision={mutateRevision}
                        maxLength={"7"}
                    />
                    <FormSelectRow
                        title={t("storageOrDisposalInfo")}
                        field="infoStoccaggio"
                        revision={revision}
                        editable={editable}
                        mutateRevision={mutateRevision}
                        data={[t("storage"), t("disposal")]}
                    />
                </>
            )}
            {editable && (
                <div className="min-w-30rem w-2/4 mt-5">
                    <RevisionExceptions eccezioneId={revision.eccezioneId} mutateRevision={mutateRevision} />
                </div>
            )}
            {!editable && !isLogistic && (
                <RecapRow
                    title={t("exception")}
                    value={revision.eccezione && revision.eccezione.trim().length > 0 ? revision.eccezione : "--"}
                />
            )}
            {editable && (
                <div className="min-w-30rem w-2/4 mt-5">
                    <TextArea
                        minHeight="8rem"
                        title={t("notes")}
                        placeholder={t("notesPlaceholder")}
                        value={revision.nota}
                        onChange={(value) => {
                            mutateRevision((old) => ({
                                ...old,
                                nota: value
                            }));
                        }}
                    />
                </div>
            )}
            {!editable && (
                <RecapRow
                    title={t("notes")}
                    value={revision.nota && revision.nota.trim().length > 0 ? revision.nota : "--"}
                />
            )}
            {editable && (
                <RecapRow
                    title={t("logisticNotes")}
                    value={noteLogistica && noteLogistica.trim().length > 0 ? noteLogistica : "--"}
                />
            )}
            <div className="w-full flex justify-end mt-4 mb-8 pr-6">
                {editable && (
                    <div className="w-32">
                        <CommonButton
                            className="text-red-600 border-2"
                            onClick={() => {
                                setShowConfirmPopup(true);
                            }}
                            enabled={
                                revision &&
                                (!revision.workOrder ||
                                    revision.workOrder.trim() === "" ||
                                    revision.workOrder.length === 7) &&
                                (isAlbumina
                                    ? !revision.itemNumber ||
                                      revision.itemNumber.trim() === "" ||
                                      revision.itemNumber.length === 7
                                    : (!revision.item || revision.item.trim() === "" || revision.item.length === 7) &&
                                      (!revision.lotSerial ||
                                          revision.lotSerial.trim() === "" ||
                                          revision.lotSerial.length === 7))
                            }
                        >
                            {t("save")}
                        </CommonButton>
                    </div>
                )}
            </div>
            {showConfirmPopup && (
                <Popup>
                    <div className="font-bold text-red-600 text-xl mb-1">{t("supplyChainRevisionConfirm")}</div>
                    <div className="font-bold text-xl">{lot}</div>
                    <div className="flex items-center justify-between w-full mt-3">
                        <div className="w-32">
                            <CommonButton
                                onClick={() => {
                                    setShowConfirmPopup(false);
                                }}
                                className="text-red-600 border-red-600"
                            >
                                {t("back")}
                            </CommonButton>
                        </div>
                        <div className="w-32">
                            <CommonButton
                                className="text-green-600 border-green-600"
                                onClick={async () => {
                                    setShowConfirmPopup(false);
                                    onSave(SAVE_SUPPLY_CHAIN_REVISION, {
                                        idRecord: recordId,
                                        nota: revision.nota,
                                        eccezioneId: revision.eccezioneId,
                                        item: revision.item,
                                        infoStoccaggio: revision.infoStoccaggio,
                                        lotSerial: revision.lotSerial,
                                        plasmaProvenienza: revision.plasmaProvenienza,
                                        tipoCampagna: revision.tipoCampagna,
                                        workOrder: revision.workOrder,
                                        frazioniV: revision.frazioniV,
                                        frazioneVLims: revision.frazioneVLims,
                                        itemNumber: revision.itemNumber
                                    });
                                }}
                            >
                                {t("confirm")}
                            </CommonButton>
                        </div>
                    </div>
                </Popup>
            )}
        </>
    );
};

const FormInputRow = ({ title, revision, field, mutateRevision, editable, maxLength = null }) => {
    if (editable) {
        return (
            <div className="min-w-30rem w-2/4 mt-5 first:mt-10">
                <Input
                    title={title}
                    placeholder={title}
                    value={revision[field]}
                    maxLength={maxLength}
                    onChange={(value) => {
                        mutateRevision((old) => ({
                            ...old,
                            [field]: value
                        }));
                    }}
                />
            </div>
        );
    }

    return (
        <RecapRow title={title} value={revision[field] && revision[field].trim().length > 0 ? revision[field] : "--"} />
    );
};

const FormSelectRow = ({ title, revision, field, mutateRevision, data, editable }) => {
    const { t } = useTranslation();

    if (editable) {
        return (
            <div className="min-w-30rem w-2/4 mt-5 first:mt-10">
                <Select
                    title={title}
                    placeholder={t("selectPlaceholder")}
                    filter={(obj, search) => obj.toLowerCase().includes(search.toLowerCase())}
                    value={revision[field]}
                    data={data}
                    onChange={(value) => {
                        mutateRevision((old) => ({
                            ...old,
                            [field]: value
                        }));
                    }}
                />
            </div>
        );
    }

    return (
        <RecapRow title={title} value={revision[field] && revision[field].trim().length > 0 ? revision[field] : "--"} />
    );
};

export default SupplyChainForm;
