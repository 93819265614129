import { useEffect, useRef } from "react";

const FileInput = ({
    value = null,
    className = "",
    multiple = false,
    onChange = () => {},
    children,
    disabled = false
}) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current && inputRef.current.files.length > 0 && value) {
            inputRef.current.value = "";
        }
    }, [value]);

    return (
        <>
            <input
                ref={inputRef}
                className={"hidden"}
                type="file"
                multiple={multiple}
                onChange={(e) => onChange(Array.from(e.target.files))}
                accept=".csv"
            />
            <div
                className={className}
                onClick={() => {
                    if (disabled) {
                        return;
                    }
                    inputRef.current.click();
                }}
            >
                {children}
            </div>
        </>
    );
};

export default FileInput;
