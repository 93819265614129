import { statusColors } from "./dataRequest_v2";
import { ReactComponent as EditIcon } from "../../svgIcons/edit-regular.svg";
import Overlay from "./overlay";
import { useTranslation } from "react-i18next";

export const HeaderCell = ({ children, className }) => (
    <th className={"p-1" + (className ? " " + className : "")}>{children}</th>
);

export const BodyCell = ({ status, onClick = () => {}, editable }) => (
    <td className="p-2 border-2">
        <div className="flex items-center justify-center w-full h-full">
            <div
                className={
                    "w-32 h-20 flex items-center justify-center rounded-sm" +
                    (editable ? " cursor-pointer hover:bg-opacity-80 " : " ") +
                    (status ? statusColors[status] : statusColors.DaImpostare)
                }
                onClick={(e) => {
                    if (!editable) {
                        return;
                    }
                    onClick(e);
                }}
            >
                {editable && <EditIcon className="w-8 h-8 animate-pulse text-gray-800" />}
            </div>
        </div>
    </td>
);

export const LegendRow = ({ children, colorClassName }) => (
    <div className="flex items-center">
        <div className={"h-5 w-8 mr-3 " + colorClassName} />
        <div>{children}</div>
    </div>
);

export const Button = ({
    children,
    onClick = () => {},
    enabled = true,
    className = "",
    enabledClassName = "",
    disabledClassName = ""
}) => (
    <div
        className={
            "p-3 font-bold text-xl flex items-center justify-center border-2 shadow rounded-md " +
            className +
            (enabled
                ? " hover:bg-gray-100 cursor-pointer " + enabledClassName
                : " cursor-default bg-gray-100 " + disabledClassName)
        }
        onClick={() => {
            if (!enabled) {
                return;
            }
            onClick();
        }}
    >
        {children}
    </div>
);

export const SavePopup = ({ onClose = () => {}, onConfirm = () => {} }) => {
    const { t } = useTranslation();

    return (
        <Overlay>
            <div className="rounded-md border-2 border-gray-500 bg-white py-3 px-5 max-h-70vh overflow-auto">
                <div className="flex flex-col items-center justify-center">
                    <div className="font-bold text-red-600 text-xl mb-1">{t("irreversibleAction")}</div>
                    <div className="flex items-center justify-between w-full mt-3">
                        <Button onClick={onClose} className="text-red-600 border-red-600 w-32">
                            {t("back")}
                        </Button>
                        <Button
                            className="w-32"
                            enabledClassName="text-green-600 border-green-600"
                            disabledClassName="border-gray-500 text-gray-500"
                            onClick={() => {
                                onConfirm();
                            }}
                        >
                            {t("confirm")}
                        </Button>
                    </div>
                </div>
            </div>
        </Overlay>
    );
};

export const ImportCompletePopup = ({ lottiScartati = [], lottiConErrori = [], onConfirm = () => {} }) => {
    const { t } = useTranslation();

    return (
        <Overlay>
            <div className="rounded-md border-2 border-gray-500 bg-white py-3 px-5 max-h-70vh overflow-auto">
                <div className="flex flex-col items-center justify-center">
                    <div className="font-bold text-green-600 text-xl mb-3">{t("importComplete")}</div>
                    {lottiScartati.length > 0 && (
                        <>
                            <div className="text-gray-600 mb-1">{t("discardedLots")}</div>
                            <div className="text-gray-600 font-bold">
                                {lottiScartati.map((l) => (
                                    <div className="mb-0.5" key={l}>
                                        {l}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    {lottiConErrori.length > 0 && (
                        <>
                            <div className="text-gray-600 mb-1">{t("lotsWithErrors")}</div>
                            <div className="text-gray-600 font-bold">
                                {lottiConErrori.map((l) => (
                                    <div className="mb-0.5" key={l}>
                                        {l}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    <div className="flex items-center justify-end w-full mt-3">
                        <Button
                            className="w-32"
                            enabledClassName="text-green-600 border-green-600"
                            disabledClassName="border-gray-500 text-gray-500"
                            onClick={() => {
                                onConfirm();
                            }}
                        >
                            {t("confirm")}
                        </Button>
                    </div>
                </div>
            </div>
        </Overlay>
    );
};
