import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CalendarInput from "../../components/calendarInput";
import Popup from "../../components/popup";
import Select from "../../components/select";
import { API_SHORT_DATE_FORMAT, campaigns } from "../../utils/constants";
import { ReactComponent as ArrowRight } from "../../svgIcons/arrow-right-solid.svg";
import Switch from "../../components/switch";
import Input from "../../components/input";
import TextArea from "../../components/textArea";
import { CommonButton } from "../../components/commonButton";
import Spinner from "../../components/spinner";
import { DELETE_GMP_REVISION, GMP_REVISIONS } from "../../utils/requests";
import { format } from "date-fns";

const typeArray = Object.freeze(Object.values(campaigns));

const EditPopup = ({
    revision,
    batchRecords,
    onClose = () => {},
    request = async () => {},
    onDelete = () => {},
    onSave = () => {}
}) => {
    const { t } = useTranslation();
    const [type, setType] = useState(() => {
        if (!revision) {
            return null;
        }
        const index = batchRecords.findIndex(
            (br) => br.id === revision.idOggettoRevisione || br.forms.some((f) => f.id === revision.idOggettoRevisione)
        );
        if (batchRecords[index].id === revision.idOggettoRevisione) {
            if (batchRecords[index].campagne.includes(campaigns.ALBUMINA)) {
                return campaigns.ALBUMINA;
            }
            return campaigns.PROCESS;
        }
        return campaigns.FORM;
    });

    const [batchRecord, setBatchRecord] = useState(() => {
        if (!type) {
            return null;
        }
        if (type === campaigns.FORM) {
            return batchRecords.find((br) => br.forms.some((f) => f.id === revision.idOggettoRevisione)).id;
        }
        return revision.idOggettoRevisione;
    });

    const [form, setForm] = useState(() => {
        if (type !== campaigns.FORM) {
            return null;
        }
        return revision.idOggettoRevisione;
    });

    const [validityStart, setValidityStart] = useState(revision ? revision.inizioValidita : new Date());

    const [automaticUrlUpdate, setAutomaticUrlUpdate] = useState(true);

    const [link, setLink] = useState(revision ? revision.link : null);

    const [description, setDescription] = useState(revision ? revision.descrizione : null);

    const revisionRecord = useMemo(() => {
        if (!type) {
            return;
        }
        if (type === campaigns.FORM) {
            if (!form) {
                return null;
            }
            return batchRecords.find((br) => br.forms.some((f) => f.id === form)).forms.find((f) => f.id === form);
        }
        return batchRecords.find((br) => br.id === batchRecord);
    }, [batchRecord, batchRecords, form, type]);

    useEffect(() => {
        setDescription(revisionRecord ? revisionRecord.descrizione : null);
    }, [revisionRecord]);

    const newLink = useMemo(() => {
        if (automaticUrlUpdate && link) {
            if (!revisionRecord) {
                return link;
            }
            try {
                const linkArr = link.split("/");
                const version = linkArr.slice(-2, -1)[0];
                if (!version) {
                    return link;
                }
                return (
                    linkArr.slice(0, -2).join("/") + "/" + (revisionRecord.versione + 1) + "/" + linkArr.slice(-1)[0]
                );
            } catch (error) {
                console.log(error);
                return link;
            }
        }
    }, [automaticUrlUpdate, link, revisionRecord]);

    useEffect(() => {
        if (!revisionRecord) {
            setLink(null);
        }
        if (revisionRecord && revisionRecord.id !== revision?.idOggettoRevisione) {
            setLink(revisionRecord.link);
        }
    }, [revision?.idOggettoRevisione, revisionRecord]);

    const [isSaving, setIsSaving] = useState(false);

    return (
        <Popup>
            {isSaving && <Spinner />}
            {!isSaving && (
                <>
                    <div className="w-full mb-12 flex flex-col items-center">
                        {!isSaving && <></>}
                        <div className={"text-red-600 font-bold text-2xl mb-4"}>{t("edit")}</div>
                        <div className="flex items-center justify-center mb-4">
                            <div className="w-52 mr-4">
                                <CalendarInput
                                    title={t("validityStart")}
                                    placeholder={t("selectPlaceholder")}
                                    value={validityStart}
                                    onChange={(value) => {
                                        if (!value) {
                                            return;
                                        }
                                        setValidityStart(value);
                                    }}
                                />
                            </div>
                            <div className="w-52 ml-4">
                                <Select
                                    title={t("type")}
                                    placeholder={t("selectPlaceholder")}
                                    data={typeArray}
                                    render={(obj) => t(obj)}
                                    value={type}
                                    onChange={(value) => {
                                        if (!value) {
                                            return;
                                        }
                                        setType(value);
                                        setBatchRecord(null);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex items-center justify-center mb-4">
                            <div className="mr-4 w-52">
                                <Select
                                    title={t("batchRecord")}
                                    placeholder={t("selectPlaceholder")}
                                    keyField={"id"}
                                    data={
                                        type === campaigns.ALBUMINA
                                            ? batchRecords.filter((br) => br.campagne.includes(campaigns.ALBUMINA))
                                            : batchRecords
                                    }
                                    render={(br) => br.id}
                                    value={batchRecord}
                                    onChange={(value) => {
                                        if (!value) {
                                            return;
                                        }
                                        setBatchRecord(value);
                                        setForm(null);
                                    }}
                                    filter={(br, search) => br.id.toLowerCase().includes(search)}
                                    disabled={!type}
                                />
                            </div>
                            <div className="ml-4 w-52">
                                <Select
                                    title={t("form")}
                                    placeholder={t("selectPlaceholder")}
                                    keyField={"id"}
                                    data={batchRecord ? batchRecords.find((br) => br.id === batchRecord).forms : []}
                                    render={(f) => f.id}
                                    value={form}
                                    onChange={(value) => {
                                        if (!value) {
                                            return;
                                        }
                                        setForm(value);
                                    }}
                                    filter={(f, search) => f.id.toLowerCase().includes(search)}
                                    disabled={type !== campaigns.FORM || !batchRecord}
                                />
                            </div>
                        </div>
                        <div className="flex items-center w-full mb-4">
                            <div className="font-medium text-base mr-6">{t("version")}</div>
                            <div className="flex items-center">
                                <div className="font-bold text-xl">
                                    {revisionRecord ? revisionRecord.versione : "--"}
                                </div>
                                <ArrowRight className="w-5 h-5 text-green-600 mr-7 ml-7" />
                                <div className="font-bold text-xl">
                                    {revisionRecord ? revisionRecord.versione + 1 : "--"}
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center w-full mb-1">
                            <div className="font-medium text-base mr-4">{t("automaticUrlUpdate")}</div>
                            <Switch value={automaticUrlUpdate} onChange={(value) => setAutomaticUrlUpdate(value)} />
                        </div>
                        <div className="w-full mb-4">
                            <Input
                                title={t("link")}
                                placeholder={"--"}
                                value={automaticUrlUpdate ? newLink : link}
                                onChange={(value) => {
                                    setLink(value);
                                }}
                                disabled={automaticUrlUpdate}
                            />
                        </div>
                        <div className="min-w-30rem w-2/4">
                            <TextArea
                                minHeight="8rem"
                                title={t("description")}
                                placeholder={"--"}
                                value={description}
                                onChange={(value) => {
                                    setDescription(value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="w-full flex items-center justify-between">
                        <div className="w-28">
                            {revision && (
                                <CommonButton
                                    className="text-red-600 border-red-600 p-2"
                                    onClick={async () => {
                                        setIsSaving(true);

                                        const response = await request(DELETE_GMP_REVISION, {
                                            method: "DELETE",
                                            ignoreResponse: true,
                                            params: {
                                                id: revision.idRevisione
                                            }
                                        });

                                        if (!response.ok) {
                                            if (response.status !== 401) {
                                                setIsSaving(false);
                                            }
                                            alert(t(response.errorMessage));
                                            return;
                                        }

                                        onDelete();

                                        setIsSaving(false);
                                    }}
                                >
                                    {t("delete")}
                                </CommonButton>
                            )}
                        </div>
                        <div className="flex">
                            <div className="w-28 mr-1">
                                <CommonButton className="text-gray-600 p-2" onClick={onClose}>
                                    {t("back")}
                                </CommonButton>
                            </div>
                            <div className="w-28 ml-1">
                                <CommonButton
                                    className="text-green-600 border-green-600 p-2"
                                    onClick={async () => {
                                        setIsSaving(true);

                                        let obj = {
                                            idOggettoRevisione: revisionRecord.id,
                                            inizioValidita: format(validityStart, API_SHORT_DATE_FORMAT),
                                            link: automaticUrlUpdate ? newLink : link,
                                            descrizione: description,
                                            versione: revisionRecord.versione + 1
                                        };

                                        if (revision) {
                                            obj.idRevisione = revision.idRevisione;
                                        }

                                        const response = await request(GMP_REVISIONS, {
                                            method: revision ? "PUT" : "POST",
                                            body: JSON.stringify(obj)
                                        });

                                        if (!response.ok) {
                                            if (response.status !== 401) {
                                                setIsSaving(false);
                                            }
                                            alert(t(response.errorMessage));
                                            return;
                                        }

                                        onSave({
                                            ...obj,
                                            idRevisione: response.data.id,
                                            inizioValidita: new Date(obj.inizioValidita)
                                        });

                                        setIsSaving(false);
                                    }}
                                    enabled={!!revisionRecord}
                                >
                                    {t("save")}
                                </CommonButton>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Popup>
    );
};

export default EditPopup;
