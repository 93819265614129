import { ReactComponent as SearchIcon } from "../svgIcons/search-solid.svg";
import { ReactComponent as DeleteIcon } from "../svgIcons/times-solid.svg";

const Input = ({
    value,
    onChange,
    title,
    placeholder,
    maxLength = null,
    searchIcon = false,
    bold = false,
    deleteIcon = false,
    type = null,
    disabled = false
}) => {
    return (
        <div className={"w-full h-full"}>
            <div className={"font-medium text-lg mb-0.5"}>{title}</div>
            <div
                className={
                    "flex items-center p-2 rounded-md border-1 shadow border-gray-400 " +
                    (!disabled ? "" : "cursor-not-allowed bg-gray-300")
                }
            >
                {searchIcon && (
                    <SearchIcon className={"h-3 w-3 mr-2 " + (!disabled ? "text-gray-300" : "text-gray-400")} />
                )}
                <input
                    type={type}
                    className={
                        "border-none w-full bg-transparent outline-none h-5 " +
                        (bold ? "font-bold " : "") +
                        (!disabled
                            ? "placeholder-gray-300 text-black"
                            : "placeholder-gray-400 text-gray-600 cursor-not-allowed")
                    }
                    value={value || ""}
                    onChange={(e) => {
                        onChange(e.target.value);
                    }}
                    maxLength={maxLength || null}
                    placeholder={placeholder}
                    readOnly={disabled}
                />
                {deleteIcon && value && value.length > 0 && (
                    <DeleteIcon
                        className={
                            "w-4 h-4 ml-2 " +
                            (!disabled ? "text-gray-300 cursor-pointer" : "text-gray-400 cursor-not-allowed")
                        }
                        onClick={() => {
                            if (disabled) {
                                return;
                            }
                            onChange("");
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default Input;
