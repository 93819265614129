import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { CommonButton } from "../../components/commonButton";
import Input from "../../components/input";
import PageHeader from "../../components/pageHeader";
import { PASSWORD_RECOVERY } from "../../utils/requests";
import { ReactComponent as UserIcon } from "../../svgIcons/user-solid.svg";
import { buildUrl } from "../../utils/requestManager";

const PasswordForgot = ({ setShowLoader }) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState(null);
    const history = useHistory();

    return (
        <div className="flex flex-col w-2/4 items-center justify-center min-w-30rem">
            <PageHeader title={t("passwordChange")} icon={UserIcon} />
            <div className="w-3/5 flex flex-col items-center justify-center mt-7">
                <div className="mt-5 w-full">
                    <Input
                        title="Email"
                        placeholder="Email"
                        value={email}
                        onChange={(value) => {
                            setEmail(value);
                        }}
                    />
                </div>
                <CommonButton
                    className="mt-7 mb-10 text-red-700"
                    enabled={email && email.trim().length > 0}
                    onClick={async () => {
                        setShowLoader(true);

                        const response = await fetch(
                            buildUrl(PASSWORD_RECOVERY, {
                                email
                            }),
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            }
                        );

                        if (!response.ok) {
                            alert(t("fetchError"));
                            setShowLoader(false);
                            return;
                        }

                        history.push("/");
                        setShowLoader(false);
                    }}
                >
                    {t("sendRecoveryMail")}
                </CommonButton>
            </div>
        </div>
    );
};

export default PasswordForgot;
