import { useTranslation } from "react-i18next";
import NavigationMenu from "../../components/navigationMenu";
import Spinner from "../../components/spinner";
import { useRequest } from "../../utils/requestManager";
import { BATCH_RECORDS, GMP_REVISIONS } from "../../utils/requests";
import { ReactComponent as EditIcon } from "../../svgIcons/edit-regular.svg";
import { ReactComponent as PlusIcon } from "../../svgIcons/plus-solid.svg";
import { format, isAfter, isBefore, parseISO } from "date-fns";
import { useState } from "react";
import EditPopup from "./editPopup";

const sortByDate = ({ inizioValidita: a }, { inizioValidita: b }) => {
    if (isAfter(a, b)) {
        return -1;
    }
    if (isBefore(a, b)) {
        return 1;
    }
    return 0;
};

const GmpDocRevisions = () => {
    const { t } = useTranslation();
    const {
        data: revisions,
        isLoading: isLoadingRevisions,
        mutate: mutateRevisions
    } = useRequest(GMP_REVISIONS, {
        transformData: (data) => {
            const result = data.map((r) => ({
                ...r,
                inizioValidita: parseISO(r.inizioValidita)
            }));
            result.sort(sortByDate);
            return result;
        }
    });

    const { data: batchRecords, isLoading: isLoadingBr, request } = useRequest(BATCH_RECORDS);

    const [editPopup, setEditPopup] = useState({
        show: false,
        revision: null
    });

    return (
        <>
            <div className="w-11/12 flex mb-12">
                {(isLoadingRevisions || isLoadingBr) && (
                    <div className="h-40 flex items-center justify-center w-full">
                        <Spinner />
                    </div>
                )}
                {!isLoadingRevisions && !isLoadingBr && (
                    <div className="w-full">
                        <div className="flex items-center mb-2">
                            <div className="text-2xl font-semibold mr-2">{t("revisions")}</div>
                            <div
                                className="border-1 shadow bg-green-700 hover:bg-green-600 p-1.5 cursor-pointer rounded-md"
                                onClick={() => {
                                    setEditPopup({
                                        show: true,
                                        revision: null
                                    });
                                }}
                            >
                                <PlusIcon className="w-4 h-4 text-white" />
                            </div>
                        </div>
                        <table className="w-full rounded-t-md text-sm text-center">
                            <thead className="text-xl">
                                <tr className="border-b-2">
                                    <th className="p-1 border-2">{t("revisionObject")}</th>
                                    <th className="p-1 border-2">{t("version")}</th>
                                    <th className="p-1 border-2">{t("validityStart")}</th>
                                    <th className="p-1 border-2">{t("description")}</th>
                                    <th className="p-1 border-2">{t("link")}</th>
                                    <th className="p-1 border-2">{t("edit")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {revisions.map((revision) => (
                                    <tr key={revision.idRevisione}>
                                        <td className="p-2 border-1 underline text-green-500 text-base whitespace-nowrap">
                                            {revision.idOggettoRevisione}
                                        </td>
                                        <td className="p-2 border-1">{revision.versione}</td>
                                        <td className="p-2 border-1">
                                            {format(revision.inizioValidita, t("shortDateFormat"))}
                                        </td>
                                        <td className="p-2 border-1">{revision.descrizione}</td>
                                        <td className="p-2 border-1 underline text-blue-600">
                                            <a href={revision.link} target="_blank" rel="noreferrer">
                                                {revision.link}
                                            </a>
                                        </td>
                                        <td className="p-2 border-1">
                                            <div className="flex items-center justify-center w-full h-full">
                                                <div
                                                    className="border-1 shadow bg-gray-300 hover:bg-gray-200 p-1.5 cursor-pointer rounded-md"
                                                    onClick={() => {
                                                        setEditPopup({
                                                            show: true,
                                                            revision
                                                        });
                                                    }}
                                                >
                                                    <EditIcon className="w-5 h-5 text-gray-700" />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                <NavigationMenu />
            </div>
            {editPopup.show && (
                <EditPopup
                    request={request}
                    revision={editPopup.revision}
                    batchRecords={batchRecords}
                    onClose={() => {
                        setEditPopup({
                            show: false,
                            revision: null
                        });
                    }}
                    onDelete={() => {
                        mutateRevisions((old) => old.filter((r) => r.idRevisione !== editPopup.revision.idRevisione));
                        setEditPopup({
                            show: false,
                            revision: null
                        });
                    }}
                    onSave={(revision) => {
                        if (editPopup.revision) {
                            mutateRevisions((old) => {
                                const index = old.findIndex((r) => r.idRevisione === editPopup.revision.idRevisione);
                                old[index] = revision;
                                old.sort(sortByDate);
                                return [...old];
                            });
                        } else {
                            mutateRevisions((old) => {
                                const result = [...old, revision];
                                result.sort(sortByDate);
                                return result;
                            });
                        }
                        setEditPopup({
                            show: false,
                            revision: null
                        });
                    }}
                />
            )}
        </>
    );
};

export default GmpDocRevisions;
