export const LOGIN = "/api/users/autentica";
export const CURRENT_USER = "/api/users/current";
export const LOTS = "/api/lotti?startDate={startDate}&endDate={endDate}";
export const PLANNING_DATA = "/api/pianificazioni/revisioni?startDate={startDate}&endDate={endDate}";
export const PLANNING_CHART_DATA = "/api/pianificazioni?startDate={startDate}&endDate={endDate}";
export const PLANNING_EXCEPTIONS = "/api/settings/eccezioniCalendario?startDate={startDate}&endDate={endDate}";
export const PLANNING_NOTE_LOGISTICA = "/api/pianificazioni/noteLogistica?fromDate={startDate}";
export const INSERT_PLANNING_NOTE_LOGISTICA = "/api/pianificazioni/noteLogistica";
export const EDIT_PLANNING_NOTE_LOGISTICA = "/api/pianificazioni/noteLogistica/{id}";
export const DELETE_PLANNING_RECORD = "/api/pianificazioni/{id}";
export const CREATE_PLANNING_DATA = "/api/pianificazioni/revisione";
export const EDIT_PLANNING_DATA = "/api/pianificazioni/revisioni";
export const APPLY_PLANNING_DATA = "/api/pianificazioni/revisioni/applica";
export const CREATE_PLANNING = "/api/pianificazioni";
export const DELETE_PLANNING = "/api/pianificazioni/revisioni";
export const DELETE_IMPORT = "/api/lotti/{idImportazione}";
export const SAVE_QC_QA_PSDT_REVISION = "/api/pdtsqcqa";
export const SAVE_SUPPLY_CHAIN_REVISION = "/api/supplyChain";
export const SAVE_GMP_DOC_REVISION = "/api/gmpDoc";
export const RECORD_PIANIFICATO = "/api/recordPianificati/{id}";
export const REVISION_EXCEPTIONS = "/api/eccezioni/revisione";
export const CREATE_REVISION_EXCEPTION = "/api/eccezioni/revisione?eccezione={eccezione}";
export const DELETE_REVISION_EXCEPTION = "/api/eccezioni/revisione/{id}";
export const GET_PRINT_VIEW = "/api/recordPianificati/stampa/{id}";
export const CHANGE_RECORD_STATUS = "/api/recordPianificati/stato/{id}?stato={stato}";
export const INCOMPLETE_FORM_NOTIFICATION = "/api/recordPianificati/notifica/{id}?ruolo={ruolo}";
export const SAVE_LOGISTIC_NOTES = "/api/logistica/note/{id}";
export const PASSWORD_CHANGE = "/api/users/password/cambia";
export const PASSWORD_RECOVERY = "/api/users/password/cambia/{email}";
export const SETTINGS = "/api/settings";
export const CALENDAR_EXCEPTIONS = "/api/eccezioni/calendario";
export const ADD_CALENDAR_EXCEPTION = "/api/eccezioni/calendario?eccezione={eccezione}";
export const DELETE_CALENDAR_EXCEPTION = "/api/eccezioni/calendario/{id}";
export const USERS = "/api/users";
export const DELETE_USER = "/api/users/{id}";
export const GMP_REVISIONS = "/api/pianificazioni/revisione/gmp";
export const GMP_REVISIONS_CHART = "/api/pianificazioni/revisione/gmp?startDate={startDate}&endDate={endDate}";
export const DELETE_GMP_REVISION = "/api/pianificazioni/revisione/gmp/{id}";
export const BATCH_RECORDS = "/api/batchRecords";
export const LEAD_TIME = "/api/settings/leadTime";
export const LOT_SHIFT = "/api/lotti/shift";
export const LOT_RESET = "/api/lotti/reset";
export const BATCH_RECORDS_ID = "api/batchrecords/{id}";
export const BRIDGE_RECORDS = "api/bridgerecords";
export const BRIDGE_RECORDS_ID = "api/bridgerecords/{id}";
export const FORMS = "api/forms";
export const FORMS_ID = "api/forms/{id}";
export const DELETE_LOT = "api/lotti/{anno}/{lotto}";
export const DOWNLOAD_LOGS = "api/log";
export const EXPORT_SCHEDULED_DATA = "/api/pianificazioni/export/excel?startDate={startDate}";
export const PROGRAMMA_STAMPA = "/api/programmaStampa?fromDate={fromDate}";
export const INSERT_PROGRAMMA_STAMPA = "/api/programmaStampa";
export const EDIT_PROGRAMMA_STAMPA = "/api/programmaStampa/{id}";
export const APPLY_PROGRAMMA_STAMPA = "/api/programmaStampa/applica";
export const PROGRAMMA_STAMPA_LAVORAZIONE = "/api/programmaStampa/inLavorazione";
export const EXPORT_STORICO_PROGRAMMA_STAMPA = "/api/programmaStampa/storico/export/excel/{id}";
export const EXPORT_PROGRAMMA_STAMPA_TODAY = "/api/programmaStampa/inLavorazione/export/excel";
