import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CommonButton } from "../../../../components/commonButton";
import Select from "../../../../components/select";
import TextArea from "../../../../components/textArea";
import { userRoles } from "../../../../utils/constants";
import { UserContext } from "../../../../utils/contexts";
import { SAVE_QC_QA_PSDT_REVISION } from "../../../../utils/requests";
import PageDescription from "../../common/pageDescription";
import PageTitle from "../../common/pageTitle";
import RecapRow from "../../common/recapRow";
import RevisionExceptions from "../../common/revisionExceptions";

const QcQaPdtsForm = ({
    recordId,
    revision,
    description,
    noteLogistica,
    mutateRevision = () => {},
    onSave = () => {},
    editable = true,
    title = null,
    showTitle = true
}) => {
    const { t } = useTranslation();
    const { currentUser } = useContext(UserContext);

    const selectData = useMemo(
        () => [
            {
                key: false,
                text: t("no")
            },
            {
                key: true,
                text: t("yes")
            }
        ],
        [t]
    );

    const isLogistic = useMemo(
        () => [userRoles.LOGISTIC, userRoles.SUPER_LOGISTIC].includes(currentUser.role),
        [currentUser.role]
    );

    return (
        <>
            {showTitle && <PageTitle>{title || t(currentUser.role)}</PageTitle>}
            <PageDescription>{description}</PageDescription>
            {editable && (
                <div className="w-48 mt-10">
                    <Select
                        title={t("deliveredForm")}
                        placeholder={t("all")}
                        data={selectData}
                        value={revision.formConsegnato || false}
                        onChange={(value) => {
                            if (value === null) {
                                return;
                            }
                            mutateRevision((old) => ({
                                ...old,
                                formConsegnato: value
                            }));
                        }}
                        keyField="key"
                        render={(obj) => obj.text}
                    />
                </div>
            )}
            {!editable && <RecapRow title={t("deliveredForm")} value={revision.formConsegnato ? t("yes") : t("no")} />}
            {editable && (
                <div className="min-w-30rem w-2/4 mt-5">
                    <RevisionExceptions eccezioneId={revision.eccezioneId} mutateRevision={mutateRevision} />
                </div>
            )}
            {!editable && !isLogistic && (
                <RecapRow
                    title={t("exception")}
                    value={revision.eccezione && revision.eccezione.trim().length > 0 ? revision.eccezione : "--"}
                />
            )}
            {editable && (
                <div className="min-w-30rem w-2/4 mt-5">
                    <TextArea
                        minHeight="8rem"
                        title={t("notes")}
                        placeholder={t("notesPlaceholder")}
                        value={revision.nota}
                        onChange={(value) => {
                            mutateRevision((old) => ({
                                ...old,
                                nota: value
                            }));
                        }}
                    />
                </div>
            )}
            {!editable && (
                <RecapRow
                    title={t("notes")}
                    value={revision.nota && revision.nota.trim().length > 0 ? revision.nota : "--"}
                />
            )}
            {editable && (
                <RecapRow
                    title={t("logisticNotes")}
                    value={noteLogistica && noteLogistica.trim().length > 0 ? noteLogistica : "--"}
                />
            )}
            <div className="w-full flex justify-end mt-4 mb-8 pr-6">
                {editable && (
                    <div className="w-32">
                        <CommonButton
                            className="text-red-600 border-2"
                            onClick={() => {
                                onSave(SAVE_QC_QA_PSDT_REVISION, {
                                    idRecord: recordId,
                                    nota: revision.nota,
                                    eccezioneId: revision.eccezioneId,
                                    formConsegnato: revision.formConsegnato || false
                                });
                            }}
                        >
                            {t("save")}
                        </CommonButton>
                    </div>
                )}
            </div>
        </>
    );
};

export default QcQaPdtsForm;
