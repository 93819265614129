import { ReactComponent as CaretRightIcon } from "../../../svgIcons/caret-right-solid.svg";

const PageDescription = ({ children }) => (
    <div className="flex text-green-500 text-2xl mt-8">
        <CaretRightIcon className="h-8 w-8" />
        <div>{children}</div>
    </div>
);

export default PageDescription;
