import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { USER_TOKEN_KEY } from "./constants";
import { AppContext } from "./contexts";

export const useRequest = (
    url,
    options = { initialState: undefined, refreshInterval: null, params: null, transformData: null }
) => {
    const { token, setToken } = useContext(AppContext);
    const [data, setData] = useState(options.initialState);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();

    const timeout = useRef(null);

    const fetchData = useCallback(
        async (token) => {
            if (!url) {
                setIsLoading(false);
                return;
            }

            setIsLoading(true);

            const response = await fetch(buildUrl(url, options.params), {
                headers: { Authorization: "Bearer " + token }
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem(USER_TOKEN_KEY);
                    setToken(null);
                    alert(t("sessionExpired"));
                    setError({
                        status: response.status,
                        message: "sessionExpired"
                    });
                } else {
                    setError({
                        status: response.status,
                        message: "fetchError"
                    });
                }
                setIsLoading(false);
                return;
            }

            const data = await response.json();

            setError(null);
            setData(options.transformData ? options.transformData(data) : data);
            setIsLoading(false);
        },
        [options, setToken, t, url]
    );

    const createTimeout = useCallback(() => {
        return setTimeout(async () => {
            await fetchData(token);
            timeout.current = createTimeout();
        }, options.refreshInterval);
    }, [fetchData, options.refreshInterval, token]);

    useEffect(() => {
        if (timeout.current) {
            clearTimeout(timeout.current);
            timeout.current = null;
        }

        fetchData(token).then(() => {
            if (options.refreshInterval && options.refreshInterval > 0) {
                timeout.current = createTimeout();
            }
        });

        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, options.params, token]);

    const request = useCallback(
        async (url, options) => {
            let headers = {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json"
            };
            if (options?.headers) {
                headers = {
                    ...headers,
                    ...options.headers
                };
            }
            const response = await fetch(buildUrl(url, options.params), {
                method: options?.method || "GET",
                headers,
                body: options.body
            });

            if (!response.ok) {
                let errorMessage;
                if (response.status === 401) {
                    localStorage.removeItem(USER_TOKEN_KEY);
                    setToken(null);
                    errorMessage = "sessionExpired";
                } else {
                    errorMessage = "fetchError";
                }
                return {
                    status: response.status,
                    ok: false,
                    errorMessage,
                    data: null,
                    response: response
                };
            }

            let result = {
                status: response.status,
                data: null,
                ok: true,
                errorMessage: null,
                response: response
            };

            if (!options?.ignoreResponse) {
                result.data = await response.json();
            }
            return result;
        },
        [setToken, token]
    );

    const reload = useCallback(() => {
        fetchData(token);
    }, [fetchData, token]);

    return { data, error, mutate: setData, isLoading, request, reload };
};

export const buildUrl = (url, params) => {
    let api = url;
    let matches = api.match(/{(\w+)}/g);

    if (matches !== null) {
        if (matches.length > 0 && !params) {
            throw "No param defined!";
        }
        matches.forEach((match) => {
            let paramName = match.replace(/[{}]/g, "");
            let param = params[paramName];
            if (param !== null) {
                api = api.replace(match, param);
            }
        });
    }

    return api;
};
