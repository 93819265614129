import { useContext } from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import { userRoles } from "../../utils/constants";
import { UserContext } from "../../utils/contexts";
import SchedulingBoard from "./chart/schedulingBoard";
import Print from "./revision/print";
import Revision from "./revision/revision";

const SchedulingBoardNavigator = () => {
    const { path } = useRouteMatch();
    const { currentUser } = useContext(UserContext);

    return (
        <Switch>
            {[userRoles.LOGISTIC, userRoles.SUPER_LOGISTIC].includes(currentUser.role) && (
                <Route path={`${path}/revision/:recordId/print`}>
                    <Print />
                </Route>
            )}
            <Route path={`${path}/revision/:recordId`}>
                <Revision />
            </Route>
            <Route path={path}>
                <SchedulingBoard />
            </Route>
        </Switch>
    );
};

export default SchedulingBoardNavigator;
