import { useTranslation } from "react-i18next";
import { useRequest } from "../../utils/requestManager";
import {
    EDIT_PROGRAMMA_STAMPA,
    EXPORT_STORICO_PROGRAMMA_STAMPA,
    INSERT_PROGRAMMA_STAMPA,
    PROGRAMMA_STAMPA
} from "../../utils/requests";
import { addMonths, format, isAfter, isBefore, parseISO, setSeconds } from "date-fns";
import Spinner from "../../components/spinner";
import { ReactComponent as PlusIcon } from "../../svgIcons/plus-solid.svg";
import { ReactComponent as EditIcon } from "../../svgIcons/edit-regular.svg";
import { ReactComponent as DownloadIcon } from "../../svgIcons/file-download-solid.svg";
import NavigationMenu from "../../components/navigationMenu";
import { API_DATE_FORMAT, API_SHORT_DATE_FORMAT, topBarType } from "../../utils/constants";
import { useContext, useEffect, useMemo, useState } from "react";
import Popup from "../../components/popup";
import { CommonButton } from "../../components/commonButton";
import { AppContext } from "../../utils/contexts";

const sortByDate = ({ dataInizio: a }, { dataInizio: b }) => {
    if (isAfter(a, b)) {
        return -1;
    }
    if (isBefore(a, b)) {
        return 1;
    }
    return 0;
};

const ProgrammaStampaGmpDoc = () => {
    const { setTopBarOptions } = useContext(AppContext);
    const { t } = useTranslation();

    useEffect(() => {
        setTopBarOptions({
            pageTitle: t("printProgram"),
            barType: topBarType.TITLE
        });
    }, [setTopBarOptions, t]);

    const params = useMemo(
        () => ({
            fromDate: format(addMonths(new Date(), -3), API_SHORT_DATE_FORMAT)
        }),
        []
    );

    const { data, isLoading, mutate, request } = useRequest(PROGRAMMA_STAMPA, {
        params,
        transformData: (data) => {
            const result = data.map((r) => ({
                ...r,
                dataInizio: parseISO(r.dataInizio),
                dataFine: parseISO(r.dataFine),
                dataUltimoRicalcolo: r.dataUltimoRicalcolo ? parseISO(r.dataUltimoRicalcolo) : null
            }));
            return result;
        }
    });

    const [editPopup, setEditPopup] = useState({
        show: false,
        programma: null
    });

    const [isSaving, setIsSaving] = useState(false);

    return (
        <>
            <div className="w-11/12 flex mb-12">
                {isLoading && (
                    <div className="h-40 flex items-center justify-center w-full">
                        <Spinner />
                    </div>
                )}
                {!isLoading && (
                    <div className="w-full">
                        <div className="flex items-center mb-2">
                            <div className="text-2xl font-semibold mr-2">{t("printProgram")}</div>
                            <div
                                className="border-1 shadow bg-green-700 hover:bg-green-600 p-1.5 cursor-pointer rounded-md"
                                onClick={() => {
                                    setEditPopup({
                                        show: true,
                                        programma: {}
                                    });
                                }}
                            >
                                <PlusIcon className="w-4 h-4 text-white" />
                            </div>
                        </div>
                        <table className="w-full rounded-t-md text-sm text-center">
                            <thead className="text-xl">
                                <tr className="border-b-2">
                                    <th className="p-1 border-2">{t("startDate")}</th>
                                    <th className="p-1 border-2">{t("endDate")}</th>
                                    <th className="p-1 border-2">{t("processDailyRecords")}</th>
                                    <th className="p-1 border-2">{t("albuminaDailyRecords")}</th>
                                    <th className="p-1 border-2">{t("recalculationDate")}</th>
                                    <th className="p-1 border-2">{t("edit")}</th>
                                    <th className="p-1 border-2">Export</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((programma) => (
                                    <tr key={programma.id}>
                                        <td className="p-2 border-1">
                                            {format(programma.dataInizio, t("dateNoSecondsFormat"))}
                                        </td>
                                        <td className="p-2 border-1">
                                            {format(programma.dataFine, t("dateNoSecondsFormat"))}
                                        </td>
                                        <td className="p-2 border-1">
                                            {programma.numeroRecordGiornalieriFrazionamento}
                                        </td>
                                        <td className="p-2 border-1">{programma.numeroRecordGiornalieriAlbumina}</td>
                                        <td className="p-2 border-1">
                                            {programma.dataUltimoRicalcolo
                                                ? format(programma.dataUltimoRicalcolo, t("chartDateFormat"))
                                                : "--"}
                                        </td>
                                        <td className="p-2 border-1">
                                            <div className="flex items-center justify-center w-full h-full">
                                                <div
                                                    className="border-1 shadow bg-gray-300 hover:bg-gray-200 p-1.5 cursor-pointer rounded-md"
                                                    onClick={() => {
                                                        setEditPopup({
                                                            show: true,
                                                            programma
                                                        });
                                                    }}
                                                >
                                                    <EditIcon className="w-5 h-5 text-gray-700" />
                                                </div>
                                            </div>
                                        </td>
                                        <td className="p-2 border-1">
                                            <div className="flex items-center justify-center w-full h-full">
                                                <div
                                                    className="border-1 shadow bg-gray-300 hover:bg-gray-200 p-1.5 cursor-pointer rounded-md"
                                                    onClick={async () => {
                                                        const response = await request(
                                                            EXPORT_STORICO_PROGRAMMA_STAMPA,
                                                            {
                                                                ignoreResponse: true,
                                                                params: {
                                                                    id: programma.id
                                                                }
                                                            }
                                                        );

                                                        const file = await response.response.blob();
                                                        const url = URL.createObjectURL(file);
                                                        window.open(url, "_blank");
                                                        URL.revokeObjectURL(url);
                                                    }}
                                                >
                                                    <DownloadIcon className="w-5 h-5 text-gray-700" />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                <NavigationMenu />
            </div>
            {editPopup.show && (
                <Popup>
                    {isSaving && <Spinner />}
                    {!isSaving && (
                        <div className="w-full">
                            <div className={"text-red-600 font-bold text-2xl mb-4"}>{t("edit")}</div>
                            <div className="mt-1 flex justify-evenly items-center">
                                <div className="mr-1">
                                    <div className={"font-medium text-base mb-1"}>{t("startDate")}</div>
                                    <input
                                        type="datetime-local"
                                        className="rounded-md border-1 p-2 shadow border-gray-400"
                                        value={
                                            editPopup.programma.dataInizio
                                                ? format(editPopup.programma.dataInizio, "yyyy-MM-dd'T'HH:mm")
                                                : ""
                                        }
                                        onChange={(e) => {
                                            setEditPopup({
                                                show: true,
                                                programma: {
                                                    ...editPopup.programma,
                                                    dataInizio: setSeconds(new Date(e.target.value), 0)
                                                }
                                            });
                                        }}
                                    />
                                </div>
                                <div className="ml-1">
                                    <div className={"font-medium text-base mb-1"}>{t("endDate")}</div>
                                    <input
                                        type="datetime-local"
                                        className="rounded-md border-1 p-2 shadow border-gray-400"
                                        value={
                                            editPopup.programma.dataFine
                                                ? format(editPopup.programma.dataFine, "yyyy-MM-dd'T'HH:mm")
                                                : ""
                                        }
                                        onChange={(e) => {
                                            setEditPopup({
                                                show: true,
                                                programma: {
                                                    ...editPopup.programma,
                                                    dataFine: setSeconds(new Date(e.target.value), 0)
                                                }
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="w-full flex items-center justify-between mt-10">
                                <div className="w-28">
                                    {editPopup.programma.id && (
                                        <CommonButton
                                            className="text-red-600 border-red-600 p-2"
                                            onClick={async () => {
                                                setIsSaving(true);

                                                const response = await request(EDIT_PROGRAMMA_STAMPA, {
                                                    method: "DELETE",
                                                    ignoreResponse: true,
                                                    params: {
                                                        id: editPopup.programma.id
                                                    }
                                                });

                                                if (!response.ok) {
                                                    if (response.status !== 401) {
                                                        setIsSaving(false);
                                                    }
                                                    alert(t(response.errorMessage));
                                                    return;
                                                }

                                                mutate((old) => old.filter((p) => p.id !== editPopup.programma.id));

                                                setIsSaving(false);
                                                setEditPopup({
                                                    show: false,
                                                    programma: null
                                                });
                                            }}
                                        >
                                            {t("delete")}
                                        </CommonButton>
                                    )}
                                </div>
                                <div className="flex ml-10">
                                    <div className="w-28 mr-1">
                                        <CommonButton
                                            className="text-gray-600 p-2"
                                            onClick={() => {
                                                setEditPopup({
                                                    show: false,
                                                    programma: null
                                                });
                                            }}
                                        >
                                            {t("back")}
                                        </CommonButton>
                                    </div>
                                    <div className="w-28 ml-1">
                                        <CommonButton
                                            className="text-green-600 border-green-600 p-2"
                                            onClick={async () => {
                                                setIsSaving(true);

                                                let obj = {
                                                    dataInizio: format(editPopup.programma.dataInizio, API_DATE_FORMAT),
                                                    dataFine: format(editPopup.programma.dataFine, API_DATE_FORMAT)
                                                };

                                                let url;
                                                let method;
                                                let params;

                                                if (editPopup.programma.id) {
                                                    params = {
                                                        id: editPopup.programma.id
                                                    };
                                                    url = EDIT_PROGRAMMA_STAMPA;
                                                    method = "PUT";
                                                } else {
                                                    url = INSERT_PROGRAMMA_STAMPA;
                                                    params = {};
                                                    method = "POST";
                                                }

                                                const response = await request(url, {
                                                    method,
                                                    params,
                                                    body: JSON.stringify(obj)
                                                });

                                                if (!response.ok) {
                                                    if (response.status === 409) {
                                                        alert(t("printProgramConflict"));
                                                        setIsSaving(false);
                                                        return;
                                                    }
                                                    if (response.status !== 401) {
                                                        setIsSaving(false);
                                                    }
                                                    alert(t(response.errorMessage));
                                                    return;
                                                }

                                                if (editPopup.programma.id) {
                                                    mutate((old) => {
                                                        const index = old.findIndex(
                                                            (p) => p.id === editPopup.programma.id
                                                        );
                                                        old[index] = editPopup.programma;
                                                        old[index].numeroRecordGiornalieriFrazionamento =
                                                            response.data.numeroRecordGiornalieriFrazionamento;
                                                        old[index].numeroRecordGiornalieriAlbumina =
                                                            response.data.numeroRecordGiornalieriAlbumina;
                                                        old.sort(sortByDate);
                                                        return [...old];
                                                    });
                                                } else {
                                                    mutate((old) => {
                                                        let newProgramma = editPopup.programma;
                                                        newProgramma.id = response.data.id;
                                                        newProgramma.numeroRecordGiornalieriFrazionamento =
                                                            response.data.numeroRecordGiornalieriFrazionamento;
                                                        newProgramma.numeroRecordGiornalieriAlbumina =
                                                            response.data.numeroRecordGiornalieriAlbumina;
                                                        const result = [...old, newProgramma].sort(sortByDate);
                                                        return result;
                                                    });
                                                }

                                                if (response.data.warning) {
                                                    alert(response.data.warning);
                                                }

                                                setIsSaving(false);
                                                setEditPopup({
                                                    show: false,
                                                    programma: null
                                                });
                                            }}
                                            enabled={editPopup.programma.dataInizio && editPopup.programma.dataFine}
                                        >
                                            {t("save")}
                                        </CommonButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Popup>
            )}
        </>
    );
};

export default ProgrammaStampaGmpDoc;
