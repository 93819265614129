import React, { useState } from "react";
import MultiSelect from "../../../components/multiSelect";
import Popup from "../../../components/popup";
import { useTranslation } from "react-i18next";
import Spinner from "../../../components/spinner";
import Input from "../../../components/input";
import CalendarInput from "../../../components/calendarInput";
import TextArea from "../../../components/textArea";
import { CommonButton } from "../../../components/commonButton";
import { FORMS, FORMS_ID } from "../../../utils/requests";
import { parseISO, format } from "date-fns";
import { API_SHORT_DATE_FORMAT } from "../../../utils/constants";

const FormsPopup = ({
    isNew,
    form,
    usedIds,
    onClose,
    onDelete,
    onSave,
    availableBatchRecords,
    request = async () => {}
}) => {
    const { t } = useTranslation();
    const [isSaving, setIsSaving] = useState(false);
    const [editForm, setEditForm] = useState({
        id: form ? form.id : null,
        descrizione: form ? form.descrizione : null,
        versione: form ? form.versione : null,
        link: form ? form.link : null,
        inizioValidita: form ? form.inizioValidita : null,
        batchRecordIds: form ? form.batchRecordIds : []
    });

    return (
        <Popup>
            {isSaving ? (
                <Spinner />
            ) : (
                <>
                    <div className="w-full mb-12 flex flex-col items-center">
                        <div className={"text-red-600 font-bold text-2xl mb-4"}>
                            {(isNew ? t("creation") : t("edit")) + " Form"}
                        </div>
                        <div className="w-full mb-4">
                            <Input
                                title={t("identifier")}
                                placeholder={"--"}
                                value={editForm.id}
                                onChange={(value) => {
                                    setEditForm((prev) => ({ ...prev, id: value }));
                                }}
                                disabled={!isNew || isSaving}
                            />
                        </div>
                        <div className="w-full mb-4">
                            <MultiSelect
                                title={t("batchRecords")}
                                placeholder={t("noBatchRecords")}
                                values={editForm.batchRecordIds}
                                data={availableBatchRecords}
                                filter={(obj, search) => obj.toLowerCase().includes(search.toLowerCase())}
                                disabled={isSaving}
                                onChange={(values) =>
                                    setEditForm((prev) => ({
                                        ...prev,
                                        batchRecordIds: values
                                    }))
                                }
                            />
                        </div>
                        <div className="flex items-center justify-center mb-4">
                            <div className="w-52 mr-4">
                                <CalendarInput
                                    title={t("validityStart")}
                                    placeholder={t("selectPlaceholder")}
                                    value={editForm.inizioValidita}
                                    onChange={(value) => {
                                        if (!value) {
                                            return;
                                        }
                                        setEditForm((prev) => ({ ...prev, inizioValidita: value }));
                                    }}
                                />
                            </div>
                            <div className="w-52 ml-4">
                                <Input
                                    title={t("version")}
                                    placeholder={"--"}
                                    value={editForm.versione}
                                    type={"number"}
                                    onChange={(value) => {
                                        setEditForm((prev) => ({ ...prev, versione: parseInt(value) }));
                                    }}
                                    disabled={isSaving}
                                />
                            </div>
                        </div>
                        <div className="w-full mb-4">
                            <Input
                                title={t("link")}
                                placeholder={"--"}
                                value={editForm.link}
                                onChange={(value) => {
                                    setEditForm((prev) => ({ ...prev, link: value }));
                                }}
                                disabled={isSaving}
                            />
                        </div>
                        <div className="min-w-30rem w-2/4">
                            <TextArea
                                minHeight="8rem"
                                title={t("description")}
                                placeholder={"--"}
                                value={editForm.descrizione}
                                onChange={(value) => {
                                    setEditForm((prev) => ({ ...prev, descrizione: value }));
                                }}
                            />
                        </div>
                    </div>
                    <div className="text-red-600 w-full font-bold text-lg mb-8 text-center">
                        {usedIds.includes(editForm.id) && isNew && t("usedId")}
                    </div>
                    <div className="w-full flex items-center justify-between">
                        <div className="w-28">
                            {!isNew && (
                                <CommonButton
                                    className="text-red-600 border-red-600 p-2"
                                    onClick={async () => {
                                        setIsSaving(true);

                                        const response = await request(FORMS_ID, {
                                            method: "DELETE",
                                            ignoreResponse: true,
                                            params: {
                                                id: editForm.id
                                            }
                                        });

                                        if (!response.ok) {
                                            if (response.status !== 401) {
                                                setIsSaving(false);
                                            }
                                            alert(t(response.errorMessage) + " " + response.response);
                                            return;
                                        }

                                        onDelete();

                                        setIsSaving(false);
                                    }}
                                >
                                    {t("delete")}
                                </CommonButton>
                            )}
                        </div>
                        <div className="flex">
                            <div className="w-28 mr-1">
                                <CommonButton className="text-gray-600 p-2" onClick={onClose}>
                                    {t("back")}
                                </CommonButton>
                            </div>
                            <div className="w-28 ml-1">
                                <CommonButton
                                    className="text-green-600 border-green-600 p-2"
                                    onClick={async () => {
                                        setIsSaving(true);
                                        let body = null;

                                        if (!isNew) {
                                            body = Object.keys(editForm)
                                                .filter((key) => key !== "id")
                                                .reduce((obj, key) => {
                                                    obj[key] = editForm[key];
                                                    return obj;
                                                }, {});
                                            body.inizioValidita = format(body.inizioValidita, API_SHORT_DATE_FORMAT);
                                        } else {
                                            body = JSON.parse(JSON.stringify(editForm));
                                            body.inizioValidita = format(
                                                parseISO(body.inizioValidita),
                                                API_SHORT_DATE_FORMAT
                                            );
                                        }

                                        let response = null;

                                        if (isNew) {
                                            response = await request(FORMS, {
                                                method: "POST",
                                                body: JSON.stringify(body)
                                            });
                                        } else {
                                            response = await request(FORMS_ID, {
                                                method: "PUT",
                                                body: JSON.stringify(body),
                                                params: {
                                                    id: editForm.id
                                                }
                                            });
                                        }

                                        if (!response.ok) {
                                            if (response.status !== 401) {
                                                setIsSaving(false);
                                            }
                                            alert(t(response.errorMessage));
                                            return;
                                        }

                                        onSave({
                                            ...editForm,
                                            inizioValidita: parseISO(body.inizioValidita)
                                        });

                                        setIsSaving(false);
                                    }}
                                    enabled={
                                        editForm.id &&
                                        editForm.inizioValidita &&
                                        editForm.link &&
                                        editForm.descrizione &&
                                        editForm.versione &&
                                        (!isNew || !usedIds.includes(editForm.id))
                                    }
                                >
                                    {t("save")}
                                </CommonButton>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Popup>
    );
};
export default FormsPopup;
