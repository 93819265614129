import React, { useState, useMemo } from "react";
import RegistryTab from "../registryTab";
import { registryObjs } from "../anagrafica";
import { ReactComponent as PlusIcon } from "../../../svgIcons/plus-solid.svg";
import { ReactComponent as EditIcon } from "../../../svgIcons/edit-regular.svg";
import { BRIDGE_RECORDS, BATCH_RECORDS } from "../../../utils/requests";
import { parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import Spinner from "../../../components/spinner";
import BridgeRecordsPopup from "./bridgeRecordsPopup";
import { useRequest } from "../../../utils/requestManager";
import Input from "../../../components/input";

const BridgeRecords = ({ handleChange }) => {
    const [showPopup, setShowPopup] = useState({ showing: false, new: false, bridgeRecordEdit: null });
    const [filterString, setFilterString] = useState("");

    const {
        data: bridgeRecords,
        isLoading: isLoadingBridgeRecords,
        mutate: mutateBridgeRecords,
        request
    } = useRequest(BRIDGE_RECORDS, {
        transformData: (data) => {
            const result = data.map((r) => ({
                ...r,
                inizioValidita: parseISO(r.inizioValidita),
                tipoPlasma: r.tipoPlasma.includes("ALTRO")
                    ? [t("other")]
                    : r.tipoPlasma.includes("TUTTI")
                    ? [t("all")]
                    : r.tipoPlasma
            }));
            return result;
        }
    });

    const { data: batchRecordIds, isLoading: isLoadingBatchRecords } = useRequest(BATCH_RECORDS, {
        transformData: (data) => data.map((r) => r.id)
    });

    const filteredData = useMemo(() => {
        if (filterString.trim() === "") return bridgeRecords;
        if (bridgeRecords)
            return bridgeRecords.filter(
                (item) =>
                    item.batchRecordId.toLowerCase().includes(filterString.toLowerCase()) ||
                    item.campagna.toLowerCase().includes(filterString.toLowerCase()) ||
                    item.stepInizio.toLowerCase().includes(filterString.toLowerCase()) ||
                    item.descrizione?.toLowerCase().includes(filterString.toLowerCase()) ||
                    item.item?.toLowerCase().includes(filterString.toLowerCase())
            );
        return null;
    }, [bridgeRecords, filterString]);

    const campaigns = useMemo(() => {
        if (!isLoadingBridgeRecords) return [...new Set(bridgeRecords.map((br) => br.campagna))];
    }, [bridgeRecords, isLoadingBridgeRecords]);

    const { t } = useTranslation();

    return (
        <>
            <div className="flex justify-center w-full items-center">
                <RegistryTab
                    tabsObjs={registryObjs}
                    onChange={handleChange}
                    currentLink={registryObjs[1].link}
                    disabled={isLoadingBatchRecords || isLoadingBridgeRecords}
                />
                <div
                    className={
                        "ml-5 mb-4 items-center border-1 shadow bg-green-700 hover:bg-green-600 p-1.5 cursor-pointer rounded-md " +
                        (isLoadingBatchRecords && isLoadingBridgeRecords
                            ? "cursor-not-allowed bg-gray-200"
                            : "cursor-pointer bg-green-700 hover:bg-green-600")
                    }
                    onClick={() => {
                        if (!isLoadingBatchRecords && !isLoadingBridgeRecords)
                            setShowPopup({ showing: true, new: true, bridgeRecordEdit: null });
                    }}
                >
                    <PlusIcon className="w-5 h-5 text-white" />
                </div>
            </div>
            <div className="w-11/12 flex mb-12 mt-4 flex-wrap">
                {isLoadingBatchRecords || isLoadingBridgeRecords ? (
                    <Spinner />
                ) : (
                    <>
                        <div className="w-1/4 mb-5">
                            <Input
                                title={t("search")}
                                placeholder={
                                    t("search") +
                                    " " +
                                    t("by") +
                                    " " +
                                    t("batchRecord") +
                                    ", " +
                                    t("campaign") +
                                    ", " +
                                    t("initialStep") +
                                    ", " +
                                    t("item") +
                                    " " +
                                    t("or") +
                                    " " +
                                    t("description")
                                }
                                value={filterString}
                                searchIcon={true}
                                onChange={setFilterString}
                            />
                        </div>
                        <table className="w-full rounded-t-md text-sm text-center">
                            <thead className="text-xl">
                                <tr className="border-b-2">
                                    <th className="p-1 border-2">{t("batchRecord")}</th>
                                    <th className="p-1 border-2">{t("campaign")}</th>
                                    <th className="p-1 border-2">{t("plasmaType")}</th>
                                    <th className="p-1 border-2">{t("item")}</th>
                                    <th className="p-1 border-2">{t("description")}</th>
                                    <th className="p-1 border-2">{t("initialStep")}</th>
                                    <th className="p-1 border-2">{t("finalStep")}</th>
                                    <th className="p-1 border-2">{t("edit")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((bridgeRecord, index, bridgeRecords) => {
                                    const thickBorder =
                                        index == 0
                                            ? false
                                            : bridgeRecord.batchRecordId !== bridgeRecords[index - 1].batchRecordId;
                                    return (
                                        <tr key={bridgeRecord.id}>
                                            <td
                                                className={
                                                    "p-2 border-1 underline text-green-500 text-base whitespace-nowrap " +
                                                    (thickBorder ? "border-t-4" : "")
                                                }
                                            >
                                                {bridgeRecord.batchRecordId}
                                            </td>
                                            <td className={"p-2 border-1 " + (thickBorder ? "border-t-4" : "")}>
                                                {bridgeRecord.campagna}
                                            </td>
                                            <td className={"p-2 border-1 " + (thickBorder ? "border-t-4" : "")}>
                                                {bridgeRecord.tipoPlasma.join(", ")}
                                            </td>
                                            <td className={"p-2 border-1 " + (thickBorder ? "border-t-4" : "")}>
                                                {bridgeRecord.item}
                                            </td>
                                            <td className={"p-2 border-1 " + (thickBorder ? "border-t-4" : "")}>
                                                {bridgeRecord.descrizione}
                                            </td>
                                            <td className={"p-2 border-1 " + (thickBorder ? "border-t-4" : "")}>
                                                {bridgeRecord.stepInizio}
                                            </td>
                                            <td className={"p-2 border-1 " + (thickBorder ? "border-t-4" : "")}>
                                                {bridgeRecord.stepFine}
                                            </td>
                                            <td className={"p-2 border-1 " + (thickBorder ? "border-t-4" : "")}>
                                                <div className="flex items-center justify-center w-full h-full">
                                                    <div
                                                        className="border-1 shadow bg-gray-300 hover:bg-gray-200 p-1.5 cursor-pointer rounded-md"
                                                        onClick={() => {
                                                            setShowPopup({
                                                                showing: true,
                                                                new: false,
                                                                bridgeRecordEdit: bridgeRecord
                                                            });
                                                        }}
                                                    >
                                                        <EditIcon className="w-5 h-5 text-gray-700" />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
            {showPopup.showing && (
                <BridgeRecordsPopup
                    isNew={showPopup.new}
                    bridgeRecord={showPopup.bridgeRecordEdit}
                    availableBatchRecords={batchRecordIds}
                    availableCampaigns={campaigns}
                    request={request}
                    onClose={() => setShowPopup({ showing: false, new: null })}
                    onSave={(editedBridgeRecord) => {
                        if (showPopup.new) {
                            mutateBridgeRecords((old) =>
                                [...old, editedBridgeRecord].sort((a, b) =>
                                    a.batchRecordId.toLowerCase() > b.batchRecordId.toLowerCase()
                                        ? 1
                                        : b.batchRecordId.toLowerCase() > a.batchRecordId.toLowerCase()
                                        ? -1
                                        : 0
                                )
                            );
                        } else {
                            mutateBridgeRecords((old) => {
                                const index = old.findIndex((r) => r.id === editedBridgeRecord.id);
                                console.log(index);
                                old[index] = editedBridgeRecord;
                                old.sort((a, b) =>
                                    a.batchRecordId.toLowerCase() > b.batchRecordId.toLowerCase()
                                        ? 1
                                        : b.batchRecordId.toLowerCase() > a.batchRecordId.toLowerCase()
                                        ? -1
                                        : 0
                                );
                                return [...old];
                            });
                        }
                        setShowPopup({ showing: false, new: null });
                    }}
                    onDelete={() => {
                        mutateBridgeRecords((old) => old.filter((r) => r.id !== showPopup.bridgeRecordEdit.id));
                        setShowPopup({ showing: false, new: null });
                    }}
                />
            )}
        </>
    );
};

export default BridgeRecords;
