import { useMemo, useState } from "react";

const RevisionTab = ({ pages, onChange = () => {} }) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const tabs = useMemo(
        () =>
            pages.map((page, index) => (
                <div
                    key={index}
                    className={
                        "relative h-full flex items-center justify-center p-3 cursor-pointer rounded-t-md shadow " +
                        (selectedTab === index ? "text-red-600" : "text-gray-500 hover:bg-gray-300 hover:bg-opacity-60")
                    }
                    onClick={() => {
                        setSelectedTab(index);
                        onChange(selectedTab);
                    }}
                >
                    <div className={"font-bold text-2xl"}>{page.title}</div>
                    {page.icon}
                    <div
                        className={
                            "absolute bottom-0 rignt-0 left-0 w-full rounded-t-md " +
                            (selectedTab === index ? "h-0.200rem bg-red-600" : "h-0.5 bg-gray-500")
                        }
                    />
                </div>
            )),
        [pages, selectedTab, onChange]
    );

    return (
        <>
            <div className={"flex flex-row flex-nowrap items-end justify-center mb-4 shadow rounded-t-md"}>{tabs}</div>
            <div className="w-full">{pages[selectedTab].content}</div>
        </>
    );
};

export default RevisionTab;
