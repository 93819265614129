import React from "react";
import { ReactComponent as Spinner } from "../svgIcons/spinner-solid.svg";

const AppLoader = () => {
    return (
        <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
            <Spinner className={"h-16 w-16 animate-spin text-red-700"} />
        </div>
    );
};

export default AppLoader;
